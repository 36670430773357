export const gridBreakPoints = {
  base: '0rem repeat(12, 1fr) 0rem', // 0 rem added so the column number stay consistent through all breakpoints.
  xl: '1fr repeat(12, calc((1280px - 26rem)/12)) 1fr',
};

// Enum for when we require the string key from GridBreakPoints (i.e. unit testing)
export enum GridBreakPoints {
  BASE = 'base',
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large',
  XL = 'xl',
  XXL = 'xxl',
}
