/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-return */
import { useEffect, useState } from 'react';
import { useLazyQuery, useReactiveVar } from '@apollo/client';
import dayjs from 'dayjs';
import { Heading, Text, Flex, Link, View, Grid, useBreakpointValue } from '@aws-amplify/ui-react';
import { authorisedBusinesses } from 'apollo/states/AuthorisedBusinesses';
import { toTitleCase } from 'utils/ToTitleCase';
import ValueBackCard from 'components/ValueBack/ValueBackCard';
import InsightsSelect from 'components/InsightsSelect/InsightsSelect';
import InsightsToggle from 'components/InsightsToggle/InsightsToggle';
import { externalLinks } from 'lib/externalLinks';
import {
  getBusinessInsights,
  ABSInsights,
  SeriesValue,
  getKeyDimentionalSeriesKey,
  Observations,
  SelectOption,
  defaultIndustry,
  getInsightsValue,
  getSelectedIndustryIndex,
} from 'services/absInsightsAPI';

import DashboardLayout from 'components/DashboardLayout/DashboardLayout';
import InsightMeasureDetails from 'components/InsightMeasureDetails/InsightMeasureDetails';
import './IndustryInsightsPage.css';
import { InsightsMeasures } from 'enums/InsightsMeasures';
import { GET_VALUE_BACK } from 'apollo/queries/getDSPBusinessQuarterData';
import {
  BusinessData,
  IndustryInsightToggleText,
  getBusinessInsightValues,
  toggleText,
  validateValueBack,
} from 'utils/IndustryInsightsUtils';
import { recordRumCustomEvent } from 'services/awsRum';
import { RumCustomEvent } from 'enums/RumCustomEvent';
import { getRumAttributes } from 'utils/getRumAttributes';
import { GetValueBack } from 'models/GraphQL/GetValueBack';
import { HorizontalRule } from 'utils/horizontalLine';

const IndustryInsightsPage = () => {
  const [insightData, setInsightData] = useState<Map<string, Observations>>();
  const [industries, setIndustries] = useState<SeriesValue | null>(null);
  const [measures, setMeasures] = useState<SeriesValue | null>(null);
  const [dates, setDates] = useState<string[]>([]);
  const [selectedIndustry, setSelectedIndustry] = useState<SelectOption>(defaultIndustry);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [retry, setRetry] = useState(false);
  const [showBusinessData, setShowBusinessData] = useState(false);
  const currentBusinesses = useReactiveVar(authorisedBusinesses);
  const { activeBusiness } = currentBusinesses;
  const [businessValueBack, setBusinessValueBack] = useState<BusinessData | undefined>(undefined);
  const valueBackAvailable = Boolean(
    validateValueBack(businessValueBack) || (activeBusiness?.dsp && activeBusiness?.dsp !== 'NODSP')
  );
  const [valueBackError, setValueBackError] = useState(false);

  const [callGetValueBackData, { loading: valueBackLoading }] = useLazyQuery<GetValueBack | undefined>(GET_VALUE_BACK, {
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    onCompleted(data) {
      const newValueBack: BusinessData = {
        salesToWagesRatio: data?.valueBack?.salesToWagesRatio ?? [],
        percentageBreakdownForSales: data?.valueBack?.percentageBreakdownForSales ?? [],
        percentageBreakdownForWages: data?.valueBack?.percentageBreakdownForWages ?? [],
        quarterDates: data?.valueBack?.quarterDates ?? [],
      };
      setBusinessValueBack(newValueBack);
      setValueBackError(false);
    },
    onError: () => {
      setValueBackError(true);
    },
  });

  const timeLineOptions = [
    { label: 'Past year', value: 4 },
    { label: 'Past 2 years', value: 8 },
    { label: 'Past 3 years', value: 12 },
    { label: 'Past 4 years', value: 16 },
    { label: 'Past 5 years', value: 20 },
  ];
  const defaultTimeFrame = timeLineOptions[1];
  const [selectedTimeFrame, setSelectedTimeFrame] = useState<SelectOption>(defaultTimeFrame);

  useEffect(() => {
    const fetchDataForPosts = async () => {
      try {
        setLoading(true);
        setError(false);
        const data = (await getBusinessInsights()) as ABSInsights;
        // ABS Data
        const series = new Map(Object.entries(data.dataSets.find((set) => set.action === 'Information')!.series));
        setInsightData(series);
        // The measures representing the data and where to find them
        setMeasures(data.structure.dimensions.series.find((a) => a?.id === 'MEASURE')!);
        // The industries represented in the data and where to find them
        const divisions = data.structure.dimensions.series.find((a) => a?.id === 'INDUSTRY')!;
        setIndustries(divisions);
        // The dates of the data in the series
        const newDates: string[] = [];
        data.structure.dimensions.observation
          .find((a) => a?.id === 'TIME_PERIOD')!
          .values.map((time) => (time.end ? newDates.push(dayjs(time.end).format('MMM YYYY')) : false));
        setDates(newDates);
        // When the user has an active business with a valid div so it first
        if (divisions && activeBusiness?.division) {
          const matchedIndustry = divisions.values.find((div) => div.id === activeBusiness?.division);
          if (matchedIndustry?.id && matchedIndustry.name) {
            setSelectedIndustry({ label: toTitleCase(matchedIndustry.name.toLowerCase()), value: matchedIndustry?.id });
          }
        }
        setLoading(false);
      } catch (err: any) {
        setLoading(false);
        setError(true);
        console.error(err.message);
      }
    };

    fetchDataForPosts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeBusiness, retry]);

  const handleChange = (e: SelectOption) => {
    setSelectedIndustry(e);
  };

  const handleTimeChange = (e: SelectOption) => {
    setSelectedTimeFrame(e);
  };

  const getIndustryOptions = () => {
    if (!industries) return [{ label: '', value: '' }];
    const values = industries.values.map((i) => ({ label: toTitleCase(i.name.toLowerCase()), value: i.id }));
    return sortIndustryOptions(values) || true;
  };

  const sortIndustryOptions = (values: SelectOption[]) => {
    const options = values.sort((a: SelectOption, b: SelectOption) => {
      if (a.label < b.label) {
        return -1;
      }
      if (a.label > b.label) {
        return 1;
      }
      return 0;
    });
    // Move 'All industries to position 0'
    options.forEach((item, i) => {
      if (item.label === 'All industries') {
        values.splice(i, 1);
        values.unshift(item);
      }
    });
    return options;
  };

  const updateCurrentToCurrentDivision = (link: string) => {
    if (selectedIndustry?.value) {
      return link.replace('..TOT.', `.${selectedIndustry.value}.TOT.`);
    }
    return link;
  };

  const handleBusinessToggle = async (toggleState: boolean) => {
    recordRumCustomEvent(RumCustomEvent.toggleValueBack, getRumAttributes({ providerId: activeBusiness?.providerId }));
    if (toggleState) setSelectedTimeFrame(defaultTimeFrame);
    if ((!businessValueBack || valueBackError) && toggleState) {
      const variables = {
        organisationId: activeBusiness?.id ?? 'test',
        providerId: activeBusiness?.providerId ?? 'test',
        dsp: activeBusiness?.dsp ?? 'test',
        noOfYears: 2,
      };
      await callGetValueBackData({
        variables,
      });
    }
    setShowBusinessData(toggleState);
  };

  const formatData = (keyPosition: number, measurePosition: number, measureIndex: string, tagName: string) => {
    if (industries) {
      const industryData = getInsightsValue(
        getKeyDimentionalSeriesKey(
          keyPosition,
          getSelectedIndustryIndex(selectedIndustry, industries),
          getKeyDimentionalSeriesKey(measurePosition, measureIndex)
        ),
        insightData!
      );
      const businessData = businessValueBack ? getBusinessInsightValues(tagName, dates, businessValueBack) : [];

      return {
        industryData,
        businessData,
      };
    }
    return {
      industryData: [],
      businessData: [],
    };
  };

  const toggleTextWithError = () => {
    if (valueBackError && !valueBackLoading) return IndustryInsightToggleText.NO_DATA;
    return activeBusiness?.dsp && activeBusiness?.dsp !== 'NODSP'
      ? toggleText(businessValueBack, valueBackLoading, valueBackAvailable)
      : IndustryInsightToggleText.NO_DATA;
  };

  const timeSpanGridRow = useBreakpointValue({ base: '2', large: '1' }) as string;
  const toggleGridRow = useBreakpointValue({ base: '3', medium: '2', xl: '1' }) as string;

  const displaySelectOptions = () => {
    if (loading) {
      return (
        <div className="skeleton" style={{ height: '78px', width: '100%' }} data-testid="skeleton-industry-insight" />
      );
    }
    if (measures) {
      return (
        <Grid
          gap="medium"
          templateColumns={{ medium: '208px 210px', large: 'repeat(2, 1fr)', xl: '288px 208px 210px' }}
          style={{}}
        >
          <InsightsSelect
            title="Industry"
            value={selectedIndustry}
            handleChange={handleChange}
            options={getIndustryOptions()}
            minWidth="16rem"
            inputId="select-industry-input"
            ariaLabel="Select Industry Input"
            style={{
              gridRow: '1',
              width: '288px',
            }}
          />
          <InsightsSelect
            title="Time span"
            value={selectedTimeFrame}
            handleChange={handleTimeChange}
            options={timeLineOptions}
            inputId="select-timespan-input"
            ariaLabel="Select Timespan Input"
            style={{
              gridRow: timeSpanGridRow,
              width: '208px',
            }}
          />
          <InsightsToggle
            title="Compare your business"
            inputId="toggle-valueback-input"
            ariaLabel="toggle-valueback-input"
            label={toggleTextWithError()}
            disabled={
              valueBackLoading ||
              !valueBackAvailable ||
              toggleText(businessValueBack, loading, valueBackAvailable) === IndustryInsightToggleText.NO_DATA
            }
            loading={valueBackLoading}
            error={valueBackError}
            info={
              <View id="toggle-valueback-input-tooltip" role="tooltip">
                <Text>
                  If you chose to connect to accounting software, this feature allows you to compare your business' data
                  with data for all businesses in your chosen industry.
                </Text>
                <br />
                <Text>
                  When you select this option, we will use information in your accounting software to display values for
                  up to the last eight quarters. Values are generated based on the accounts or categories you selected
                  in completing the most recent survey for the business.
                </Text>
                <br />
                <Text>
                  If accounts have changed, values for previous quarters may not be accurate.This data is only displayed
                  for your information and is not sent to the ABS.
                </Text>
              </View>
            }
            handleChange={handleBusinessToggle}
            style={{
              gridRow: toggleGridRow,
              width: '210px',
            }}
          />
        </Grid>
      );
    }
    return false;
  };

  return (
    <DashboardLayout>
      <div id="skipTarget" />
      <Flex
        direction="column"
        gap="var(--amplify-space-xxl)"
        className="industry-insights-page-wrapper"
        testId="industry-insights-page-wrapper"
        marginTop={useBreakpointValue({ base: '0px', medium: '48px' }) as string}
        paddingBottom="60px"
      >
        <Heading className="industry-insights-page-heading" testId="industry-insights-page-heading" level={1}>
          Industry insights
        </Heading>
        <Text className="industry-insights-page-description" testId="dashboard-abn-text">
          This tool allows you to access and explore{' '}
          <Link isExternal href={externalLinks.absBusinessIndicators}>
            ABS Business Indicators data
          </Link>{' '}
          for your chosen industry. The information you see is produced from what you and others report in the Quarterly
          Business Indicators Survey.
        </Text>
        <Text className="industry-insights-page-description" testId="dashboard-abn-text">
          The following graphs show change in each indicator relative to the quarter before for the industry selected.
          This shows what businesses in the industry have experienced over time.
        </Text>
        <Text className="industry-insights-page-description" testId="dashboard-abn-text">
          If you chose to connect to accounting software, you can select 'Compare your business' to view your business
          information from accounting software alongside industry data, so that you can compare your business with all
          businesses for your chosen industry.
        </Text>
        <Text color="#000000" fontWeight="400" fontSize="16px" testId="dashboard-abn-text">
          Data from accounting software is only displayed here for your use, and is not sent to the ABS.
        </Text>
        <Flex gap="2rem">{displaySelectOptions()}</Flex>
        <HorizontalRule />
        <Flex direction="column" gap="64px" justifyContent="space-around">
          {measures &&
            InsightsMeasures &&
            InsightsMeasures.map((InsightsMeasureConfig) => {
              const measure = measures.values.find((m) => m.id === InsightsMeasureConfig.key)!;
              const measureIndex = measures.values.indexOf(measure).toString();
              if (industries) {
                return (
                  <>
                    <InsightMeasureDetails
                      key={measure.id}
                      tagName={InsightsMeasureConfig.tagName}
                      title={InsightsMeasureConfig.name}
                      name={InsightsMeasureConfig.title}
                      data={formatData(
                        industries?.keyPosition,
                        measures.keyPosition,
                        measureIndex,
                        InsightsMeasureConfig.tagName
                      )}
                      categories={dates}
                      color={InsightsMeasureConfig.color}
                      whatIsIt={InsightsMeasureConfig.whatIsIt}
                      whyItMatters={InsightsMeasureConfig.whyItMatters}
                      displayCount={selectedTimeFrame.value}
                      industry={selectedIndustry ? selectedIndustry.label : ''}
                      loading={loading}
                      link={updateCurrentToCurrentDivision(InsightsMeasureConfig.link)}
                      onRetry={() => setRetry(!retry)}
                      label={InsightsMeasureConfig.label}
                      isPercentage={InsightsMeasureConfig.isPercentage}
                      showBusinessData={showBusinessData}
                    />
                    <HorizontalRule />
                  </>
                );
              }
              return false;
            })}
          {!measures &&
            InsightsMeasures.map((InsightsMeasureConfig) => (
              <>
                <InsightMeasureDetails
                  key={InsightsMeasureConfig.name}
                  tagName={InsightsMeasureConfig.tagName}
                  title={InsightsMeasureConfig.name}
                  name="loading"
                  data={{ industryData: [], businessData: [] }}
                  categories={[]}
                  color={InsightsMeasureConfig.color}
                  whatIsIt={InsightsMeasureConfig.whatIsIt}
                  whyItMatters={InsightsMeasureConfig.whyItMatters}
                  displayCount={8}
                  industry={selectedIndustry ? selectedIndustry.label : ''}
                  loading={loading}
                  error={error}
                  onRetry={() => setRetry(!retry)}
                />
                <HorizontalRule />
              </>
            ))}
        </Flex>
        <View column="1/-1">
          <ValueBackCard />
        </View>
        <Heading level={3} style={{ margin: '0' }}>
          Notes on the data
        </Heading>
        <Text>
          This data is in current prices. It includes incorporated and unincorporated businesses. It is not seasonally
          adjusted.
        </Text>
        <Text>
          These graphs show data from the{' '}
          <Link isExternal href={externalLinks.absBusinessIndicators}>
            Business Indicators, Australia
          </Link>{' '}
          publication. Please refer to the{' '}
          <Link isExternal href={externalLinks.absBussinessIndicatorsMethodology}>
            methodology
          </Link>{' '}
          page for more information about the data.
        </Text>
      </Flex>
    </DashboardLayout>
  );
};

export default IndustryInsightsPage;
