import { useReactiveVar } from '@apollo/client';
import { Expander, ExpanderItem, Flex, Heading, View, useBreakpointValue, Grid } from '@aws-amplify/ui-react';
import { useSaveReport } from 'utils/useSaveReport';
import { useEffect, useMemo, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { visitedReportPages } from 'apollo/Cache';
import './SideNavbar.scss';
import useCurrentReportTopics from 'apollo/states/utils/useCurrentReportTopics';
import { StepID } from 'apollo/states/utils/useCurrentReportSteps';
import { gridBreakPoints } from 'utils/gridBreakPoints';
import ReportSaveStatus from '../ReportComponents/ReportSaveStatus/ReportSaveStatus';
import { ReactComponent as TickIcon } from '../../assets/icon-progress-indicator-visited.svg';
import { ReactComponent as CurrentProgressIcon } from '../../assets/icon-progress-indicator-current.svg';
import { ReactComponent as ProgressIcon } from '../../assets/icon-progress-indicator.svg';
import { ReactComponent as ChevronUp } from '../../assets/icon-chevron-up.svg';
import { ReactComponent as ChevronDown } from '../../assets/icon-chevron-down.svg';

const getLinkStateClass = (current: boolean, visited: boolean) => {
  if (current) return 'processing';
  if (visited) return 'completed';
  return '';
};

const getLinkIcon = (current: boolean, visited: boolean) => {
  const iconStyle = { backgroundColor: 'white', width: '20px', height: '20px', zIndex: '1' };
  const nonCurrentIcon = visited ? <TickIcon style={iconStyle} fill="#326297" /> : <ProgressIcon style={iconStyle} />;

  return (
    <View
      style={{
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        verticalAlign: 'middle',
        width: '32px',
      }}
    >
      {current ? (
        <CurrentProgressIcon style={{ ...iconStyle, display: 'block', width: '32px', height: '32px' }} />
      ) : (
        nonCurrentIcon
      )}
    </View>
  );
};

const SideNavbarLinkItem = ({ step, title, to }: { step: StepID; title: string; to: string }) => {
  const [saveReport] = useSaveReport(`SideNavbarLinkItem "${title}"`);

  const location = useLocation();
  const currentReportPage = location.pathname.split('/').pop();
  const current = step === currentReportPage;
  const wasVisited = useReactiveVar(visitedReportPages).includes(step);
  const visited = wasVisited || current;

  // save this as a visited page if it is the current page
  useEffect(() => {
    if (wasVisited || !current) return;

    const previousVisited = visitedReportPages();
    visitedReportPages([...previousVisited, step]);
  }, [step, current, wasVisited]);

  const stateClass = getLinkStateClass(current, visited);

  const handleOnClick = () => {
    saveReport('Side Nav Bar Click', `Side nav "${title}" click`);
  };

  return (
    <li className={`side-navBar-item ${stateClass}`} style={{ display: 'flex', height: '56px' }}>
      {getLinkIcon(current, Boolean(wasVisited))}
      <Link
        data-testid={`navbar-${step}`}
        className="navbar-link"
        aria-current={current && 'page'}
        to={to}
        onClick={handleOnClick}
        style={{ borderBottom: step === 'review' ? '' : '1px solid #DCDEE0' }}
      >
        {title}
      </Link>
    </li>
  );
};

export const SideNavBar = () => {
  const allCurrentReportTopics = useCurrentReportTopics();
  const currentReportTopics = useMemo(
    () => allCurrentReportTopics.filter(({ isFeedback }) => !isFeedback),
    [allCurrentReportTopics]
  );

  const [expanderOpen, setExpanderOpen] = useState<string>('');

  const sideBarLinks = (
    <ol
      className="side-navbar"
      style={{ borderStyle: useBreakpointValue({ base: 'none', medium: 'solid none' }) as string }}
    >
      <SideNavbarLinkItem step="instructions" title="Instructions" to="/report/instructions" />
      {currentReportTopics.map((topicDetail) => (
        <SideNavbarLinkItem
          key={topicDetail.key}
          step={topicDetail.key}
          title={topicDetail.topicShortTitle}
          to={topicDetail.path}
        />
      ))}
      <SideNavbarLinkItem step="review" title="Review" to="/report/review" />
    </ol>
  );

  const navBar = (
    <Flex
      testId="side-navbar"
      className="side-navbar-container"
      direction="column"
      justifyContent="flex-start"
      gap="0"
      alignContent="flex-start"
    >
      <Flex marginBottom="24px" minHeight="24px" gap="small">
        <Heading fontSize="20px" fontWeight="600" lineHeight="24px">
          Sections
        </Heading>
        <ReportSaveStatus />
      </Flex>
      {sideBarLinks}
    </Flex>
  );

  const expanderNavBar = (
    <Grid
      templateColumns={String(gridBreakPoints.base)}
      column="1/15"
      border="1px solid #DCDEE0"
      borderWidth="0 0 1px 0"
      height="min-content"
    >
      <View column="1/15">
        <Expander
          type="single"
          onValueChange={(e) => setExpanderOpen(e as string)}
          value={expanderOpen}
          data-testid="side-navbar-expander"
          width="100%"
          className="side-navbar-expander"
          isCollapsible
        >
          <ExpanderItem
            value="navbar"
            title={
              <Flex
                width="100%"
                data-testid="side-navbar-expander-item"
                justifyContent="space-between"
                padding="16px xl"
                border="1px solid #DCDEE0"
                borderWidth={expanderOpen ? '0 0 1px 0' : '0'}
              >
                <Flex minHeight="24px" gap="small">
                  <Heading
                    fontSize="20px"
                    fontWeight="600"
                    lineHeight="24px"
                    textDecoration="underline"
                    color="#0F487C"
                  >
                    Sections
                  </Heading>
                  <ReportSaveStatus />
                </Flex>
                {expanderOpen === 'navbar' ? <ChevronUp /> : <ChevronDown />}
              </Flex>
            }
            padding="0"
          >
            {sideBarLinks}
          </ExpanderItem>
        </Expander>
      </View>
    </Grid>
  );

  const sideNavBarComponent = useBreakpointValue({
    base: expanderNavBar,
    medium: navBar,
  }) as JSX.Element;

  return sideNavBarComponent;
};
