import { Dispatch, useReducer, useEffect } from 'react';
import { ChosenReport, SavedReportDataItems } from 'models';
import makeVarPersisted from 'apollo/MakeVarPersisted';
import { readonlyRvUtils } from './readonlyRvUtils';
import {
  BackgroundSaveAction,
  reducer,
  initialBackgroundSaveData,
  init,
  ReportSnapshot,
} from './backgroundSaveReducer';

const savingInBackground = makeVarPersisted(false, 'aspBackgroundSaveInProgress');
const [readSavingInBackground, useSavingInBackgroundHook, updateSavingInBackground] =
  readonlyRvUtils(savingInBackground);
export const isSavingInBackground = readSavingInBackground;
export const useSavingInBackground = useSavingInBackgroundHook;
/**
 * @deprecated only exposed for test
 */
export const savingInBackgroundForTestOnly = savingInBackground;

// Variable for the dispatch function - simplifies sharing a function that will queue a background save
let capturedDispatch: Dispatch<BackgroundSaveAction> | undefined;

export const useBackgroundSaveReducer = () => {
  const response = useReducer(reducer, initialBackgroundSaveData, init);
  const [state, dispatch] = response;

  // externalise dispatch to the module scope for use when external components request background save
  useEffect(() => {
    capturedDispatch = dispatch;
    return () => (capturedDispatch = undefined);
  }, [dispatch]);

  // keep savingInBackground accurate
  useEffect(() => updateSavingInBackground(!!state.length), [state.length]);

  return response;
};

/**
 * Request a background save to be queued.
 *
 * Note: Could change to just accept a ReportSnapshot for simplicity
 */
export const requestBackgroundSave = (
  reportData: ChosenReport,
  savedValues: SavedReportDataItems,
  serverSavedValues: SavedReportDataItems,
  context: ReportSnapshot['context']
) => {
  if (!capturedDispatch) {
    // TODO: store requests until dispatch is available instead (e.g. using a promise)
    console.error('Not ready to queue background save');
    return;
  }

  capturedDispatch({
    type: 'QUEUE_BACKGROUND_SAVE',
    userReportId: reportData.userReportId!,
    report: {
      reportData,
      savedValues,
      serverSavedValues,
      context,
    },
  });
};
