import { gql } from '@apollo/client';

export const REVOKE_BUSINESS = gql`
  mutation revokeBusiness($dspProvider: String!, $organisationId: String!, $providerId: String, $email: String) {
    revokeBusiness(dspProvider: $dspProvider, organisationId: $organisationId, providerId: $providerId, email: $email) {
      success
      errorMessages {
        message
        errorCode
        language
        metaData
        errorType
        stack
        time
      }
    }
  }
`;
