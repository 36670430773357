/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { chosenReport, ChosenReportInitialState } from 'apollo/states/ChosenReport';
import { currentReportData } from 'apollo/states/CurrentReportData';
import { savedReportDataItems } from 'apollo/states/SavedReportDataItem';
import { userContactDetails } from 'apollo/states/UserContactDetails';
import {
  ChosenReport,
  CurrentReportData,
  DataItem,
  SavedReportDataItem,
  SavedReportDataItems,
  UserContactDetails,
} from 'models';
import { PDFDataItem } from 'models/LocalState/PDFDataItem';
import { PDFTopicDetail, ReportPDFData } from 'models/LocalState/ReportPDFData';
import { TopicFullDetail, TopicName } from 'models/LocalState/TopicFullDetail';
import { getTotalByTopicWithoutReactives } from 'apollo/states/utils/GetTotalByTopic';
import { currencyFormat } from './CurrencyFormat';
import { asSavedActionListNumeric } from './savedActionTypeUtil';
import { generateRuleExplanation } from './generateRuleExplanation';

const buildEmptyReportPDFData = (): ReportPDFData => ({
  topics: [],
  user: undefined,
  businessInformation: undefined,
});

export interface ReportPDFInputData {
  localUserContactDetails: UserContactDetails;
  localCurrentReportData: CurrentReportData;
  localSavedReportDataItems: SavedReportDataItems;
  localChosenReport: ChosenReport;
}

const reportPdfData = (data?: ReportPDFInputData) => {
  if (!data) {
    // No data provided, retrieve all from reactive variables for current report
    const currentReport = currentReportData();
    return buildReportPdfData(
      currentReport.topics,
      savedReportDataItems(),
      currentReport.dataItems,
      userContactDetails(),
      // This satisfies the type checker, but if any of the inputs are not available we really should
      // be failing with an error, since that is not the expected state when this is called without input data
      chosenReport() ?? ChosenReportInitialState
    );
  }

  const { localUserContactDetails, localCurrentReportData, localSavedReportDataItems, localChosenReport } = data;
  const { topics, dataItems } = localCurrentReportData;

  // existing code returned empty state if answers are present but not questions, replicating here in case
  // that was on purpose, but should check if it was intended. Most likely would not occur in practice.
  if (localSavedReportDataItems && !dataItems) {
    return buildEmptyReportPDFData();
  }

  return buildReportPdfData(topics, localSavedReportDataItems, dataItems, localUserContactDetails, localChosenReport);
};

function buildReportPdfData(
  topics: TopicFullDetail[],
  currentReportAnswers: SavedReportDataItems,
  reportQuestions: DataItem[],
  user: UserContactDetails | undefined,
  businessInformation: ChosenReport
) {
  const getPdfReportData = reportQuestions
    .sort((a, b) => a.position - b.position)
    .map((currentQuestion): PDFDataItem => {
      const selectedAnswer = currentReportAnswers.savedAnswers.find(
        (currentAnswer) => currentAnswer.dataItemId === currentQuestion.dataItemId
      );

      if (selectedAnswer) {
        return { ...currentQuestion, answer: selectedAnswer };
      }
      return { ...currentQuestion };
    });

  const withTopRemaining = topRemainingFactory(getPdfReportData, Boolean(businessInformation.isUsingDsp));

  const topicItems = (topic: TopicName) => getPdfReportData.filter((i) => i.topic === topic).map(withTopRemaining);
  const allSavedItems = getPdfReportData.map((i) => i.answer).filter((a): a is SavedReportDataItem => !!a);

  const mappedTopics: PDFTopicDetail[] = topics.map((topic) => {
    const dataItems = topicItems(topic.topic);

    const total = currencyFormat.format(getTotalByTopicWithoutReactives(topic.topic, topics, allSavedItems));
    const ruleExplanation = generateRuleExplanation(topic.compiledRule, topics);

    return {
      ...topic,
      dataItems,
      total,
      ruleExplanation,
    };
  });

  const reportPDFData: ReportPDFData = {
    topics: mappedTopics,
    user,
    businessInformation,
  };

  return reportPDFData;
}

/**
 * Matches SORTDISPLAY, which is the action type for the "Top two" sections.
 * Case insensitive since there is no reason not to be.
 *
 * Capture groups (number is index in sortDisplayRegex.exec(...) return value):
 *
 * Group 1: item ID that should be used for the items to sort
 * Group 2: size quantifier part if present
 * Group 3: size digits
 *
 * Note that the "size" qualifier like "_S=4" is not part of the spec, but the spec mentions a
 * default of 2 items but no way to override the default so I have made this support the same
 * specifier that TEXT items use as a reasonable default in case we need to customise it.
 */
const sortDisplayRegex = /SORTDISPLAY=([^_]+).*(?:_S=(\d+))?/i;

// Are there other list action types that would be suitable? Anything numeric.
const validListActionsRegex = /LIST(SUM|AVG)/i;

/**
 *
 * @param allItems
 * @returns a callback that will add the appropriate top items to any SORTDISPLAY data item that it is called with
 */
function topRemainingFactory(allItems: PDFDataItem[], isUsingDsp: boolean) {
  return (item: PDFDataItem) => {
    const matches = sortDisplayRegex.exec(item.action);
    if (!matches) return item;

    const listItemId = matches[1];
    const sizeMatch = matches[3];
    const size = (sizeMatch?.length ? Number(sizeMatch) : 2) ?? 2;

    let topSortedItems;
    let listItemAnswer;
    if (!isUsingDsp) {
      // type is inferred properly in vscode, but not in build
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      listItemAnswer = asSavedActionListNumeric(item.answer?.savedReportDataItem);
    } else {
      const listItem = allItems.find((i) => i.dataItemId === listItemId);
      if (!listItem) {
        console.warn(
          `No item matching ${listItemId} from action ${item.action} for item ${item.dataItemId}. Skipping.`
        );
        return item;
      }

      if (!listItem.action.match(validListActionsRegex)) {
        console.warn(
          `Item matching ${listItemId} from action ${item.action} for item ${item.dataItemId} is not a supported LIST type. Skipping.`
        );
        return item;
      }

      // type is inferred properly in vscode, but not in build
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      listItemAnswer = asSavedActionListNumeric(listItem.answer?.savedReportDataItem);
    }

    if (listItemAnswer) {
      topSortedItems = listItemAnswer?.selectedReportDataItems
        .sort((a, b) => Number(b?.dspValue || b?.userValue) - Number(a?.dspValue || a?.userValue))
        .slice(0, size);
    }

    // Add directly to item for convenience
    return { ...item, topSortedItems };
  };
}

export default reportPdfData;
