import { useMutation, useReactiveVar } from '@apollo/client';
import { AuthorisedBusiness } from 'models';
import { authorisedBusinesses } from 'apollo/states/AuthorisedBusinesses';
import { SET_LAST_ACTIVE_BUSINESS } from 'apollo/mutations/setLastActiveBusiness';
import { ExpandedReview } from 'apollo/states/ExpandedReview';
import { RumCustomEvent } from 'enums/RumCustomEvent';
import { recordRumCustomEvent } from 'services/awsRum';
import { getRumAttributes } from './getRumAttributes';

export const useSwitchBusiness = () => {
  const currentBusinesses = useReactiveVar(authorisedBusinesses);
  const [callSetActiveBusiness] = useMutation(SET_LAST_ACTIVE_BUSINESS);

  const switchBusiness = (business: AuthorisedBusiness) => {
    authorisedBusinesses({ activeBusiness: business, authorisedBusinesses: currentBusinesses.authorisedBusinesses });
    recordRumCustomEvent(RumCustomEvent.businessLoad, getRumAttributes({ providerId: business.providerId }));
    callSetActiveBusiness({
      variables: { dspProvider: business.dsp, organisationId: business.id },
    });

    // reset review page expand toggle
    ExpandedReview(false);
  };

  return switchBusiness;
};
