/* eslint-disable no-nested-ternary */
import { Alert, AlertProps, AlertVariations, Grid, Text, useBreakpointValue } from '@aws-amplify/ui-react';
import { ReactNode, useRef, useEffect, useState } from 'react';
import { gridBreakPoints } from 'utils/gridBreakPoints';
import './AlertComponent.scss';

interface Props {
  variation: 'incompleted' | 'timeOut' | 'error' | 'warning';
  title?: string;
  textHeader?: string;
  textHeaderComponent?: ReactNode;
  secondTextHeaderComponent?: ReactNode;
  listItem?: string[];
  alertProps?: AlertProps;
  errorCode?: number;
  className?: string;
  column?: string;
  contentColumn?: string;
  templateColumns?: string;
  templateRows?: string;
  backgroundColor?: string;
  columnGap?: string;
  onDismiss?: () => void;
}

let variationCheck: AlertVariations;
let selectClass: string;

export const AlertComponent: React.FC<Props> = ({
  variation,
  title,
  textHeader,
  textHeaderComponent,
  secondTextHeaderComponent,
  listItem,
  alertProps,
  errorCode,
  className,
  column,
  contentColumn,
  templateColumns,
  templateRows,
  backgroundColor,
  columnGap,
  onDismiss,
}) => {
  selectClass = 'alert-component-default';
  if (variation === 'incompleted') {
    variationCheck = 'info';
  }
  if (variation === 'timeOut') {
    variationCheck = 'info';
    selectClass = 'alert-component-time-out';
  }
  if (variation === 'error') {
    variationCheck = 'error';
    selectClass = 'alert-component-error';
  }
  if (variation === 'warning') {
    variationCheck = 'warning';
    selectClass = 'alert-component-warning';
  }

  const [isDismissed, setIsDismissed] = useState(false);
  // 'warning' and 'info' are currently without alert being dismissible
  const isDismissible = variationCheck === 'error';

  const handleDismiss = () => {
    onDismiss?.();
    setIsDismissed(true);
  };
  const errorRef = useRef<null | HTMLDivElement>(null);

  const defaultTemplateColumns = useBreakpointValue(gridBreakPoints as Record<string, unknown>) as string;
  const gridTempalateColumns = templateColumns ?? defaultTemplateColumns;

  const defaultTemplateRows = variation === 'timeOut' ? 'repeat(1, auto)' : 'repeat(2, auto)';
  const defaultColumns = '2/12';
  const defaultContentColumns = '2/-2';

  // Use specified if provided, otherwise choose color based on selectClass or allback color if none of the conditions match
  const alertBackgroundColor =
    backgroundColor ?? selectClass === 'alert-component-warning'
      ? '#FFFBEB'
      : selectClass === 'alert-component-error'
      ? '#fee2e2'
      : selectClass === 'alert-component-time-out'
      ? 'var(--amplify-components-alert-info-background-color)'
      : 'defaultColor';

  useEffect(() => {
    errorRef.current?.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
  }, []);

  return (
    <Grid
      templateColumns={gridTempalateColumns}
      templateRows={templateRows ?? defaultTemplateRows}
      className={className}
      padding={{ base: '0px 24px', small: '0px' }}
      margin="0px"
      overflow="hidden"
      maxWidth="100vw"
      backgroundColor={alertBackgroundColor}
      column={column ?? defaultColumns}
      ref={errorRef}
      {...(columnGap && { columnGap })}
      {...(isDismissed && { gap: '0px' })}
    >
      <Grid
        templateColumns={templateColumns}
        column={contentColumn ?? defaultContentColumns}
        className="alert-content"
        data-testid="alert-content"
      >
        <Alert
          {...alertProps}
          variation={variationCheck}
          testId="alert-component"
          heading={title}
          className={`${selectClass} alert-component`}
          dismissButtonLabel="close notification"
          column="1/-1"
          backgroundColor={alertBackgroundColor}
          onDismiss={handleDismiss}
          // 'warning' and 'info' are currently without alert being dismissible
          isDismissible={isDismissible}
        >
          {(textHeader || textHeaderComponent) && (
            <>
              <Text className="alert-text-header" testId="alert-text-header">
                {textHeader}
                {textHeaderComponent}
                {variation === 'error' && errorCode && (
                  <span className="alert-error-code"> (Error code: {errorCode})</span>
                )}
              </Text>
              {secondTextHeaderComponent && <Text className="alert-text-header">{secondTextHeaderComponent}</Text>}
            </>
          )}
          {listItem && (
            <ul className="alert-texts" data-testid="alert-texts">
              {listItem.map((text: string, i: number) => (
                <li key={i}>{text}</li>
              ))}
            </ul>
          )}
        </Alert>
      </Grid>
    </Grid>
  );
};
