/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { Text, Flex } from '@aws-amplify/ui-react';
import Select from 'react-select';
import './InsightsSelect.scss';

interface Props {
  title: string;
  value: any;
  handleChange: (e: { label: string; value: string }) => any;
  options: {
    label: string;
    value: string | number;
  }[];
  minWidth?: string;
  inputId: string;
  ariaLabel: string;
  style?: React.CSSProperties;
}

const InsightsSelect: React.FC<Props> = ({
  title,
  value,
  handleChange,
  options,
  minWidth = '150px',
  inputId,
  ariaLabel,
  style,
}) => (
  <Flex direction="column" gap="0.25rem" testId={`select-insights-input-${title}`} minWidth={minWidth} style={style}>
    <Text
      className="select-business-label"
      data-testid="select-business-label"
      style={{ marginBottom: '0px', fontWeight: '600', fontSize: '16px', lineHeight: '24px' }}
    >
      {title}
    </Text>
    <Select
      className="insight-select-parent"
      classNamePrefix="insight-select"
      value={value}
      inputId={inputId}
      options={options}
      maxMenuHeight={215}
      onChange={handleChange}
      aria-label={ariaLabel}
    />
  </Flex>
);

export default InsightsSelect;
