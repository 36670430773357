/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Flex, View, Link, Button } from '@aws-amplify/ui-react';
import { useOktaAuth } from '@okta/okta-react';
import { useLocation } from 'react-router-dom';

import { doLogout } from 'utils/doLogout';
import { useShowSideNav, toggleShowSideNav } from 'utils/getShowSideNav';
import { ReactComponent as DashboardIconSvg } from 'assets/icon-dashboard.svg';
import { ReactComponent as ManageConnectionsIconSvg } from 'assets/icon-manage-connections.svg';
import { ReactComponent as AnalyticsIconSvg } from 'assets/icon-analytics.svg';
import { ReactComponent as LogoutIconSvg } from 'assets/icon-logout-side-bar.svg';
import { ReactComponent as CloseMenuIconSvg } from 'assets/icon-menu-close.svg';
import { ReactComponent as HelpMenuIconSvg } from 'assets/icon-help.svg';
import { externalLinks } from 'lib/externalLinks';

import './DashboardSideNav.scss';
import React, { ReactNode, useEffect } from 'react';
import { setFocus } from 'utils/setFocus';

interface NavLinkProps {
  id: string;
  location: string;
  children: ReactNode;
}

const NavLink = ({ id, location, children }: NavLinkProps) => {
  const url = `/${id}`;
  const current = location === url;
  return (
    <Link
      data-testid={`nav-to-${id}`}
      aria-current={current}
      href={current ? undefined : url}
      className={`${id} dashboard-nav-link ${current ? 'active-dashboard-link' : ''}`}
    >
      <Flex alignItems="center">{children}</Flex>
    </Link>
  );
};

const DashboardSideNav = () => {
  const location = useLocation().pathname;
  const { oktaAuth } = useOktaAuth();
  const showSideNav = useShowSideNav();

  useEffect(() => {
    const html = document.querySelector('html');
    if (html) {
      html.style.overflow = showSideNav ? 'hidden' : 'auto';
    }
    if (showSideNav) {
      setFocus('dashboard-nav-focus-target');
    } else {
      setFocus('menu-button-focus-target');
    }
  }, [showSideNav]);

  const HandleLogout: React.MouseEventHandler<HTMLButtonElement> = () => {
    doLogout(oktaAuth);
  };

  return (
    <>
      <nav
        className={`dashboard-nav${!useShowSideNav() ? ' hide-sidenav' : ''}`}
        id="dashboard-nav-focus-target"
        style={{
          left: useShowSideNav() ? '0px' : '-288px',
          borderRight: useShowSideNav() ? '' : 'solid 1px #EFF0F0',
        }}
        ref={(nav) => nav && showSideNav && nav.focus()}
      >
        <Flex className="nav-header" testId="nav-header">
          <Button onClick={toggleShowSideNav} style={{ border: '0', padding: '0 ' }}>
            <CloseMenuIconSvg className="side-nav-icon" />
          </Button>
        </Flex>
        <View className="dashboard-body" padding={{ base: 'var(--amplify-space-medium) 0', medium: '0' }}>
          <ul>
            <li>
              <NavLink id="dashboard" location={location}>
                <DashboardIconSvg className="side-nav-icon" />
                Dashboard
              </NavLink>
            </li>
            <li>
              <NavLink id="manage-connections" location={location}>
                <ManageConnectionsIconSvg className="side-nav-icon" />
                Manage connections
              </NavLink>
            </li>
            <li>
              <NavLink id="industry-insights" location={location}>
                <AnalyticsIconSvg />
                Industry insights
              </NavLink>
            </li>
            <div className="divider">
              <div className="divider-line" />
            </div>
            <li>
              <Link
                target="_blank"
                data-testid="nav-to-help"
                href={externalLinks.absContactUs}
                className="help dashboard-nav-link"
                style={{ display: 'flex' }}
              >
                <Flex alignItems="center">
                  <HelpMenuIconSvg />
                  Help
                </Flex>
              </Link>
            </li>
            <div className="divider">
              <div className="divider-line" />
            </div>
            <li>
              <button
                type="button"
                data-testid="dashboard-log-out"
                className="amplify-link dashboard-nav-link"
                onClick={HandleLogout}
                style={{ display: 'flex' }}
              >
                <Flex alignItems="center">
                  <LogoutIconSvg className="side-nav-icon" />
                  Log out
                </Flex>
              </button>
            </li>
          </ul>
        </View>
      </nav>
      <div className="overlay" onClick={toggleShowSideNav} style={{ display: useShowSideNav() ? 'flex' : 'none' }} />
    </>
  );
};

export default DashboardSideNav;
