// noinspection GraphQLUnresolvedReference

import { gql } from '@apollo/client';

export const GET_REPORT_DATA_INITIAL_LOAD = gql`
  query GetReport($dspProvider: String!, $userReportId: ID!, $organisationId: ID!) {
    getReport(dspProvider: $dspProvider, userReportId: $userReportId, organisationId: $organisationId) {
      success
      errorMessages {
        errorCode
        language
        message
        metaData
        errorType
        stack
        time
      }
      report {
        singleUserAccess {
          lock
          selfLock
        }
        reportStatus
        contacts {
          firstName
          lastName
          email
          phone
          role
        }
        reportDataItems {
          dataItemId
          items {
            responseUid
            dspValue
            userValue
            dspDateRead
            mapState
            type
            isActive
            class
            dataItemSaveTime
            accountName
            accountCode
          }
        }
        lastMapState {
          accountUid
          dataItemId
        }
        dspDataItems {
          accountName
          class
          code
          dspDateSave
          dspValue
          isActive
          responseUid
          type
          suggestForDataItem
        }
        topicAndDataItems {
          dataItems {
            accountClass
            action
            showSelectAllWhenAccountsNotFiltered
            dataItemId
            dataItemInformation
            dataItemInformationNoDsp
            dataItemText
            dataItemTextNoDsp
            dataItemLabel
            dataItemLabelNoDsp
            position
            topic
          }
          topics {
            topicItems {
              title
              topicDetails {
                action
                rule
                ruleText
                ruleDescription
                ruleDescriptionNoDsp
                topic
                topicText
                topicTotalInfo
                topicTotalInfoNoDsp
                topicTotalText
                totalDisplay
                topicTitle
                topicShortTitle
              }
            }
          }
        }
      }
    }
  }
`;
