import makeVarPersisted from 'apollo/MakeVarPersisted';
import { getMergedReportDataItems } from 'utils/getUpdatedReportDataItems';
import { ChosenReport, SavedReportDataItems } from 'models';

/**
 * Snapshot of all report data needed to save a report in the background.
 */
export interface ReportSnapshot {
  savedValues: SavedReportDataItems;
  serverSavedValues: SavedReportDataItems;
  reportData: ChosenReport;
  context: {
    component: string;
    callSite: string;
    triggeredBy: string;
    triggerLocation: string;
  };
}

/**
 * Snapshot of all report data needed to save the report in the background,
 * and possible queued snapshots to be saved next.
 */
export interface ReportSnapshotSet extends ReportSnapshot {
  queuedSnapshots: ReportSnapshot[];
}

// Actions for reducer
type ActionType = 'SAVE_SUCCESS' | 'QUEUE_BACKGROUND_SAVE' | 'COMPLETED_BACKGROUND_SAVE' | 'FAILED_BACKGROUND_SAVE';
interface Action {
  type: ActionType;
  userReportId: string;
}
interface SuccessAction extends Action {
  type: 'SAVE_SUCCESS';
  updatesInSave: SavedReportDataItems;
}
interface QueueAction extends Action {
  type: 'QUEUE_BACKGROUND_SAVE';
  report: ReportSnapshot;
}
interface CompletedAction extends Action {
  type: 'COMPLETED_BACKGROUND_SAVE';
}
interface FailedAction extends Action {
  type: 'FAILED_BACKGROUND_SAVE';
}
export type BackgroundSaveAction = SuccessAction | QueueAction | CompletedAction | FailedAction;

// Purposely not exported, other components should not touch this
// (just using reactive variable for easy reuse of persistence code)
export const initialBackgroundSaveData: ReportSnapshotSet[] = [];

const backgroundSaveDataRV = makeVarPersisted(initialBackgroundSaveData, 'aspBackgroundSaveData');

export const init = (initialState: ReportSnapshotSet[]) => {
  // hydrate the state from session storage in case the user refreshed the page
  // Note: no attempt to prevent duplicate saves on 2 tabs as it is benign
  const backgroundSaveData = backgroundSaveDataRV();
  return backgroundSaveData ?? initialState;
};

export const reducer = (state: ReportSnapshotSet[], action: BackgroundSaveAction) => {
  const { userReportId } = action;
  // predicates to match against userReportId
  const actionReport = (r: ReportSnapshot) => r.reportData.userReportId === userReportId;
  const notActionReport = (r: ReportSnapshot) => !actionReport(r);
  // wrap update function to map updating just the action report
  const updateIfActionReport = (update: (r: ReportSnapshotSet) => ReportSnapshotSet) => (r: ReportSnapshotSet) =>
    actionReport(r) ? update(r) : r;
  let newState = state;

  switch (action.type) {
    case 'SAVE_SUCCESS':
      newState = newState.map(
        updateIfActionReport((report) => {
          // compute the new server values after this save
          // FIXME: item within listsum with same responseUid and different userValue has both old and new
          const serverSavedValues = getMergedReportDataItems(
            report.savedValues,
            report.serverSavedValues,
            action.updatesInSave
          );

          return { ...report, serverSavedValues };
        })
      );
      break;

    case 'QUEUE_BACKGROUND_SAVE': {
      // adding another report to save, or another save for an already-saving report
      const index = state.findIndex(actionReport);
      if (index === -1) {
        newState = [...state, { ...action.report, queuedSnapshots: [] }];
      } else {
        // queueing a save for a report that is already queued, would usually indicate we coded something wrong
        console.warn(`queue background save for report that is already background saving: ${userReportId}`);
        newState = state.map(
          updateIfActionReport((report) => ({ ...report, queuedSnapshots: [...report.queuedSnapshots, action.report] }))
        );
      }
      break;
    }

    case 'COMPLETED_BACKGROUND_SAVE':
    case 'FAILED_BACKGROUND_SAVE': {
      // current snapshot is fully saved, replace state with the next queuedSnapshot, or remove if there are none queued
      const oldReport = state.find(actionReport);
      // report should be present, but if it isn't then no need to remove it
      if (!oldReport) break;

      // See if there are any snapshots that have updated saved values
      // (identical savedValues means we've already finished saving those)
      const nextSnapshotIndex = oldReport.queuedSnapshots.findIndex(
        (snapshot) => snapshot.savedValues !== oldReport.savedValues
      );
      if (nextSnapshotIndex === -1) {
        // report is all done, remove it
        newState = state.filter(notActionReport);
        break;
      }

      // process the snapshot instead of removing the report entirely
      const nextSnapshot = oldReport.queuedSnapshots[nextSnapshotIndex];

      // For successful save only, keep the existing serverSavedValues since that should be
      // a more accurate record of what is on the server (as it was updated during the previous save).
      // This is imperfect, but generally we won't hit this condition unless the same report
      // is queued for background save twice in a small space of time, so it is very unlikely
      // any new server state would have come in.
      const { serverSavedValues } = oldReport;
      const retainedServerSavedValues = action.type === 'COMPLETED_BACKGROUND_SAVE' ? { serverSavedValues } : {};
      const queuedSnapshots = oldReport.queuedSnapshots.slice(nextSnapshotIndex + 1);

      newState = state.map(
        updateIfActionReport(() => ({ ...nextSnapshot, ...retainedServerSavedValues, queuedSnapshots }))
      );
      break;
    }

    default:
      console.error('backgroundSaveReducer received unknown action type', action);
      break;
  }

  if (newState !== state) {
    backgroundSaveDataRV(newState);
  }

  return newState;
};
