/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import {
  Flex,
  Grid,
  Heading,
  TabItem,
  Tabs,
  Text,
  View,
  VisuallyHidden,
  useBreakpointValue,
} from '@aws-amplify/ui-react';
import { useReactiveVar } from '@apollo/client';

import BusinessSelect from 'components/BusinessSelect/BusinessSelect';
import DashboardInfo from 'components/DashboardInfo/DashboardInfo';
import ReportSummary from 'components/ReportSummary/ReportSummary';
import { ReportStatus } from 'enums/ReportStatus';
import { AccessDetail, ChosenReport, Delegate, ReportOverview } from 'models';
import { getDsp } from 'utils/dspIndex';
import {
  ReportEndFormat,
  ReportExtendedQuarterFormat,
  ReportPeriodFormat,
  ReportTitleQuarterFormat,
  ReportQuarterFormat,
} from 'utils/ReportPeriodFormat';
import { formatAbn } from 'utils/formatAbn';
import { useUserInfo } from 'lib/userInfoHook';
import { authorisedBusinesses } from 'apollo/states/AuthorisedBusinesses';

import './Dashboard.css';
import { dashboardReportTabState } from 'apollo/states/operationsInProgress';
import InsightsWidget from 'components/InsightsWidget/InsightsWidget';
import { useEffect, useState } from 'react';
import { canUserDelegateNoDspOrganisation, doesUserOwnOrganisation } from 'utils/canUserDelegateNoDspOrganisation';
// import { DownloadAllReportsButton } from './DownloadAllReportsButton';

const reportDataForFactory =
  (selectedOrganisation: AccessDetail | undefined) =>
  (report: ReportOverview): ChosenReport => ({
    dspProvider: selectedOrganisation?.dsp,
    organisationId: selectedOrganisation?.organisationDetails?.organisationId,
    singleUserAccess: report.singleUserAccess,
    userReportId: report.userReportId,
    dspAbn: selectedOrganisation?.organisationDetails?.providerDetails.currentPEGAABN,
    reportName: report.name,
    reportPeriod: ReportPeriodFormat(report.spRefPeriodStartDate, report.spRefPeriodEndDate),
    reportEnd: ReportEndFormat(report.spRefPeriodEndDate),
    reportStart: ReportEndFormat(report.spRefPeriodStartDate),
    reportDateRangeText: ReportQuarterFormat(report.spRefPeriodStartDate, report.spRefPeriodEndDate),
    reportDateRangeTitleText: ReportTitleQuarterFormat(report.spRefPeriodStartDate, report.spRefPeriodEndDate),
    reportExtendedDateRangeText: ReportExtendedQuarterFormat(report.spRefPeriodStartDate, report.spRefPeriodEndDate),
    organisationName: selectedOrganisation?.organisationDetails?.providerDetails.name,
    reportDueDate: report?.dueDate,
    reportLastEditedDate: report.lastEditedDate,
    dspDisplayName: getDsp(selectedOrganisation?.dsp).displayName,
    isUsingDsp: getDsp(selectedOrganisation?.dsp).isUsingDspProvider,
  });

const ReportSummaries = ({
  reports,
  selectedOrganisation,
  preCacheReports,
  loadingReportId,
  setLoadingReportId,
  canDelegate,
  delegates,
  maxDelegatesReached,
  isOwner,
}: {
  selectedOrganisation: AccessDetail | undefined;
  reports: ReportOverview[] | undefined;
  preCacheReports?: string[];
  loadingReportId: string;
  setLoadingReportId: (id: string) => void;
  canDelegate: boolean;
  delegates: Delegate[];
  maxDelegatesReached: boolean;
  isOwner: boolean;
}) => {
  const reportDataFor = reportDataForFactory(selectedOrganisation);

  // Commented-out lines here (and in imports) are for reference when implementing ASP-5670 next sprint
  // TODO: avoid generating this twice
  // const allReportsData = reports?.map((report) => reportDataFor(report));

  return (
    <Flex gap={31} direction="column" className="tab-current-reports-container fade-in">
      {/* {allReportsData && <DownloadAllReportsButton reports={allReportsData} />} */}

      {reports?.map((report) => (
        <ReportSummary
          name={report.name}
          lastEditedDate={report.lastEditedDate}
          cycleStartDate={report.spRefPeriodStartDate}
          cycleEndDate={report.spRefPeriodEndDate}
          dueDate={report.dueDate}
          status={report.status as ReportStatus}
          reportData={reportDataFor(report)}
          key={report.userReportId}
          preCache={preCacheReports?.includes(report.userReportId)}
          loadingReportId={loadingReportId}
          setLoadingReportId={setLoadingReportId}
          lockState={report.singleUserAccess}
          canDelegate={canDelegate}
          delegates={delegates}
          maxDelegatesReached={maxDelegatesReached}
          isOwner={isOwner}
        />
      ))}
    </Flex>
  );
};

export const isCurrentStatus = (status: string) =>
  [ReportStatus.New.toLowerCase(), ReportStatus.Edit.toLowerCase(), ReportStatus.UnderReview.toLowerCase()].includes(
    status.toLowerCase()
  );
export const isPreviousStatus = (status: string) =>
  [
    ReportStatus.NotSubmitted.toLowerCase(),
    ReportStatus.Submitted.toLowerCase(),
    ReportStatus.SubmittedFeedback.toLowerCase(),
  ].includes(status.toLowerCase());

interface DashboardProps {
  data?: AccessDetail[];
}

const Dashboard = ({ data }: DashboardProps) => {
  const currentBusinesses = useReactiveVar(authorisedBusinesses);
  const userInfo = useUserInfo();
  // reportId of the report that is launching, if any (getReport in progress)
  const [loadingReportId, setLoadingReportId] = useState('');

  const selectedOrganisation = data?.find(
    (business) => business.organisationDetails?.organisationId === currentBusinesses.activeBusiness?.id
  );

  const canDelegate = canUserDelegateNoDspOrganisation(userInfo, selectedOrganisation);
  const delegates: Delegate[] =
    (selectedOrganisation?.organisationDetails?.providerDetails?.delegates as Delegate[]) ?? [];

  const isOwner = doesUserOwnOrganisation(userInfo, selectedOrganisation);

  const maxDelegatesReached = Boolean(selectedOrganisation?.organisationDetails?.providerDetails.maxDelegatees);

  // sort before we pass into report summaries
  selectedOrganisation?.providerReports?.sort((a, b) => b.dueDate - a.dueDate);

  const currentReports = selectedOrganisation?.providerReports?.filter((report) => isCurrentStatus(report.status));
  const pastReports = selectedOrganisation?.providerReports?.filter((report) => isPreviousStatus(report.status));

  // Pre-fetching of the top reports so they are in apollo cache
  const preCacheReports = currentReports?.slice(0, 3).map((report) => report.userReportId);

  // Setting the tab state to ensure it is the default value of 'current'
  useEffect(() => {
    dashboardReportTabState('current');
  }, []);

  return (
    <Flex gap="48px" direction="column">
      <Grid
        className="dashboard-wrapper"
        templateColumns="1fr"
        marginTop={{ base: '0px', medium: '48px' }}
        padding="var(--amplify-space-large)"
        borderRadius="var(--amplify-radii-large)"
      >
        <Grid
          templateColumns={{ base: '1fr', xl: '1fr 1fr' }}
          className="dashboard-header-wrapper"
          gap="var(--amplify-space-xxl)"
        >
          <Flex
            direction={{ base: 'column', xl: 'row' }}
            gap="24px"
            column={{ base: '1/-1' }}
            justifyContent="space-between"
          >
            <View as="section">
              <Heading fontWeight="600" fontSize="40px" lineHeight="48px" testId="dashboard-header-heading" level={1}>
                <VisuallyHidden>Dashboard for </VisuallyHidden>
                {selectedOrganisation?.organisationDetails?.providerDetails.name || 'Dashboard'}
              </Heading>
              {getDsp(selectedOrganisation?.dsp).showDspBusinessName &&
                selectedOrganisation?.organisationDetails?.dspBusinessName !== '' && (
                  <Heading className="dashboard-dsp-name-heading" testId="dsp-name-heading" level={2}>
                    {selectedOrganisation?.organisationDetails?.dspBusinessName}
                  </Heading>
                )}
            </View>
            <View
              testId="dashboard-header-business-select-wrapper"
              maxWidth={{ base: 'none', medium: '288px' }}
              minWidth={{ base: 'none', medium: '208px' }}
              width="100%"
            >
              <BusinessSelect />
            </View>
          </Flex>
          <View className="dashboard-abn-wrapper" column="1/-1">
            <Heading
              color="#326297"
              fontWeight="600"
              fontSize="24px"
              lineHeight="32px "
              testId="dashboard-header-heading"
              level={2}
            >
              Welcome, {userInfo?.firstName}
            </Heading>
            <Text color="#304050" fontWeight="400" fontSize="16px" lineHeight="24px" testId="dashboard-abn-text">
              Welcome to your dashboard. Here you can access your surveys and change the business you are reporting for.
            </Text>
          </View>
        </Grid>
      </Grid>
      <Grid className="business-info" templateColumns="1fr" rowGap="24px">
        <Heading level={2} fontWeight="600" fontSize="32px" lineHeight="40px" letterSpacing="-0.02em">
          Business information
        </Heading>
        <Grid
          templateColumns={{ base: '1fr 1fr', small: '1fr 1fr 2fr 2fr', large: '3fr 3fr 3fr 3fr' }}
          className="dashboard-data-wrapper"
        >
          <DashboardInfo
            heading="Business Name"
            uniqueId="trading-name"
            data={selectedOrganisation?.organisationDetails?.providerDetails.name}
            style={{ gridColumn: useBreakpointValue({ base: '1/-1', small: '1/3', large: '1/2' }) as string }}
          />
          <DashboardInfo
            heading="ABN"
            uniqueId="business-abn"
            data={formatAbn(selectedOrganisation?.organisationDetails?.providerDetails.currentPEGAABN)}
            style={{ gridColumn: useBreakpointValue({ base: '1/-1', small: '3/-1', large: '2/2' }) as string }}
          />
          <DashboardInfo
            heading="State"
            uniqueId="state"
            data={selectedOrganisation?.organisationDetails?.providerDetails?.address?.state}
          />
          <DashboardInfo
            heading="Postcode"
            uniqueId="postcode"
            data={selectedOrganisation?.organisationDetails?.providerDetails?.address?.postcode}
          />
        </Grid>
      </Grid>
      <div className="tab-container">
        <Heading marginBottom="25px" level={2} fontSize="28px" fontWeight="600">
          Quarterly Business Indicators Survey
        </Heading>

        <Tabs testId="report-tabs" borderStyle="solid" borderWidth="0px 0px 4px" borderColor="#DCDEE0">
          <TabItem
            title="Current surveys"
            testId="tab-current-reports"
            onClick={() => dashboardReportTabState('current')}
            className="dashboard-tab"
          >
            {!currentReports?.length ? (
              <Text className="empty-report-list">You have no surveys to complete</Text>
            ) : (
              <ReportSummaries
                reports={[...currentReports, ...(pastReports ?? [])]}
                selectedOrganisation={selectedOrganisation}
                preCacheReports={preCacheReports}
                loadingReportId={loadingReportId}
                setLoadingReportId={setLoadingReportId}
                canDelegate={canDelegate}
                delegates={delegates}
                maxDelegatesReached={maxDelegatesReached}
                isOwner={isOwner}
              />
            )}
          </TabItem>
          <TabItem
            title="Past surveys"
            testId="tab-submitted-reports"
            onClick={() => dashboardReportTabState('past')}
            className="dashboard-tab"
          >
            {!pastReports?.length ? (
              <Text className="empty-report-list">You have no past surveys to view</Text>
            ) : (
              <ReportSummaries
                reports={[...pastReports, ...(currentReports ?? [])]}
                selectedOrganisation={selectedOrganisation}
                preCacheReports={preCacheReports}
                loadingReportId={loadingReportId}
                setLoadingReportId={setLoadingReportId}
                canDelegate={canDelegate}
                delegates={delegates}
                maxDelegatesReached={maxDelegatesReached}
                isOwner={isOwner}
              />
            )}
          </TabItem>
        </Tabs>
      </div>
      <View paddingBottom="32px">
        <InsightsWidget />
      </View>
    </Flex>
  );
};

export default Dashboard;
