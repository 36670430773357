import {
  ChosenReport,
  Contact,
  DataItem,
  GetReportReport,
  MapState,
  SavedReportDataItems,
  UserContactDetails,
} from 'models';
import { cleanseDspDataItems } from 'utils/cleanseDspDataItems';
import { buildSubstitutePlaceholders } from 'utils/substitutePlaceholders';
import { mapGraphQLToLocal } from 'utils/mapGraphQLToLocal';
import { mapGraphQLTopicsToLocal } from 'utils/mapGraphQLTopicsToLocal';
import { ReportStatus } from 'enums/ReportStatus';
import { AbsUserClaims } from 'lib/authConfig';
import { ReportPDFInputData } from 'utils/getPdfData';
import { buildContactDetails } from './buildContactDetails';

// FIXME use this interface, after renaming the report PDF input data and combining
//       topics+dataItems to localCurrentReportData
export interface LocalReportData extends ReportPDFInputData {
  reportStatus: string;
  returningUser: boolean;
  /** Only present if contact details were missing from report, so need to be set */
  generatedContactDetails?: Partial<Contact>;
  localLatestSavedReportDataItems: SavedReportDataItems | undefined;
}

/**
 * Process a fetched report into the local representation used by the app or PDF
 *
 * @param reportData metadata for report (from GetUserProfile)
 * @param fetchedReport data for report (from GetReport)
 * @param isForPdfDownload intended use of the data, we can skip some processing for PDF
 * @param userInfo for fallback contact info if fetchedReport does not have it, from useUserInfo
 */
export const mapGraphQLReportToLocal = (
  reportData: ChosenReport,
  fetchedReport: GetReportReport,
  isForPdfDownload: boolean,
  userInfo: AbsUserClaims | null
): LocalReportData => {
  const {
    contacts,
    reportStatus,
    dspDataItems: rawDspDataItems,
    topicAndDataItems,
    reportDataItems,
    lastMapState,
  } = fetchedReport;

  // Handle contact details setup for new users
  // Check contact details in response. If null:  update with okta, otherwise: set to local
  // Note: contact details missing from server are only updated for non-PDF workflow
  //       but the rendered PDF still uses the values from userInfo if the report does
  //       not include them.
  const baseContactDetails: Partial<Contact> = contacts[0];
  let generatedContactDetails: Partial<Contact> | undefined;
  if (!baseContactDetails) generatedContactDetails = buildContactDetails(userInfo, '');

  const localUserContactDetails = toUserContactDetails(baseContactDetails ?? generatedContactDetails);

  const dspDataItems = rawDspDataItems ? cleanseDspDataItems(rawDspDataItems) : [];
  const { dataItems: rawDataItems, topics } = topicAndDataItems[0];

  // Perform substitutions in question text
  const substitutePlaceholders = buildSubstitutePlaceholders(reportData);
  const dataItems = rawDataItems
    .map((dataItem) => {
      // If using dsp, return dataItem as is
      if (reportData.isUsingDsp) return dataItem;

      // Otherwise we're using noDsp, replace the display strings with the noDsp display strings
      const {
        dataItemInformation,
        dataItemInformationNoDsp,
        dataItemLabel,
        dataItemLabelNoDsp,
        dataItemText,
        dataItemTextNoDsp,
      } = dataItem;

      return {
        ...dataItem,
        dataItemInformation: dataItemInformationNoDsp ?? dataItemInformation,
        dataItemLabel: dataItemLabelNoDsp ?? dataItemLabel,
        dataItemText: dataItemTextNoDsp ?? dataItemText,
      };
    })
    .map((dataItem) => {
      const dataItemText = substitutePlaceholders(dataItem.dataItemText);
      if (dataItemText === dataItem.dataItemText) return dataItem;

      return { ...dataItem, dataItemText };
    });

  const returningUser = reportStatus === ReportStatus.New && lastMapState?.length > 0;
  const localChosenReport = { ...reportData, returningUser };

  // On first load of report for returning users, use answers from their previously submitted report (lastMapState)
  // as the basis for this report's answers (savedReportDataItems.).
  const savedDataItems =
    isForPdfDownload || !returningUser ? reportDataItems : lastMapStateToReportDataItems(lastMapState, dataItems)[0];
  const localSavedReportDataItems = mapGraphQLToLocal(savedDataItems, dataItems, dspDataItems, !isForPdfDownload);
  const localTopics = mapGraphQLTopicsToLocal(topics, dataItems, reportData.isUsingDsp);
  // Not needed for PDF generation
  const localLatestSavedReportDataItems = isForPdfDownload
    ? undefined
    : mapGraphQLToLocal(reportDataItems, dataItems, dspDataItems);

  // continue the theme of constructing all the local state values in here
  const localCurrentReportData = { dataItems, dspDataItems, topics: localTopics };

  return {
    reportStatus,
    returningUser,
    generatedContactDetails,
    localUserContactDetails,
    localChosenReport,
    localCurrentReportData,
    localSavedReportDataItems,
    localLatestSavedReportDataItems,
  };
};

function lastMapStateToReportDataItems(lastMapState: MapState[], dataItems: DataItem[]) {
  // IDs that appear in more than 1 data item.
  const duplicateMappingUids = lastMapState
    .flatMap(({ accountUid }) => accountUid)
    // keep only uuids that are not the first index of that uuid
    .filter((uid, i, allUids) => allUids.indexOf(uid) !== i);

  // Topics that have duplicate UUIDs being excluded
  // Note: topic can appear multiple times in this list unless I de-duplicate it
  const topicsWithDuplicateMappings = lastMapState
    .filter((item) => item.accountUid.some((uid) => duplicateMappingUids.includes(uid)))
    .map((item) => dataItems.find((dataItem) => dataItem.dataItemId === item.dataItemId))
    .filter((item) => item)
    .map((item) => item?.topic)
    // keeps only the first occurrence of each topic
    .filter((topic, i, allTopics) => allTopics.indexOf(topic) === i);

  const reportDataItems = lastMapState
    .map((item) => ({
      dataItemId: item.dataItemId,
      items: item.accountUid.filter((uid) => !duplicateMappingUids.includes(uid)).map((uid) => ({ responseUid: uid })),
    }))
    .filter((item) => item.items.length && dataItems.find((dataItem) => dataItem.dataItemId === item.dataItemId));

  // FIXME: use these computed duplicate values to drive the duplicate mappings feature
  return [reportDataItems, topicsWithDuplicateMappings, duplicateMappingUids] as const;
}

function toUserContactDetails(contact: Partial<Contact> | undefined): UserContactDetails {
  return {
    firstName: contact?.firstName ?? '',
    lastName: contact?.lastName ?? '',
    email: contact?.email ?? '',
    mobile: contact?.phone ?? '',
    role: contact?.role ?? '',
  };
}
