import { recordRumCustomEventWithPageId } from 'services/awsRum';
import './Text.scss';
import { Heading, Card, Link, Text, Grid, View, useBreakpointValue } from '@aws-amplify/ui-react';
import { RumCustomEvent } from 'enums/RumCustomEvent';
import { getRumAttributes } from 'utils/getRumAttributes';
import { EmbedVideo } from '../EmbedVideo/EmbedVideo';

interface Props {
  className?: string;
  style?: string;
  heading?: string;
  summary?: string;
  list?: string[];
  footer?: {
    text: string;
    link?: {
      url: string;
      text: string;
    };
    linkTwo?: {
      url: string;
      text: string;
    };
  };
  uniqueId: string;
  videoSrc?: string;
  videoTitle?: string;
}
const textFontSize = '18px';
const footerMargin = '5rem';

export const TextComponent: React.FC<Props> = ({
  className,
  style,
  heading,
  summary,
  list,
  footer,
  uniqueId,
  videoSrc = '',
  videoTitle = '',
}) => {
  const tileListTemplate = useBreakpointValue({ base: 'repeat(1, auto)', medium: 'repeat(2, auto)' }) as string;
  const headerFontStyling = useBreakpointValue({
    base: {
      fontSize: '28px',
      lineHeight: '36px',
    },
    medium: {
      fontSize: '32px',
      lineHeight: '40px',
    },
  }) as object;
  return (
    <Card
      className={`text-component ${className ?? ''}`}
      testId={`text-component-card-container-${uniqueId}`}
      variation={style === 'card' ? undefined : undefined}
      padding={style === 'card' ? '0 0 16px 0' : undefined}
      margin={style === 'card' ? undefined : undefined}
    >
      <Heading
        testId={`text-component-card-heading-${uniqueId}`}
        margin={style === 'card' ? '0 0 0' : '0 0 xxl'}
        level={style === 'card' ? 3 : 1}
        fontWeight="600"
        color="#0D1926"
        {...headerFontStyling}
      >
        {heading}
      </Heading>
      {videoSrc ? <EmbedVideo src={videoSrc} title={videoTitle} /> : null}
      {summary ? (
        <Text fontSize={textFontSize} testId={`text-component-card-text-${uniqueId}`} as="p">
          {summary}
        </Text>
      ) : null}
      {list ? (
        <Grid
          className="content-tile-list"
          data-testid="content-list"
          templateColumns={tileListTemplate}
          gap="32px 16px"
          templateRows="1fr 1fr"
        >
          {list?.map((content, id) => (
            <View className="content-tile-list-item" key={id} data-testid={`list-item-${id}`}>
              {content}
            </View>
          ))}
        </Grid>
      ) : null}
      {footer ? (
        // Footer is only used to display Privacy and Data Usage links on AuthorisationPage.tsx, it would be worth
        // considering whether the footer needs to exist in this component, or whether either a new component
        // specifically for this footer (including contact us) should be created.
        <Text
          maxWidth="900px"
          testId={`text-component-card-footer-text-${uniqueId}`}
          marginTop={style === 'card' ? '52px' : footerMargin}
        >
          {footer?.text.split(`${footer?.link?.text}`)[0]}
          <Link
            testId={`text-component-card-footer-link-${uniqueId}`}
            href={footer?.link?.url}
            isExternal
            onClick={() => recordRumCustomEventWithPageId(RumCustomEvent.privacyAtAbsClicked, getRumAttributes())}
          >
            {footer?.link?.text}
          </Link>
          {footer?.linkTwo ? (
            <>
              {footer?.text.split(`${footer?.link?.text}`)[1].split(`${footer.linkTwo.text}`)[0]}
              <Link
                testId={`text-component-card-footer-link-${uniqueId}`}
                href={footer?.linkTwo?.url}
                isExternal
                onClick={() =>
                  recordRumCustomEventWithPageId(RumCustomEvent.dataReportingUsageClicked, getRumAttributes())
                }
              >
                {footer?.linkTwo?.text}
              </Link>
              {footer?.text.split(`${footer?.link?.text}`)[1].split(`${footer.linkTwo.text}`)[1]}
            </>
          ) : (
            footer?.text.split(`${footer?.link?.text}`)[1]
          )}
        </Text>
      ) : null}
    </Card>
  );
};
