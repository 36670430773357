import { components, ControlProps, DropdownIndicatorProps, StylesConfig } from 'react-select';
import { ReactComponent as ChevronDownSvg } from 'assets/icon-chevron-down.svg';
import { ReactComponent as ChevronUpSvg } from 'assets/icon-chevron-up.svg';

// Intentionally any option type to make it compatible to spread in other styles config objects with concrete types
export const businessSelectStyles: StylesConfig<any> = {
  control: (styles) => ({
    ...styles,
    borderRadius: '8px',
    borderWidth: '0px',
    boxShadow: 'box-shadow: 0px 2px 6px 0px var(--amplify-colors-shadow-secondary)',
  }),
  menu: (styles) => ({
    ...styles,
    marginTop: 10,
    borderRadius: '8px',
    border: '1px solid #878889',
    boxShadow: '0px 12px 16px -4px rgba(0, 0, 0, 0.1), 0px 4px 6px -2px rgba(0, 0, 0, 0.06)',
  }),
  menuList: (styles) => ({
    ...styles,
    padding: 0,
    borderRadius: '8px',
  }),
  option: (styles) => ({ ...styles, padding: '11px 16px' }),
  indicatorSeparator: (styles) => ({ ...styles, display: 'none' }),
  container: (styles) => ({ ...styles, padding: '0px' }),
  indicatorsContainer: (styles) => ({ ...styles, padding: '0px' }),
  dropdownIndicator: (styles) => ({ ...styles, padding: '0px' }),
  input: (styles) => ({ ...styles, margin: '0px', padding: '0px' }),
};

export const CustomDropdownIndicator = <T,>(props: DropdownIndicatorProps<T>) => (
  <components.DropdownIndicator {...props}>
    {/* eslint-disable-next-line react/destructuring-assignment */}
    {props.selectProps.menuIsOpen ? <ChevronUpSvg width={24} height={24} /> : <ChevronDownSvg width={24} height={24} />}
  </components.DropdownIndicator>
);

export const Control = <T,>({ children, ...props }: ControlProps<T>) => (
  <components.Control {...props}>{children}</components.Control>
);
