/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import highchartsAccessibility from 'highcharts/modules/accessibility';
import highchartsNoDataToDisplay from 'highcharts/modules/no-data-to-display';
import { ErrorStateGraph } from './errorStateGraph';

highchartsNoDataToDisplay(Highcharts);
highchartsAccessibility(Highcharts);

type GraphProps = {
  options: Highcharts.Options | object;
  onRetry: () => void;
  isLoading?: boolean;
  error?: boolean;
  showRetry?: boolean;
};

const Graph: React.FC<GraphProps> = ({ options, onRetry, isLoading = true, error = false, showRetry = true }) => {
  const renderGraph = () => {
    if (isLoading) {
      return (
        <div className="skeleton" style={{ width: '100%', height: '100%', maxWidth: '676px', maxHeight: '400px' }} />
      );
    }
    if (error) {
      return <ErrorStateGraph onRetry={() => onRetry()} showRetry={showRetry} />;
    }
    return <HighchartsReact highcharts={Highcharts} options={options} />;
  };

  return renderGraph();
};

export default Graph;
