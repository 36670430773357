import { chosenReport } from 'apollo/states/ChosenReport';
import { savedReportDataItems } from 'apollo/states/SavedReportDataItem';
import { latestSavedReportDataItems } from 'apollo/states/LatestSavedReportDataItems';
import { requestBackgroundSave } from './useBackgroundSaveReducer';

type SaveContext = Parameters<typeof requestBackgroundSave>[3];

export const backgroundSaveCurrentReport = (context: SaveContext) => {
  requestBackgroundSave(chosenReport(), savedReportDataItems(), latestSavedReportDataItems(), context);
};
