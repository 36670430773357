import { DefaultContext, useReactiveVar } from '@apollo/client';
import { useMemo } from 'react';
import { ChosenReport, ReportDataObject, SavedReportDataItems } from 'models';
import { savedReportDataItems } from 'apollo/states/SavedReportDataItem';
import { chosenReport } from 'apollo/states/ChosenReport';
import { mapLocalToGraphQL } from 'utils/mapLocalToGraphQL';
import { latestSavedReportDataItems } from 'apollo/states/LatestSavedReportDataItems';
import {
  batchUpdateReportDataItems,
  getUpdatedReportDataItems,
  getUpdatedSavedValues,
} from './getUpdatedReportDataItems';
import { payloadLimitReportDataItems } from './payloadLimit';

export interface SaveReportContext extends DefaultContext {
  // updates in the current save batch, for use to compute the new server answers
  updatesInSave: SavedReportDataItems;
}

/**
 * Options for a useMutation call to SaveReport
 */
export interface SaveReportOptions {
  variables: {
    lockReport?: boolean | undefined;
    dspProvider: string;
    organisationId: string;
    saveTrigger: string;
    reportDataObject: ReportDataObject;
  };
  context: SaveReportContext;
}

/**
 * React hook that returns variables and context needed for a call to saveReport or submitReport.
 *
 * This will trigger component re-render when any of the variables update.
 * If you need the variables at a specific time (e.g. in an event handler)
 * you can use getSaveReportOptions to get the value once instead.
 *
 * @returns object to pass as 'variables' in a call to saveReport or submitReport
 */
export const useSaveReportOptions = (componentName: string) => {
  const savedValues = useReactiveVar<SavedReportDataItems>(savedReportDataItems);
  const serverSavedValues = useReactiveVar<SavedReportDataItems>(latestSavedReportDataItems);
  const chosenReportData = useReactiveVar<ChosenReport>(chosenReport);
  const saveTriggerLocation = window.location.pathname;
  const saveTriggerComponentName = componentName;

  const saveReportVariables = useMemo(
    () =>
      buildSaveReportOptions(
        savedValues,
        serverSavedValues,
        chosenReportData,
        saveTriggerLocation,
        saveTriggerComponentName
      ),
    [chosenReportData, savedValues, serverSavedValues, saveTriggerLocation, saveTriggerComponentName]
  );

  return saveReportVariables;
};

/**
 * Plain function that returns variables and context needed for a call to saveReport or submitReport.
 *
 * If you need a component to re-render when these values change, use the hook version
 * useSaveReportOptions.
 *
 * @returns object to pass as 'variables' in a call to saveReport or submitReport
 */
export const getSaveReportOptions = (componentName: string, lockReport?: boolean) => {
  const savedValues = savedReportDataItems();
  const serverSavedValues = latestSavedReportDataItems();
  const chosenReportData = chosenReport();
  const saveTriggerLocation = window.location.pathname;
  const saveTriggerComponentName = componentName;

  return buildSaveReportOptions(
    savedValues,
    serverSavedValues,
    chosenReportData,
    saveTriggerLocation,
    saveTriggerComponentName,
    lockReport
  );
};

// Common logic for hook and non-hook ways to generate variables and context for saveReport/submitReport
export const buildSaveReportOptions = (
  savedValues: SavedReportDataItems,
  serverSavedValues: SavedReportDataItems,
  chosenReportData: ChosenReport,
  saveTriggerLocation: string,
  saveTriggerComponentName: string,
  lockReport?: boolean
) => {
  const updatedSavedValues = getUpdatedSavedValues(savedValues, serverSavedValues);

  return toSaveReportOptions(
    updatedSavedValues,
    chosenReportData,
    saveTriggerLocation,
    saveTriggerComponentName,
    lockReport
  );
};

/**
 * Like buildSaveReportOptions, but gives an array of options objects that represent all batches of saves
 * needed to save all the changes.
 */
export const buildBatchedSaveReportOptions = (
  savedValues: SavedReportDataItems,
  serverSavedValues: SavedReportDataItems,
  chosenReportData: ChosenReport,
  saveTriggerLocation: string,
  saveTriggerComponentName: string,
  lockReport?: boolean
) => {
  const updatedSavedValues = getUpdatedReportDataItems(savedValues, serverSavedValues);
  const batches = batchUpdateReportDataItems(updatedSavedValues, payloadLimitReportDataItems);

  const optionsBatches = batches.map((answersInBatch, index, array) => {
    const lastBatch = index === array.length - 1;
    return toSaveReportOptions(
      answersInBatch,
      chosenReportData,
      saveTriggerLocation,
      `${saveTriggerComponentName}, batch ${index + 1} of ${array.length}`,
      // TODO: decide whether it makes sense to only pass in lockReport for last batch
      lastBatch ? lockReport : undefined
    );
  });

  return optionsBatches;
};

function toSaveReportOptions(
  updatesInSave: SavedReportDataItems,
  chosenReportData: ChosenReport,
  saveTriggerLocation: string,
  saveTriggerComponentName: string,
  lockReport?: boolean
): SaveReportOptions {
  const mappedSavedValues = mapLocalToGraphQL(updatesInSave, chosenReportData, false);

  return {
    variables: {
      dspProvider: chosenReportData.dspProvider || '',
      organisationId: chosenReportData.organisationId || '',
      saveTrigger: `${saveTriggerComponentName}, at ${saveTriggerLocation}`,
      reportDataObject: mappedSavedValues,
      ...(lockReport != null ? { lockReport } : {}),
    },
    context: { updatesInSave },
  };
}
