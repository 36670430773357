/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { createContext, useCallback, useEffect } from 'react';
import { useLazyQuery, useReactiveVar } from '@apollo/client';
import { useNavigate } from 'react-router';

import { recordRumCustomEvent, recordRumError } from 'services/awsRum';
import { AuthorisedBusiness, GetUserProfile } from 'models';
import Dashboard from 'components/Dashboard/Dashboard';
import LoadingSpinner from 'components/Loading/LoadingSpinner';
import DashboardLayout from 'components/DashboardLayout/DashboardLayout';
import { GET_USER_PROFILE } from 'apollo/queries/getUserProfile';
import { GET_USER_PROVIDER_REPORTS } from 'apollo/queries/getUserProviderReports';
import { ClearReportStateData, evictAllGetReportFromCache } from 'apollo/states/utils/ClearReportStateData';
import { authorisedBusinesses } from 'apollo/states/AuthorisedBusinesses';
import { RumCustomEvent } from 'enums/RumCustomEvent';
import { setErrorState } from 'utils/setErrorState';
import { getRumAttributes } from 'utils/getRumAttributes';
import { useSyncDspBusinessNames } from 'utils/useSyncDspBusinessNames';
import useSetContactDetails from 'utils/useSetContactDetails';
import { buildContactDetails } from 'utils/buildContactDetails';
import { useUserInfo } from 'lib/userInfoHook';

export const CallSyncDspBusinessNamesContext = createContext(false);
export const RefreshDashboardData = createContext<(() => void) | null>(null);

const DashboardPage = () => {
  const navigate = useNavigate();
  const userInfo = useUserInfo();
  const currentBusinesses = useReactiveVar(authorisedBusinesses);
  const [callSetContactDetail] = useSetContactDetails();
  const [callSyncDspBusinessNames, , callSyncDspBusinessNamesLoading] = useSyncDspBusinessNames();

  const [callGetUserProfile, { loading }] = useLazyQuery<GetUserProfile | undefined>(GET_USER_PROFILE, {
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    onCompleted: async (response) => {
      if (response?.getUserProfile.user?.accessDetails && response.getUserProfile.user.accessDetails.length > 0) {
        const sortedAuthorisedBusinesses = response.getUserProfile.user.accessDetails
          .map(
            (business): AuthorisedBusiness => ({
              dsp: business.dsp || '',
              id: business.organisationDetails?.organisationId || '',
              name: business.organisationDetails?.providerDetails.name || '',
              suburb: business.organisationDetails?.providerDetails.address?.suburb || '',
              postcode: business.organisationDetails?.providerDetails.address?.postcode || '',
              dspBusinessName: business.organisationDetails?.dspBusinessName || '',
              providerId: business.organisationDetails?.providerDetails.pegaProviderId || '',
              division: business.organisationDetails?.providerDetails.division || '',
              delegates: business.organisationDetails?.providerDetails.delegates || undefined,
              maxDelegatees: business.organisationDetails?.providerDetails.maxDelegatees || false,
            })
          )
          .sort((a, b) => a.name.localeCompare(b.name));

        const serverSavedActiveBusiness = sortedAuthorisedBusinesses.find(
          (business) => business.id === response.getUserProfile.user?.lastActiveBusiness?.organisationId
        );

        const latestAuthorisedBusinesses = authorisedBusinesses();
        const localSavedActiveBusiness = sortedAuthorisedBusinesses.find(
          (business) => business.id === latestAuthorisedBusinesses.activeBusiness?.id
        );

        // Setting next active business
        const nextActiveBusiness =
          localSavedActiveBusiness ?? serverSavedActiveBusiness ?? sortedAuthorisedBusinesses[0];

        // Retain needsSetContactDetails value when syncing business names with server response
        if (
          latestAuthorisedBusinesses?.activeBusiness?.needsSetContactDetails &&
          latestAuthorisedBusinesses.activeBusiness.id === nextActiveBusiness.id
        ) {
          nextActiveBusiness.needsSetContactDetails = latestAuthorisedBusinesses.activeBusiness.needsSetContactDetails;
        }

        if (!latestAuthorisedBusinesses.activeBusiness?.name)
          recordRumCustomEvent(
            RumCustomEvent.businessLoad,
            getRumAttributes({ providerId: nextActiveBusiness?.providerId })
          );

        await callSyncDspBusinessNames({
          activeBusiness: nextActiveBusiness,
          authorisedBusinesses: sortedAuthorisedBusinesses,
        });
      } else {
        await callSyncDspBusinessNames({ activeBusiness: undefined, authorisedBusinesses: [] });
      }
    },
    onError: (error) => {
      authorisedBusinesses({ activeBusiness: undefined, authorisedBusinesses: [] });
      recordRumError(error);
      setErrorState(error);
      navigate('/error');
    },
  });

  const [callGetUserProviderReports, { data }] = useLazyQuery<GetUserProfile | undefined>(GET_USER_PROVIDER_REPORTS, {
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    onCompleted: async (response) => {
      const latestAuthorisedBusinesses = authorisedBusinesses();
      // on complete of getUserProviderReports, update contact details for newly claimed business
      if (
        !response?.getUserProfile.user?.accessDetails ||
        !latestAuthorisedBusinesses.activeBusiness?.needsSetContactDetails ||
        !latestAuthorisedBusinesses.activeBusiness?.id
      )
        return;
      // get accessDetail of current active business that is newly claimed
      const activeBusinessAccessDetail = response.getUserProfile.user.accessDetails.find(
        (business) =>
          business.organisationDetails &&
          latestAuthorisedBusinesses.activeBusiness &&
          business.organisationDetails.organisationId === latestAuthorisedBusinesses.activeBusiness.id
      );
      if (!activeBusinessAccessDetail?.providerReports) return;
      // call set contact details for each report of business
      const userReportIds = activeBusinessAccessDetail.providerReports.map((report) => report.userReportId);
      const contactDetails = buildContactDetails(userInfo, activeBusinessAccessDetail.organisationDetails?.role ?? '');
      const setContactDetailRequests = userReportIds.map((userReportId) =>
        callSetContactDetail({
          variables: {
            saveContactDataObject: {
              userReportId,
              dspProvider: activeBusinessAccessDetail.dsp,
              organisationId: activeBusinessAccessDetail.organisationDetails?.organisationId,
              contactDataObject: contactDetails,
            },
          },
        })
      );
      await Promise.allSettled(setContactDetailRequests)
        // if this can fail without GraphQL error, should add success check here.
        .finally(() => {
          // have to fetch the latest authorisedBusinesses value in case it has updated
          // since latestAuthorisedBusinesses was defined.
          const currentActiveBusinesses = authorisedBusinesses();

          if (currentActiveBusinesses.activeBusiness)
            authorisedBusinesses({
              ...currentActiveBusinesses,
              // set needsSetContactDetails to false so contact details aren't set on dashboard again (even if call fails)
              activeBusiness: { ...currentActiveBusinesses.activeBusiness, needsSetContactDetails: false },
            });
        });
    },
    onError: (error) => {
      authorisedBusinesses({ activeBusiness: undefined, authorisedBusinesses: [] });
      recordRumError(error);
      setErrorState(error);
      navigate('/error');
    },
  });

  useEffect(() => {
    ClearReportStateData();
    evictAllGetReportFromCache();
    callGetUserProfile();
    callGetUserProviderReports();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const refreshReportsData = useCallback(() => {
    callGetUserProviderReports();
  }, [callGetUserProviderReports]);

  if (loading || (!currentBusinesses.activeBusiness && currentBusinesses.authorisedBusinesses.length === 0)) {
    return <LoadingSpinner fullPage />;
  }

  return (
    <DashboardLayout>
      <div id="skipTarget" />
      <CallSyncDspBusinessNamesContext.Provider value={callSyncDspBusinessNamesLoading}>
        <RefreshDashboardData.Provider value={refreshReportsData}>
          <Dashboard data={data?.getUserProfile.user?.accessDetails} />
        </RefreshDashboardData.Provider>
      </CallSyncDspBusinessNamesContext.Provider>
    </DashboardLayout>
  );
};

export default DashboardPage;
