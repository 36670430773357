import { useReactiveVar } from '@apollo/client';
import { saveTimer } from 'apollo/states/SaveTimer';
import { saveIntervalSeconds } from 'utils/saveInterval';
import dayjs from 'dayjs';
import { FC } from 'react';
import { saveReportMutexDebugState } from './saveReportMutex';
import { useCurrentReportSaveStatus, useUnsavedAnswersAndCount } from './currentReportStatus';

export const SaveReportDebugUI: FC = () => {
  const saveTimerVal = useReactiveVar(saveTimer);
  const mutexDebug = useReactiveVar(saveReportMutexDebugState);
  const currentReportSaveStatus = useCurrentReportSaveStatus();
  const [, unsavedItemsCount] = useUnsavedAnswersAndCount();

  let timeSinceSave = 'no last saved time available';
  if (saveTimerVal.lastSaved)
    timeSinceSave = Math.trunc((dayjs().valueOf() - saveTimerVal.lastSaved) / 1_000).toString();

  return (
    <ul>
      <li>currentReportSaveStatus: {currentReportSaveStatus}</li>
      <li>unsavedItemsCount: {unsavedItemsCount}</li>
      <li>saveTimer: {JSON.stringify(saveTimerVal)}</li>
      <li>
        seconds since save: {timeSinceSave} (interval: {saveIntervalSeconds})
      </li>
      <li>savesRequestingMutex: {mutexDebug.queued}</li>
    </ul>
  );
};
