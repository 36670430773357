import { Dispatch, useCallback, useEffect, useMemo, useState } from 'react';
import { ApolloError, DefaultContext, MutationFunctionOptions, OperationVariables, useMutation } from '@apollo/client';
import { ErrorMessage, SaveReportResponse } from 'models';
import { SAVE_REPORT } from 'apollo/mutations/saveReport';
import { buildBatchedSaveReportOptions, SaveReportOptions } from 'utils/getSaveReportOptions';
import { recordRumError } from 'services/awsRum';
import { BackgroundSaveAction, ReportSnapshotSet } from './backgroundSaveReducer';
import { computeReportDataItemsUpdates } from './reportStatusUtil';

// TODO: externalize these consts
// Retry attempts allowed per batch (max attempts will be 1 + retries)
const MAX_BACKGROUND_SAVE_BATCH_RETRIES = 5;
// Total failures allowed across all batches, if failures exceeds this
// value, the background save will terminate with a failure.
const MAX_BACKGROUND_SAVE_TOTAL_FAILURES = 20;
// Failures in a row without successes in between allowed across any batches, if there are
// more than this many failures in a row, the background save will terminate with a failure.
const MAX_CONSECUTIVE_FAILURES = 10;

interface SaveReportHandlerProps {
  debug: boolean;
  report: ReportSnapshotSet;
  dispatch: Dispatch<BackgroundSaveAction>;
}

/**
 * Record of a single failure of a SaveReport mutation.
 *
 * We count any of the following as a failure, so we snapshot all
 * for reference/debugging:
 *  - response.error present
 *  - response.data.saveReport.success not true
 *  - response.data.saveReport.errorMessage present and non-empty
 */
interface SaveReportFailure {
  // which save batch this failure represents
  saveBatchIndex: number;
  success?: boolean;
  errorMessage?: ErrorMessage[];
  error?: ApolloError;
}

// FIXME: add RUM errors for failed individual save attempts, batches, total failures and consecutive failures

// can't include more specific variables/context because apollo uses too broad a type for onCompleted
type SaveReportClientOptions = MutationFunctionOptions<SaveReportResponse, OperationVariables, DefaultContext>;

/**
 * Responsible for fully saving a single report in the background.
 */
export const BackgroundSaveHandler = ({ debug, report, dispatch }: SaveReportHandlerProps) => {
  const { reportData, savedValues, serverSavedValues, context } = report;
  const { userReportId: maybeUserReportId } = reportData;
  // This is optional on chosenReport, but comes from a non-nullable string so it is not clear why it is optional
  const userReportId = maybeUserReportId!;
  if (!userReportId) console.error('SaveReportHandler called with blank userReportId', userReportId, reportData);

  const onCompleted = useCallback(
    (response: SaveReportResponse, clientOptions?: SaveReportClientOptions) => {
      // not expecting errorMessage on success, but either one counts as a failure just in case
      if (response.saveReport.success && !response.saveReport.errorMessage?.length) {
        // have to cast this because the apollo types do not use the variable and context type variables properly
        const saveContext = clientOptions?.context as SaveReportOptions['context'] | undefined;
        const updatesInSave = saveContext?.updatesInSave ?? { savedAnswers: [] };

        dispatch({
          userReportId,
          type: 'SAVE_SUCCESS',
          updatesInSave,
        });
      } else {
        // This is an error state, maybe report something about the error?
        console.error(
          `SaveReportHandler onCompleted non-success state for ${userReportId}`,
          response.saveReport.errorMessage
        );
      }
    },
    [userReportId, dispatch]
  );

  const [saveReport, saveResponse] = useMutation<SaveReportResponse>(SAVE_REPORT, {
    onCompleted,
    onError: (error) => {
      recordRumError(error);

      console.error(`SaveReportHandler onError for ${userReportId}`, error);
    },
  });

  // unbound method warning for reset is from a typing bug, fixed in a later version of @apollo
  // eslint-disable-next-line @typescript-eslint/unbound-method
  const { called, loading, data, error, reset } = saveResponse;

  // TODO: store batches, batchIndex, failures and consecutiveFailures in the reducer
  //       to survive reload
  const [saveBatches, setSaveBatches] = useState<SaveReportOptions[]>([]);
  // Index of the next/current batch to save
  const [saveBatchIndex, setBatchIndex] = useState(0);
  const [failures, setFailures] = useState<SaveReportFailure[]>([]);
  const failuresForBatch = useCallback(
    (batchIndex: number) => failures.filter((f) => f.saveBatchIndex === batchIndex),
    [failures]
  );
  const currentBatchFailures = useMemo(
    () => failuresForBatch(saveBatchIndex).length,
    [failuresForBatch, saveBatchIndex]
  );
  // Count of failures in a row (with no successes in between)
  const [consecutiveFailures, setConsecutiveFailures] = useState(0);

  // Compute save batches and detect when save is complete
  useEffect(() => {
    // Already saving some batches, wait for them to finish
    if (saveBatchIndex !== saveBatches.length) return;

    // Either compute first batches of saves, or check if state has updated with more to save
    // after the initial set of save batches are done
    const batches = buildBatchedSaveReportOptions(
      savedValues,
      serverSavedValues,
      reportData,
      context.triggerLocation,
      // save is only called from one place, so background save is enough to locate this
      `BACKGROUND SAVE queued by: component: ${context.component}; call_site: ${context.callSite}; triggered_by: ${context.triggeredBy}`
    );

    if (batches.length) {
      // There is more to save, so queue that up
      setSaveBatches(batches);
      setBatchIndex(0);
    } else {
      // There is nothing left to save, so we're done. Dispatch a finished action.
      dispatch({ type: 'COMPLETED_BACKGROUND_SAVE', userReportId });

      // Note: if there is a queued snapshot with different savedValues,
      //       this useEffect will run again when the savedValues updates.
      //       So batch index and batches are left as-is so the above batch
      //       build and check will repeat.
    }
  }, [saveBatches, saveBatchIndex, savedValues, serverSavedValues, reportData, dispatch, userReportId, context]);

  useEffect(() => {
    if (loading) {
      return;
    }

    // either ready for next save or waiting for batches
    if (!called) {
      const nextBatch = saveBatches[saveBatchIndex];
      if (!nextBatch) {
        // either batches not computed (not ready to save anything)
        // or all batches saved (nothing left to save)
        return;
      }

      // Current batch may have failed a save, check if we are at any retry/failure limit
      const totalFailures = failures.length;
      const batchRetryLimited = currentBatchFailures > MAX_BACKGROUND_SAVE_BATCH_RETRIES;
      const totalFailuresLimited = totalFailures > MAX_BACKGROUND_SAVE_TOTAL_FAILURES;
      const consecutiveFailuresLimited = consecutiveFailures > MAX_CONSECUTIVE_FAILURES;

      if (batchRetryLimited) {
        // Give up on this batch and move to the next one
        setBatchIndex((i) => i + 1);
        console.error(
          `🏋️ SaveReportHandler useEffect for ${userReportId} batch ${saveBatchIndex} hit retry limit (${currentBatchFailures} failures)`
        );
      }
      if (totalFailuresLimited) {
        console.error(
          `🏋️ SaveReportHandler useEffect for ${userReportId} batch ${saveBatchIndex} hit failure limit (${totalFailures} failures, ${currentBatchFailures} in this batch)`
        );
      }
      if (consecutiveFailuresLimited) {
        console.error(
          `🏋️ SaveReportHandler useEffect for ${userReportId} batch ${saveBatchIndex} hit consecutive failure limit (${consecutiveFailures} failures in a row, ${currentBatchFailures} in this batch)`
        );
      }

      if (totalFailuresLimited || consecutiveFailuresLimited) {
        // terminate the while save, connection is probably down or something
        dispatch({ type: 'FAILED_BACKGROUND_SAVE', userReportId });
      }

      const exceededErrorLimit = batchRetryLimited || totalFailuresLimited || consecutiveFailuresLimited;
      if (!exceededErrorLimit) saveReport(nextBatch);
      return;
    }

    const { success, errorMessage } = data?.saveReport ?? {};
    if (error || errorMessage?.length || !success) {
      // Record the failure, for debugging and counting retries
      setFailures((f) => [...f, { saveBatchIndex, error, errorMessage, success }]);
      setConsecutiveFailures((n) => n + 1);
      // Keep same batchIndex, so the same batch will be retried (or skipped if it has hit max retries).
    } else {
      // Increment the batch number so it will save the next batch (or check for completion if they're all done)
      setBatchIndex((i) => i + 1);
      // Any success interrupts the consecutive failure streak
      setConsecutiveFailures(0);
    }

    reset();

    // excluding saveReport and dispatch from dependency array as they are not relevant for re-running
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    // save mutation state
    called,
    loading,
    data,
    error,

    // failures
    failures.length,
    consecutiveFailures,
    currentBatchFailures,

    // batch/batches
    saveBatches,
    saveBatchIndex,

    // not expected to change
    userReportId,
    reset,
  ]);

  const updatesCount = useMemo(
    // only used for debug UI, so avoid computing otherwise
    () => (debug ? computeReportDataItemsUpdates(savedValues, serverSavedValues)[1] : 0),
    [debug, savedValues, serverSavedValues]
  );

  // Only render the UI for debugging
  if (!debug) return null;

  return (
    <div>
      <h4>
        {userReportId} has {updatesCount} unsaved items
      </h4>
      <div>
        Failed {failures.length} times (max allowed: {MAX_BACKGROUND_SAVE_TOTAL_FAILURES})
      </div>
      <div>
        Saving batch {saveBatchIndex} of {saveBatches.length} (attempt {1 + failuresForBatch(saveBatchIndex).length} of{' '}
        {1 + MAX_BACKGROUND_SAVE_BATCH_RETRIES})
      </div>
      <div>
        {saveBatches.map((batch, i) => {
          const itemCount = batch.variables.reportDataObject.reportDataItems?.length;
          const status = batchStatus(i, saveBatchIndex);
          const failCount = failuresForBatch(i).length;
          return (
            <div key={i}>
              Batch {i} ({itemCount} items), {status} (Failed {failCount} times).
            </div>
          );
        })}
      </div>
    </div>
  );
};

function batchStatus(batchIndex: number, currentBatchIndex: number) {
  if (batchIndex === currentBatchIndex) return 'next/saving';
  return batchIndex < currentBatchIndex ? 'done' : 'waiting';
}
