import React from 'react';
import { Card, Heading, Image, Text } from '@aws-amplify/ui-react';
import './InstructionCard.scss';

interface Props {
  title: string;
  text: string[];
  imgPath: string;
  altText: string;
}

export const InstructionCard: React.FC<Props> = ({ text, title, imgPath, altText }) => (
  <Card testId="instruction-card" className="instruction-card">
    <Image objectFit="inital" src={imgPath} alt={altText} testId="instruction-img" />
    <div className="instruction-content">
      <Heading testId="instruction-title" className="instruction-heading" level={2}>
        {title}
      </Heading>
      <span className="instruction-text" data-testid="instruction-text">
        {text.map((line, i) => (
          <Text key={i}>{line}</Text>
        ))}
      </span>
    </div>
  </Card>
);
