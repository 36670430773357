import { wrap } from 'comlink';
import { saveAs } from 'file-saver';
import { PdfWorker } from 'webworkers/PdfWorker';
import { ClearReportStateData } from 'apollo/states/utils/ClearReportStateData';
import JSZip from 'jszip';
import { ChosenReport } from 'models';
import dayjs from 'dayjs';
import reportPdfData, { ReportPDFInputData } from './getPdfData';
import { DAYNUM_SHORT_MONTHNAME_YEAR } from './dayjsFormats';

const handleGeneratePdf = async (submitted: boolean, data?: ReportPDFInputData) => {
  const reportPDFData = reportPdfData(data);

  const worker = new Worker('/bundle.min.js');
  const isDashboard = data !== undefined;
  const pdfWorker = wrap<PdfWorker>(worker);

  let timeout;
  const timeoutPromise = new Promise((_, reject) => {
    timeout = setTimeout(() => {
      reject(new Error('PDF Generation Failed'));
    }, 30000); // 30 seconds timeout
  });

  try {
    const pdfBlobPromise = pdfWorker.generateSinglePDF(submitted, reportPDFData);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const pdfBlob = await Promise.race([pdfBlobPromise, timeoutPromise]);
    clearTimeout(timeout);
    saveAs((await pdfBlobPromise).pdfBlob, (await pdfBlobPromise).fileName);
  } catch (error) {
    throw new Error('PDF Generation Failed');
  } finally {
    worker.terminate();
  }

  // TODO: when ReportSummary is updated not to set report-related reactive vars for PDF, remove this
  if (submitted && isDashboard) ClearReportStateData();
};

// FIXME: figure out if I have to pass in submitted to this per report or not
// FIXME: rename to be clear that it generates it *and starts the download*
export const handleGeneratePdfZip = async (data: ReportPDFInputData[]) => {
  // make a worker
  const worker = new Worker('/bundle.min.js');
  const pdfWorker = wrap<PdfWorker>(worker);

  try {
    const pdfBlobPromises = data.map(reportPdfData).map((pdfData) => {
      // FIXME: probably need to check this is logically sound. Use-case is reports on dashboard
      //        so the submitted status in the report metadata should be accurate, but make sure.
      const submitted = pdfData.businessInformation?.submitted ?? true;
      return pdfWorker.generateSinglePDF(submitted, pdfData);
    });
    const pdfBlobs = await Promise.all(pdfBlobPromises);

    // FIXME move to worker?
    const zip = new JSZip();

    // add all blobs to zip as filename
    pdfBlobs.forEach(({ fileName, pdfBlob }) => {
      // Note: same filename will overwrite
      // Note: can accept promise of a blob
      //       (just can't do that right now with the filenames being in the same promise as the blob)
      // Note: extension doesn't get auto-added like it would with directly saving
      zip.file(`${fileName}.pdf`, pdfBlob);
    });

    // Note: zip.folder(...) creates a nested folder, add files to its return value.

    // FIXME: filename with business and date
    const zipFileName = generateZipName(data[0].localChosenReport) ?? 'All_Reports.zip';

    const zipBlob = await zip.generateAsync({ type: 'blob' });

    // Note: if this is unreliable, look into StreamSaver library instead of FileSaver
    saveAs(zipBlob, zipFileName);
  } catch (error) {
    console.error('Error generating zip of PDFs', error);
    throw error;
  } finally {
    worker.terminate();
  }

  // TODO: see if I can avoid the repeated calls to the worker, and instead put most of this logic in there
  // TODO: see if I can synchronously return the object with { fileName, Promise<Blob> } to avoid having to
  //       make everything before starting the zip.
  // TODO: check whether saveAs can live in a webworker... seems like no (FileSaver.js library), but that's what StreamSaver.js is for
};

// FIXME: partially duplicates generateFileName in PDF worker, unify those guys
function generateZipName(report: ChosenReport) {
  const business = report.organisationName ?? 'Business';
  const downloadDate = dayjs().format(DAYNUM_SHORT_MONTHNAME_YEAR);
  return `${business}_Reports_${downloadDate}.zip`.replace(/ /g, '_');
}

export default handleGeneratePdf;
