import React from 'react';
import { Button } from '@aws-amplify/ui-react';

import './SkipLink.scss';
import { setFocus } from 'utils/setFocus';

const SkipLink = () => {
  const handleOnClick = (event: React.SyntheticEvent) => {
    event.preventDefault();

    setFocus('skipTarget');
  };

  return (
    <Button onClick={handleOnClick} className="skip-link" variation="link">
      Skip to main content
    </Button>
  );
};

export default SkipLink;
