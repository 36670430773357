/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { Button, Flex, Heading, Text, View } from '@aws-amplify/ui-react';
import { DataItem, DspDataItemObject, TopicFullDetail } from 'models';
import React, { useMemo, useState } from 'react';

import { ReportQuestion } from 'components/ReportComponents/ReportQuestion/ReportQuestion';
import { findSortDisplays } from 'components/ReportComponents/TopRemaining/TopRemaining';
import ReportList from 'components/ReportComponents/ReportList/ReportList';
import ReportNumber from 'components/ReportComponents/ReportNumber/ReportNumber';
import ReportListNoDsp from 'components/ReportComponents/ReportListNoDsp/ReportListNoDsp';
import TopRemainingNoDsp from 'components/ReportComponents/TopRemaining/TopRemainingNoDsp';
import { useTotalByTopic } from 'apollo/states/utils/GetTotalByTopic';
import { currentReportData } from 'apollo/states/CurrentReportData';
import { currencyFormat } from 'utils/CurrencyFormat';
import { compareTopicStrings } from 'utils/CompareTopicStrings';
import { chosenReport } from 'apollo/states/ChosenReport';

import useCurrentReportTopics from 'apollo/states/utils/useCurrentReportTopics';
import { TopicName, getTopicByName } from 'models/LocalState/TopicFullDetail';
import { ReactComponent as TooltipDefault } from 'assets/tooltip/tooltip-default.svg';
import { ReactComponent as TooltipHover } from 'assets/tooltip/tooltip-hover.svg';
import { ReactComponent as TooltipSelected } from 'assets/tooltip/tooltip-select.svg';

import './TopicQuestions.scss';
import {
  MinifiedRuleTopicSummaries,
  RuleTopicSummaries,
} from 'components/ReportComponents/RuleTopicSummaries/RuleTopicSummaries';
import { generateRuleExplanation } from 'utils/generateRuleExplanation';
import { useReactiveVar } from '@apollo/client';
import { getDataItemTexts } from 'utils/dataItemText';
import { buildSubstitutePlaceholders } from 'utils/substitutePlaceholders';

interface FullTopicQuestionsProps {
  topicType: TopicName;
  topicFullDetail?: TopicFullDetail;
  topics: TopicFullDetail[];
  minified?: boolean;
}

type BaseTopicQuestionsProps = Partial<FullTopicQuestionsProps>;

interface TopicQuestionsProps1 extends BaseTopicQuestionsProps {
  topicType: TopicName;
  topicFullDetail?: undefined;
}

interface TopicQuestionsProps2 extends BaseTopicQuestionsProps {
  topicType?: undefined;
  topicFullDetail: TopicFullDetail;
}

// either the full detail or the topic name for lookup is required
type TopicQuestionsProps = TopicQuestionsProps1 | TopicQuestionsProps2;

// Regex adds commas every 3 characters given there is more than 3 digits in the string
export const formatValueString = (value: string): string => value.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

export const stripCommas = (value: string): string => value.replaceAll(',', '');

/**
 * Wrapper component to look up topic detail and delegates to the correct component.
 *
 * Accepts either topicFullDetail (preferred), or topicType to look up detail from
 */
const TopicQuestions = ({ topicType, topicFullDetail, minified }: TopicQuestionsProps) => {
  const actualTopicType = topicFullDetail ? topicFullDetail.topic : topicType;
  const topics = useCurrentReportTopics();
  const topic = topicFullDetail ?? getTopicByName(topics, topicType);

  // Render special component for topics that have rules
  const includeRuleSection = topic?.compiledRule && minified;

  if (minified) {
    return (
      <>
        {includeRuleSection && <MinifiedRuleTopicSummaries topicFullDetail={topic} />}
        <TopicQuestionsInner topicType={actualTopicType} topicFullDetail={topic} topics={topics} minified={minified} />
      </>
    );
  }

  return (
    <TopicQuestionsInner topicType={actualTopicType} topicFullDetail={topic} topics={topics} minified={minified} />
  );
};

const regexLineBreak = /\r\n|\n/;
const splitHelpTexts = (text: string | undefined) => (!text ? [] : text.split(regexLineBreak));

const TopicQuestionsInner = ({ topicType, topicFullDetail, topics, minified }: FullTopicQuestionsProps) => {
  const [showtopicTotalInfo, setShowtopicTotalInfo] = useState<boolean>(false);
  const [tooltipIconHover, setTooltipIconHover] = useState<boolean>(false);

  const totalNumber = useTotalByTopic(topicType);
  const total = currencyFormat.format(totalNumber);

  const topicTotalInfo = topicFullDetail?.topicTotalInfo;
  const topicTotalInfoPresent = !!topicTotalInfo?.length;

  const topicQuestionsList = <TopicQuestionsList topicType={topicType} minified={minified} />;

  const ruleExplanation = useMemo(
    () => generateRuleExplanation(topicFullDetail?.compiledRule, topics),
    [topicFullDetail?.compiledRule, topics]
  );

  let topicDescription = topicFullDetail?.ruleDescription || [];
  // backend can incorrectly send a single string instead of array of string, detect and recover
  if (typeof topicDescription === 'string') {
    topicDescription = [topicDescription];
  }

  if (minified) {
    return (
      <Flex direction="column" gap="2px">
        {topicQuestionsList}
      </Flex>
    );
  }

  const totalLabel = topicFullDetail?.topicTotalText?.trim() || 'Total';

  // Only show total section for topics with an action for rollup, that are configured to display
  const shouldShowTotalSection = !!topicFullDetail?.action && topicFullDetail?.totalDisplay === 'SHOW';

  // Only show the rule summary section for topics with a rule
  // (only "Profit or Loss" in current implementation).
  const shouldShowRuleSection = !!topicFullDetail?.rule;

  return (
    <>
      {/* Content moved from ProfitOrLoss.tsx to this matching position
          may be able to combine within div if layout is not affected */}
      {shouldShowRuleSection && (
        <>
          <View className="report-paragraph">
            {topicDescription.map((copy, index, allCopy) => (
              <Text key={index} marginBottom={allCopy.length - index > 1 ? '4px' : undefined} color="#4B4F54">
                {copy}
              </Text>
            ))}
          </View>
          <Heading
            level={3}
            className="report-header margin-bottom-40"
            testId="profitorloss-sub-header"
            style={{ maxWidth: '75%' }}
          >
            Review {topicFullDetail.ruleText?.toLowerCase()}
          </Heading>
        </>
      )}
      <div>
        {shouldShowRuleSection && (
          <>
            <RuleTopicSummaries ruleTopics={topicFullDetail.compiledRule?.topicNames ?? []} topics={topics} />
            <Flex justifyContent="space-between" className="width-640">
              <Text className="bold-total" testId="income-expenses-total">
                {topicFullDetail.ruleText}
              </Text>
              <Text className="bold-total">{total}</Text>
            </Flex>
            <Text className="sub-desc margin-bottom-40">{ruleExplanation}</Text>
          </>
        )}
        {shouldShowRuleSection || shouldShowTotalSection ? (
          topicQuestionsList
        ) : (
          <div className="total-amount">{topicQuestionsList}</div>
        )}
        {shouldShowTotalSection && (
          <Flex direction="column" gap="0" alignItems="flex-end" marginBottom="48px">
            <Text className="income-expenses-total income-expenses-total-amount" testId="income-expenses-total">
              {totalLabel}: {total}
              {topicTotalInfoPresent && (
                <Button
                  aria-label={`information about ${totalLabel}`}
                  aria-expanded={showtopicTotalInfo}
                  testId="tooltip-icon"
                  className={`${showtopicTotalInfo && 'selected'} tooltip-icon`}
                  onClick={() => {
                    setShowtopicTotalInfo((show) => !show);
                  }}
                  onMouseEnter={() => {
                    setTooltipIconHover(true);
                  }}
                  onMouseLeave={() => {
                    setTooltipIconHover(false);
                  }}
                >
                  <TooltipIcon displayHelpText={showtopicTotalInfo} tooltipIconHover={tooltipIconHover} />
                </Button>
              )}
            </Text>
            {/* wrapped in aria-live region so text is announced when revealed */}
            {topicTotalInfoPresent && (
              <div aria-live="polite" style={{ maxWidth: '659px' }}>
                {showtopicTotalInfo && (
                  <Text textAlign="right" color="#71767D" fontSize="16px" lineHeight="24px" marginTop="24px">
                    {topicTotalInfo}
                  </Text>
                )}
              </div>
            )}
          </Flex>
        )}
      </div>
    </>
  );
};

const TooltipIcon = ({
  displayHelpText,
  tooltipIconHover,
}: {
  displayHelpText: boolean;
  tooltipIconHover: boolean;
}) => {
  if (displayHelpText) {
    return <TooltipSelected />;
  }
  return tooltipIconHover ? <TooltipHover /> : <TooltipDefault />;
};

const isSortDisplay = (dataItem: string, dataItemsList: DataItem[]) =>
  findSortDisplays(dataItemsList).find((item) => item.dataItemId === dataItem);

function TopicQuestionsList({ topicType, minified }: { topicType: TopicName; minified: boolean | undefined }) {
  const { dataItems: dataItemsList, dspDataItems: dspItemsList } = useReactiveVar(currentReportData);
  const { isUsingDsp } = useReactiveVar(chosenReport);
  const isNoDsp = isUsingDsp === false;
  const reportData = chosenReport();

  const substitutePlaceholders = buildSubstitutePlaceholders(reportData);

  return (
    <>
      {dataItemsList
        .filter((i: DataItem) => compareTopicStrings(i.topic, topicType))
        .sort((a, b) => a.position - b.position)
        .map((actionItem: DataItem, i: any) => {
          const { helpText, itemText: dataItemText } = getDataItemTexts(actionItem, isNoDsp);
          const itemText = substitutePlaceholders(dataItemText ?? '');
          if (actionItem.action.match(/LIST/)) {
            return (
              <React.Fragment key={i}>
                {isNoDsp ? (
                  <ReportListNoDsp dataItem={actionItem} helpTexts={splitHelpTexts(helpText)} minified={minified} />
                ) : (
                  <ReportList
                    dspItems={dspItemsList.filter((x: DspDataItemObject) => x.class === actionItem.accountClass)}
                    helpTexts={splitHelpTexts(helpText)}
                    accountTypes={topicType}
                    dspId={chosenReport().dspProvider || ''}
                    minified={minified}
                    dataItem={actionItem}
                    sortDisplay={!!isSortDisplay(actionItem.dataItemId, dataItemsList)}
                  />
                )}
              </React.Fragment>
            );
          }
          if (actionItem.action.match(/SORTDISPLAY/) && isNoDsp) {
            return (
              <TopRemainingNoDsp
                dataItem={actionItem}
                helpTexts={splitHelpTexts(helpText)}
                minified={minified}
                key={i}
              />
            );
          }
          if (actionItem.action.match(/TEXT/)) {
            const fieldLength = Number(actionItem.action.replace(/[^0-9]/g, ''));
            return (
              <ReportQuestion
                key={i}
                text={itemText}
                helpTexts={splitHelpTexts(helpText)}
                length={fieldLength || undefined}
                dataItem={actionItem}
                minified={minified}
              />
            );
          }
          if (actionItem.action.match(/NUMBER/)) {
            return (
              <ReportNumber
                dataItem={actionItem}
                helpTexts={splitHelpTexts(helpText)}
                itemText={itemText}
                key={actionItem.dataItemId}
                minified={minified}
                label={
                  (isNoDsp ? actionItem.dataItemLabelNoDsp ?? actionItem.dataItemLabel : actionItem.dataItemLabel) ?? ''
                }
              />
            );
          }
          return null;
        })}
    </>
  );
}

export default TopicQuestions;
