/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { Button, Flex, Heading, SwitchField, Text, View, Link } from '@aws-amplify/ui-react';
import ContactDetails from 'components/ContactDetails/ContactDetails';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useReactiveVar } from '@apollo/client';

import { chosenReport } from 'apollo/states/ChosenReport';
import { GetTotalByTopic } from 'apollo/states/utils/GetTotalByTopic';
import useCurrentReportTopics from 'apollo/states/utils/useCurrentReportTopics';
import { ExpandedReview, ExpandedReviewClicked, ExpandersArray } from 'apollo/states/ExpandedReview';
import { AlertComponent } from 'components/AlertComponent/AlertComponent';
import DetailedTopicComponent from 'components/DetailedTopic/DetailedTopicComponent';
import TopicQuestions from 'components/ReportComponents/TopicQuestions/TopicQuestions';
import SubmitButtonWithModal from 'components/SubmitButtonWithModal/SubmitButtonWithModal';
import { getSavedAnswerCount } from 'utils/getSavedAnswerCount';
import { PDFButton } from 'utils/PDFButton';
import { externalLinks } from 'lib/externalLinks';

import { ReactComponent as IconInfoWarnSvg } from 'assets/icon-info-warn.svg';
import { recordRumCustomEvent, recordRumCustomEventWithPageId } from 'services/awsRum';
import { getRumAttributes } from 'utils/getRumAttributes';
import { RumCustomEvent } from 'enums/RumCustomEvent';

import 'pages/Report/ReportPage.scss';
import './Review.scss';
import { savedReportDataItems } from 'apollo/states/SavedReportDataItem';
import { ReactComponent as EditIconSvg } from 'assets/icon-edit-borderless.svg';

const returningUserBanner = {
  title: 'Welcome back',
  variation: 'incompleted',
  textHeader:
    'We have remembered your account selections from your last survey. Please ensure these are still correct and:',
  listItem: [
    'Update the number of people working for this business',
    'Allocate any new accounts you have created since last time',
    'Leave comments against survey items where appropriate',
    "Add manual entries, as these aren't saved between quarters",
  ],
};

export const incompleteReportBanner = {
  title: 'Incomplete survey',
  textHeader: 'This survey has no responses',
  listItem: [
    'If this is not correct, please return to the survey and respond to all relevant items',
    "If this is correct, please review and click 'Submit survey' to proceed",
  ],
};

const ReportReview = () => {
  const [editingDetails, setEditingDetails] = useState(false);
  const currentReportTopics = useCurrentReportTopics();
  const savedReportDataItemsListener = useReactiveVar(savedReportDataItems);

  const chosenReportDetails = chosenReport();
  const { returningUser } = chosenReportDetails;
  const isNoDsp = chosenReportDetails.isUsingDsp === false;

  const focusRef = useRef<HTMLHeadingElement>(null);

  const expanded = useReactiveVar(ExpandedReview);
  const expandedClicked = useReactiveVar(ExpandedReviewClicked);
  const expandersArray = useReactiveVar(ExpandersArray);

  /**
   * When reportIsEmpty, we need to let the user know. To do this, we listen for the latest changes to the underlying reactive form data variable
   */
  const reportIsEmpty = useMemo(
    () => getSavedAnswerCount(savedReportDataItemsListener.savedAnswers) === 0,
    [savedReportDataItemsListener.savedAnswers]
  );

  const [allWarningsViewed, setAllWarningsViewed] = useState(false);

  const checkForWarnings = () => {
    if (document.querySelectorAll('.review-warning-icon').length === 0) setAllWarningsViewed(true);
  };

  /* istanbul ignore next */
  useEffect(() => {
    if (focusRef.current != null) {
      focusRef.current.focus();
    }
    ExpandedReview(false);
    if (returningUser) document.querySelector('#review-report-wrapper')?.addEventListener('click', checkForWarnings);
    return () => {
      chosenReport({ ...chosenReport(), returningUser: false });
      document.querySelector('#review-report-wrapper')?.removeEventListener('click', checkForWarnings);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!!expandersArray.length && expandersArray.every((item) => item.expanded === true)) {
      ExpandedReview(true);
    }
  }, [expandersArray]);

  const handleExpandAllSwitch = () => {
    ExpandedReviewClicked(!expandedClicked);

    // Expand and collapse should be logged.
    recordRumCustomEvent(
      expanded ? RumCustomEvent.collapseAllSections : RumCustomEvent.expandAllSections,
      getRumAttributes()
    );
  };

  return (
    <div className="review-report" id="review-report-wrapper" data-testid="review-report">
      {returningUser && (
        <AlertComponent
          templateColumns="1"
          className="review-report-alert"
          title={returningUserBanner.title}
          variation="incompleted"
          textHeader={returningUserBanner.textHeader}
          listItem={returningUserBanner.listItem}
          alertProps={{ isDismissible: true }}
        />
      )}
      {reportIsEmpty && (
        <AlertComponent
          templateColumns="1"
          className="review-report-alert"
          title={incompleteReportBanner.title}
          variation="incompleted"
          textHeader={incompleteReportBanner.textHeader}
          listItem={incompleteReportBanner.listItem}
        />
      )}
      <Flex direction="row" justifyContent="space-between" marginTop={returningUser ? '30px' : ''}>
        <Heading
          level={1}
          ref={focusRef}
          tabIndex={-1}
          className="report-step-header report-step-header-buffer"
          testId="review-header"
          marginTop={reportIsEmpty ? '30px' : ''}
        >
          Review survey
        </Heading>
      </Flex>
      <View className="report-paragraph-wrapper">
        {isNoDsp ? (
          <Text>This screen shows a summary of the values you have entered in each section of the survey.</Text>
        ) : (
          <>
            <Text>
              This screen shows a summary of the accounts you have allocated in each section for you to review.{' '}
            </Text>
            <br />
            <Text>
              The ABS will only use the totals for each survey item to produce statistics and not the individual account
              values you have allocated.
            </Text>
          </>
        )}
      </View>
      <Flex
        direction="column"
        justifyContent="flex-start"
        alignItems="flex-start"
        alignContent="flex-start"
        wrap="nowrap"
        className="pdf-button"
      >
        <PDFButton submitted={false} />
      </Flex>

      <h2 hidden aria-hidden={false}>
        Report Information
      </h2>
      <Flex direction="column">
        <Flex className="contact-details-header">
          <Heading level={2} className="contact-details-heading" testId="contact-details-heading">
            Verify your contact details
          </Heading>
        </Flex>
        <p className="contact-details-text" data-testid="contact-details-text">
          We may contact you if we have any questions about your survey
        </p>
        <ContactDetails editing={editingDetails} lockEditing={() => setEditingDetails(false)} />
        <Button
          className="contact-details-edit-btn"
          testId="contact-details-edit-btn"
          onClick={() => setEditingDetails(true)}
          title="Edit contact details"
          variation="link"
        >
          <EditIconSvg /> Edit details
        </Button>
      </Flex>
      <Flex direction="column">
        {!isNoDsp && (
          <Flex justifyContent="start">
            <SwitchField
              testId="review-expand-toggle-switch"
              isChecked={expanded}
              onChange={(e) => {
                ExpandedReview(e.target.checked);
                handleExpandAllSwitch();
              }}
              label="Expand all sections"
              padding="48px 0 20px 0"
              width="100%"
              style={{ borderTop: '1px solid #EFF0F0' }}
            />
          </Flex>
        )}
        {returningUser && !allWarningsViewed && (
          <Flex gap={12} alignItems="center" style={{ backgroundColor: '#FAFAFA', padding: '16px' }}>
            <IconInfoWarnSvg style={{ flex: 'none' }} />
            <Text>
              These survey items do not carry over between quarters. Please review and complete where necessary.
            </Text>
          </Flex>
        )}
      </Flex>
      <Flex direction="column" gap={10} style={{ borderTop: '1px solid #EFF0F0' }}>
        {currentReportTopics
          .filter((topic) => !topic.isFeedback)
          .map((topicDetail) => (
            <DetailedTopicComponent
              key={topicDetail.key}
              title={topicDetail.topicTitle}
              total={
                topicDetail.totalDisplay === 'SHOW' && (topicDetail.action || topicDetail.rule)
                  ? GetTotalByTopic(topicDetail.topic)
                  : undefined
              }
              link={topicDetail.path}
            >
              <TopicQuestions topicFullDetail={topicDetail} minified />
            </DetailedTopicComponent>
          ))}
      </Flex>
      <Flex alignItems="baseline" direction="row" justifyContent="start">
        <SubmitButtonWithModal />
      </Flex>
      <Text marginTop="72px" maxWidth="unset">
        If you want to know more about how we use your data visit our{' '}
        <Link
          href={externalLinks.absDataPrivacySecurity}
          isExternal
          onClick={() => recordRumCustomEventWithPageId(RumCustomEvent.privacyAtAbsClicked, getRumAttributes())}
        >
          Privacy at the ABS
        </Link>{' '}
        and{' '}
        <Link
          href={externalLinks.absBusinessReportingDataUsage}
          isExternal
          onClick={() => recordRumCustomEventWithPageId(RumCustomEvent.dataReportingUsageClicked, getRumAttributes())}
        >
          ABS Business Reporting Data Usage
        </Link>{' '}
        pages
      </Text>
    </div>
  );
};

export default ReportReview;
