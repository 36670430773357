/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { useEffect, useState, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Flex, VisuallyHidden } from '@aws-amplify/ui-react';
import { chosenReport } from 'apollo/states/ChosenReport';
import { resetLastSavedTimer } from 'apollo/states/SaveTimer';
import { savingLocalAnswersStatus, isSubmittingReport } from 'apollo/states/operationsInProgress';
import { isCurrentReportSaveable } from 'services/SaveReportManager/currentReportStatus';
import { InlineError } from 'components/InlineError/InlineError';
import { getSaveReportOptions } from 'utils/getSaveReportOptions';
import useSyncDspItems from 'hooks/useSyncDspItems';
import useDspApiErrorToast from 'hooks/useDspApiErrorToast';
import { isMac } from 'utils/detectOS';
import { useSaveReport } from 'utils/useSaveReport';
import { ReactComponent as SyncIconSvg } from 'assets/icon-sync.svg';
import { ReactComponent as SavingIconSvg } from 'assets/icon-loading-save.svg';
import { ReactComponent as ContinueIconSvg } from 'assets/icon-continue.svg';
import { ReactComponent as SaveAndExitIconSvg } from 'assets/icon-save-and-exit.svg';
import ToastNotification from 'components/ToastNotification/ToastNotification';

import './ReportButtonGroup.css';

interface ButtonProps {
  link: string;
  text: string;
  useSave?: boolean;
  Callback?: () => void;
  isLoading?: boolean;
  loadingText?: string;
  showIcon?: boolean;
}

interface ReportButtonGroupProps {
  nextButton: ButtonProps;
  backButton?: ButtonProps;
  exitButton?: ButtonProps;
  showSyncButton?: boolean;
}

const ReportButtonGroup = ({ nextButton, backButton, exitButton, showSyncButton = false }: ReportButtonGroupProps) => {
  const navigate = useNavigate();
  const chosenReportDetails = chosenReport();
  const [isSyncing, startSync] = useSyncDspItems();
  const [inlineError, setInlineError] = useState(false);
  const [buttonLoader, setButtonLoader] = useState('');
  const [navigateLink, setNavigateLink] = useState('');
  // For screen reader loading announcement, until button component handles it properly
  const [announceLoading, setAnnounceLoading] = useState(false);
  const [saveLockedFailureNotificationOpen, setSaveLockedFailureNotificationOpen] = useState(false);
  const [syncSuccessNotificationOpen, setSyncSuccessNotificationOpen] = useState(false);
  const [syncFailureNotificationOpen, setSyncFailureNotificationOpen] = useState(false);

  const [toastToShow, showToastOpenIfDSPError, clearDspApiToast] = useDspApiErrorToast();

  // FIXME: clicking back then continue rapidly can cause being stuck forever?
  const [callSaveReport, saveReportResponse] = useSaveReport('ReportButtonGroup');

  const isLocked = useMemo(() => {
    if (chosenReportDetails.singleUserAccess?.lock) {
      setSaveLockedFailureNotificationOpen(true);
      return true;
    }
    return false;
  }, [chosenReportDetails?.singleUserAccess?.lock]);

  const handleOnClick = (clickedButton: string, link: string, useSave?: boolean) => {
    if (useSave) {
      let uiContext = 'unknown button';
      if (clickedButton === 'back-button') {
        setButtonLoader('back');
        uiContext = backButton?.text ?? 'Back';
      } else if (clickedButton === 'next-button') {
        setButtonLoader('next');
        uiContext = nextButton?.text ?? 'Save and continue';
      } else if (clickedButton === 'exit-button') {
        setButtonLoader('exit');
        uiContext = exitButton?.text ?? 'Save and exit';
      }
      setInlineError(false);
      setSyncFailureNotificationOpen(false);
      setNavigateLink(link);

      // IF save and exit but browser is offline show inline error.
      // This will need to be addressed in ASP-5793. The original bug was raised because of a change in
      // behaviour in save. If there are no changes, save is not called so no failed save inline is raised
      // Instead the app routes to /dasboard and is immediately sent to the /error 500 page.
      // as does clicking the logo in the header. This is the behaviour that will likly need to be changed.
      if (clickedButton === 'exit-button' && typeof window !== 'undefined' && !window.navigator.onLine) {
        setInlineError(true);
        savingLocalAnswersStatus('error');
        return;
      }

      // Short-cut the save if there is nothing to save. useSaveReport does this too, but this is a quicker refactor.
      const saveReportOptions = getSaveReportOptions(uiContext, true);
      if (
        saveReportOptions.variables.reportDataObject.reportDataItems?.length &&
        !isLocked &&
        isCurrentReportSaveable()
      ) {
        setSaveLockedFailureNotificationOpen(false);
        savingLocalAnswersStatus('saving');
        // we want to lock the report as we are making changes.
        callSaveReport('handleOnClick', uiContext, true);
      } else {
        if (clickedButton === 'next-button') resetLastSavedTimer();
        navigate(link);
      }
    } else {
      navigate(link);
    }
  };

  const handleSyncClick = () => {
    if (isSyncing) return;
    if (isLocked) return;
    setInlineError(false);
    setSyncFailureNotificationOpen(false);
    // clear existing notifications to avoid confusion
    setSyncSuccessNotificationOpen(false);
    clearDspApiToast();

    startSync()
      .then(() => {
        setSyncSuccessNotificationOpen(true);
        // ensure error is not still shown if it was still displaying
        setSyncFailureNotificationOpen(false);
      })
      .catch((errorMessages) => {
        if (!showToastOpenIfDSPError(errorMessages)) {
          setSyncFailureNotificationOpen(true);
        }
        // ensure success is not still shown if it was still displaying
        setSyncSuccessNotificationOpen(false);
      });
  };

  /* istanbul ignore next */
  useEffect(() => {
    if (saveReportResponse.error) {
      setInlineError(true);
      savingLocalAnswersStatus('error');
    }
  }, [saveReportResponse.error]);

  useEffect(() => {
    if (saveReportResponse.success) navigate(navigateLink);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saveReportResponse.success]);

  const isBackLoading = backButton
    ? backButton.isLoading || (saveReportResponse.loading && buttonLoader === 'back')
    : false;
  const isNextLoading = nextButton.isLoading || (saveReportResponse.loading && buttonLoader === 'next');
  const isExitLoading = exitButton?.isLoading || (saveReportResponse.loading && buttonLoader === 'exit');
  const isLoading = isBackLoading || isNextLoading || isExitLoading;

  useEffect(() => {
    // Add add small delay to announcing loading state, as recommended in accessibility report
    const timeoutHandle = setTimeout(() => setAnnounceLoading(isLoading), 200);
    return () => clearTimeout(timeoutHandle);
  }, [isLoading]);

  const loadingText =
    (isNextLoading && (nextButton.loadingText || 'Loading...')) ||
    (isBackLoading && (backButton?.loadingText || 'Loading...'));

  // Callbacks are un-bound while loading since only aria-disabled is used (for accessibility) and not disabled
  // not loading.
  const onBackClick =
    !backButton || isBackLoading
      ? undefined
      : backButton.Callback ?? (() => handleOnClick('back-button', backButton.link, backButton.useSave));
  const onNextClick =
    !nextButton || isNextLoading
      ? undefined
      : nextButton.Callback ?? (() => handleOnClick('next-button', nextButton.link, nextButton.useSave));
  const onExitClick =
    !exitButton || isExitLoading
      ? undefined
      : exitButton.Callback ?? (() => handleOnClick('exit-button', exitButton.link, exitButton.useSave));
  return (
    <Flex direction="column" alignItems="flex-start" style={{ width: '100%' }}>
      <Flex className="report-button-group-wrapper">
        {backButton && (
          <Button
            className="report-button-group-button"
            testId="report-button-back"
            onClick={onBackClick}
            isLoading={isBackLoading}
            loadingText={backButton.loadingText || 'Loading...'}
            isDisabled={false}
            aria-disabled={isBackLoading}
          >
            {backButton.text}
          </Button>
        )}
        <Button
          variation="primary"
          className="report-button-group-button"
          testId="report-button-next"
          onClick={onNextClick}
          isLoading={isNextLoading}
          loadingText={nextButton.loadingText || 'Loading...'}
          isDisabled={isLoading || isSyncing}
          aria-disabled={isNextLoading}
        >
          {nextButton.text}
          {nextButton.showIcon ? <ContinueIconSvg /> : ''}
        </Button>
        {exitButton && (
          <Button
            className="report-button-group-button"
            testId="report-button-exit"
            onClick={onExitClick}
            isLoading={isExitLoading}
            loadingText={exitButton.loadingText || 'Loading...'}
            isDisabled={isLoading || isSyncing || isSubmittingReport()}
            aria-disabled={isExitLoading || isSubmittingReport()}
            style={{ color: '#0F487C' }}
          >
            {exitButton.text}
            {exitButton.showIcon ? <SaveAndExitIconSvg /> : ''}
          </Button>
        )}
        {chosenReportDetails.isUsingDsp && showSyncButton && (
          <>
            <Button
              testId="sync-btn"
              onClick={handleSyncClick}
              className="action-btn sync-btn"
              isDisabled={saveReportResponse.loading || isSyncing || isSubmittingReport()}
              style={{ border: 'none', paddingTop: '10px', paddingBottom: '10px' }}
            >
              {isSyncing ? (
                <>
                  <SavingIconSvg className="saving-rotate-icon" />
                  Syncing
                </>
              ) : (
                <>
                  <SyncIconSvg />
                  Sync accounting software
                </>
              )}
            </Button>
            {syncFailureNotificationOpen && (
              <InlineError styles="sync-inline-error" errorMessage="Sorry, that button isn't working" />
            )}
          </>
        )}
      </Flex>
      {inlineError && <InlineError />}
      {!isMac && <VisuallyHidden role="status">{announceLoading && <span>{loadingText}</span>}</VisuallyHidden>}
      <ToastNotification
        success
        title="Sync successful"
        open={syncSuccessNotificationOpen}
        onClose={() => setSyncSuccessNotificationOpen(false)}
      />
      {toastToShow}
      <ToastNotification
        success={false}
        title="Failed to save. The report is locked by another user."
        open={saveLockedFailureNotificationOpen}
        onClose={() => setSaveLockedFailureNotificationOpen(false)}
      />
    </Flex>
  );
};
export default ReportButtonGroup;
