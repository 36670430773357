import { useNavigate } from 'react-router-dom';
import { chosenReport } from 'apollo/states/ChosenReport';
import { ReportStatus } from 'enums/ReportStatus';
import { ChosenReport } from 'models';
import { userContactDetails } from 'apollo/states/UserContactDetails';
import { currentReportData } from 'apollo/states/CurrentReportData';
import { savedReportDataItems } from 'apollo/states/SavedReportDataItem';
import { latestSavedReportDataItems } from 'apollo/states/LatestSavedReportDataItems';
import { saveTimer } from 'apollo/states/SaveTimer';
import useSetContactDetails from 'utils/useSetContactDetails';
import { LocalReportData } from 'utils/mapGraphQLReportToLocal';
import { useSetReportLock } from 'utils/useSetReportLock';

/**
 * Get a callback that launches the report.
 *
 * Launching sets all the necessary reactive variables, saves contact details if needed,
 * locks the report and navigates to the appropriate report page.
 *
 * usage:
 *     const launchReport = useLaunchReport(reportData);
 *     // ... later, with fetched report
 *     const localReportData = mapGraphQLReportToLocal(...);
 *     launchReport(localReportData);
 *
 * @param reportData metadata for report to launch (callback takes the remaining data from GetReport)
 * @returns callback that takes the data for the report (in local representation) and launches the report
 */
export function useLaunchReport(reportData: ChosenReport) {
  const navigate = useNavigate();
  const [callSetContactDetail] = useSetContactDetails();
  const lockReport = useSetReportLock('lock', reportData);

  return (localReportData: LocalReportData) => {
    const { userReportId, dspProvider, organisationId } = reportData;
    const { reportStatus, returningUser, generatedContactDetails } = localReportData;

    // If contact details needed to be generated, set them on the server
    if (generatedContactDetails) {
      callSetContactDetail({
        variables: {
          saveContactDataObject: {
            userReportId,
            dspProvider,
            organisationId,
            contactDataObject: generatedContactDetails,
          },
        },
      });
    }

    setReportReactiveVariables(localReportData);
    lockReport();
    const jumpToReview = returningUser || reportStatus === ReportStatus.Edit;
    navigate(jumpToReview ? '/report/review' : '/report/instructions');
  };
}

function setReportReactiveVariables(localReportData: LocalReportData) {
  const {
    localUserContactDetails,
    localChosenReport,
    localCurrentReportData,
    localSavedReportDataItems,
    localLatestSavedReportDataItems,
  } = localReportData;
  chosenReport(localChosenReport);
  userContactDetails(localUserContactDetails);
  savedReportDataItems(localSavedReportDataItems);

  // Populate the record of the answers saved on the server
  currentReportData(localCurrentReportData);
  latestSavedReportDataItems(localLatestSavedReportDataItems);

  saveTimer({ timer: 0 });
}
