import { BackgroundSaveHandler } from './BackgroundSaveHandler';
import { useBackgroundSaveReducer } from './useBackgroundSaveReducer';

/**
 * Responsible for coordinating all background saving.
 *
 * - manage the data for background save in its reducer
 * - update data in response to actions from other components
 * - render BackgroundSaveHandler components with correct data
 */
export const BackgroundSaveManager = ({ debug }: { debug: boolean }) => {
  const [state, dispatch] = useBackgroundSaveReducer();

  return (
    <>
      {debug && <h3>BackgroundSaveManager {state.length}</h3>}
      {state.map((report) => (
        <BackgroundSaveHandler debug={debug} key={report.reportData.userReportId} report={report} dispatch={dispatch} />
      ))}
    </>
  );
};
