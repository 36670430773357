import { useState } from 'react';
import { Button, Card, Flex, Text } from '@aws-amplify/ui-react';
import { useLazyQuery } from '@apollo/client';
import { useNavigate } from 'react-router-dom';

import { InlineError } from 'components/InlineError/InlineError';
import { GET_CONSENT_FORM } from 'apollo/queries/getConsentForm';
import { ConsentFormUrl } from 'models';
import { recordRumError } from 'services/awsRum';
import { getDsp } from 'utils/dspIndex';
import { getCsrfToken } from 'services/authorisationTokens';

import './AuthoriseDsp.scss';

interface AuthorisationProps {
  dspId: string;
  cardSelectedState: [boolean, (state: boolean) => void];
}

export const AuthoriseDsp = ({ dspId, cardSelectedState }: AuthorisationProps) => {
  const dspInfo = getDsp(dspId);
  const dspName = dspInfo.displayName;
  const Logo = dspInfo.authoriseDspIcon;
  const usingDsp = dspInfo.isUsingDspProvider;

  const navigate = useNavigate();

  const [inlineError, setInlineError] = useState(false);
  const [redirecting, setRedirecting] = useState(false);
  const [cardSelected, setCardSelected] = cardSelectedState;

  const [callGetConsentForm, getConsentFormResponse] = useLazyQuery<ConsentFormUrl>(GET_CONSENT_FORM, {
    fetchPolicy: 'network-only',
    onCompleted: (response) => {
      if (response.getConsentFormURL.success && response.getConsentFormURL.consentFormUrl) {
        const url = response.getConsentFormURL.consentFormUrl;
        // Create token and store secret in local storage
        getCsrfToken()
          .then((csrfToken) => {
            // Create state object:
            const state = JSON.stringify({ dsp: dspName, token: csrfToken });
            const encodedState = encodeURIComponent(state);
            // Append encoded state to url and navigate to consent form
            window.location.replace(`${url}&state=${encodedState}`);
            setRedirecting(true);
          })
          // Stop flow if token generation had an error
          .catch(() => setInlineError(true));
      } else {
        setInlineError(true);
      }
    },
    onError: (error) => {
      recordRumError(error);
      setInlineError(true);
    },
  });

  const handleAuth = () => {
    setInlineError(false);
    setCardSelected(true);
    if (usingDsp) callGetConsentForm({ variables: { dsp: dspId } });
    else navigate('/manual-authorisation');
  };

  if (inlineError) setCardSelected(false);

  const fontStyling = { fontSize: '16px', lineHeight: '24px', fontWeight: '600', textAlign: 'center' };

  // Amplify button types only accept a string for 'loadingText' but reading the code there is
  // nothing to prevent JSX elements being passed in to have a more customised loading state.
  const buttonContent = (
    <>
      <Flex alignItems="center" justifyContent="center" width="48px">
        <Logo width="100%" style={{ maxHeight: '48px' }} title={`${dspName} logo`} />
      </Flex>
      {/* eslint-disable-next-line jsx-a11y/aria-role */}
      <Flex role="text" direction="column" justifyContent="center" alignItems="center" gap="0" minWidth="fit-content">
        {usingDsp ? (
          <>
            <Text whiteSpace="nowrap" {...fontStyling}>
              Continue with
            </Text>
            <Text style={{ fontWeight: '600', fontSize: '20px', lineHeight: '24px', letterSpacing: '-0.01em' }}>
              {dspName}
            </Text>
          </>
        ) : (
          <Text {...fontStyling}>
            Continue without <br />
            accounting software
          </Text>
        )}
      </Flex>
    </>
  ) as unknown as string;

  return (
    <div data-testid="authorisation-form-container" className="authorisation-form">
      <Card
        className="dsp-card"
        testId={`authorisation-form-${dspId.toLowerCase()}-card`}
        variation="elevated"
        borderRadius="8px"
        border="2px solid var(--amplify-colors-border-tertiary, #EFF0F0)"
        padding="0"
        width="100%"
        boxShadow="0px 2px 4px 0px #0D1A260D"
      >
        <Button
          className="authorise-btn"
          testId={`authorisation-form-${dspName.toLowerCase()}-btn`}
          onClick={handleAuth}
          isLoading={getConsentFormResponse.loading || redirecting}
          loadingText={buttonContent}
          padding="small"
          height="100%"
          width="100%"
          justifyContent="center"
          gap="small"
          disabled={cardSelected}
        >
          {buttonContent}
        </Button>
      </Card>
      {inlineError && (
        <InlineError
          errorMessage="Sorry, that button isn't working. Please try again later."
          styles="authorisation-inline-error"
        />
      )}
    </div>
  );
};
