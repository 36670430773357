import React from 'react';
import { Button } from '@aws-amplify/ui-react';
import { ReactComponent as GoBackButtonIcon } from 'assets/icon-go-back-button.svg';

interface ButtonProps {
  onClick: () => void;
}

const goBackStyle = {
  border: 'none',
  fontSize: '16px',
  lineHeight: '24px',
  fontWeight: '600',
  padding: '4px 0px 4px 0px',
  color: 'rgba(15, 72, 124, 1)',
  display: 'flex',
  displayFlex: 'row',
  alignItems: 'flex-start',
  justifyContent: 'flex-start',
  width: '98px',
};

const GoBackButton: React.FC<ButtonProps> = ({ onClick }) => (
  <Button testId="go-back-btn" style={goBackStyle} onClick={onClick}>
    <GoBackButtonIcon />
    Go Back
  </Button>
);

export default GoBackButton;
