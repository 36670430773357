import { Button, Card, Flex, Heading, Image, Text } from '@aws-amplify/ui-react';
import { useNavigate } from 'react-router-dom';
import { externalLinks } from 'lib/externalLinks';

import './Error.scss';
import { recordRumCustomEventWithPageId } from 'services/awsRum';
import { RumCustomEvent } from 'enums/RumCustomEvent';
import { getRumAttributes } from 'utils/getRumAttributes';

interface Props {
  title: string;
  summary: string;
  content: string;
  img?: string | undefined;
}

const contactUsPage = externalLinks.absContactUs;

const Error = ({ title, summary, content, img }: Props) => {
  const navigate = useNavigate();

  return (
    <div data-testid="error-page" className="error-container">
      {!img ? (
        <Card className="error-pages" margin="200px auto">
          <Heading testId="error-page-title" className="error-page-title" level={1}>
            {title}
          </Heading>
          <Heading testId="error-page-summary" className="error-page-summary" level={2}>
            {summary}
          </Heading>
          <Text testId="error-page-content" padding="0 30px" className="error-page-content">
            {content}
          </Text>
          <Flex justifyContent="center">
            <a
              data-testid="contact-us-link"
              className="contact-us-link amplify-button amplify-button--link page-not-found-btn"
              href={contactUsPage}
              onClick={() => recordRumCustomEventWithPageId(RumCustomEvent.contactUsClicked, getRumAttributes())}
            >
              Help
            </a>
            <Button
              className="page-not-found-btn primary-button-radii-override"
              onClick={() => navigate('/')}
              testId="back-to-home-btn"
              variation="primary"
            >
              Back to home
            </Button>
          </Flex>
        </Card>
      ) : (
        <Card className="page-not-found-404">
          <Flex>
            <Card paddingLeft="130px" margin="auto" className="page-not-found-left">
              <Heading testId="page-not-found-title" className="page-not-found-title" level={1}>
                {title}
              </Heading>
              <Heading testId="page-not-found-summary" className="page-not-found-summary" level={2}>
                {summary}
              </Heading>
              <Text testId="page-not-found-content" className="page-not-found-content">
                {content}
              </Text>
              <Flex>
                <a data-testid="contact-us-link" className="contact-us-link" href={contactUsPage}>
                  <Button className="page-not-found-btn" variation="link">
                    Help
                  </Button>
                </a>
                <Button
                  className="page-not-found-btn"
                  onClick={() => navigate('/')}
                  testId="back-to-home-btn"
                  variation="primary"
                >
                  Back to home
                </Button>
              </Flex>
            </Card>
            <Image className="page-not-found-right-img" alt="page not found" src={img} />
          </Flex>
        </Card>
      )}
    </div>
  );
};
export default Error;
