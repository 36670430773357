import { Button, Card, Flex, Grid, Image, Link, Text, useBreakpointValue, View } from '@aws-amplify/ui-react';
import { useOktaAuth } from '@okta/okta-react';
import { AlertComponent } from 'components/AlertComponent/AlertComponent';
import { TIMEOUT_PARAM } from 'components/UserInactivity/InactivityModal';
import { useSearchParams, useLocation } from 'react-router-dom';
import HeroImage from 'assets/homepage-hero.jpg';
import headerImage from 'assets/homepage-welcome.png';
import { recordRumCustomEventWithPageId } from 'services/awsRum';
import { getRumAttributes } from 'utils/getRumAttributes';
import { RumCustomEvent } from 'enums/RumCustomEvent';
import { HomePageTextComponent } from 'components/TextComponent/HomePageTextComponent';
import { gridBreakPoints } from 'utils/gridBreakPoints';
import { content } from './content';

import './Homepage.scss';

interface ErrorRedirect {
  OKTAVERIFIED_PARAM?: boolean;
}

const Homepage = () => {
  const { oktaAuth } = useOktaAuth();
  const [searchParams] = useSearchParams();
  const timeout = searchParams.get('state') === TIMEOUT_PARAM;
  const location = useLocation();
  const oktaUnverifiedRedirect: ErrorRedirect = location.state as ErrorRedirect;
  const colummStartHeadingImage = useBreakpointValue({
    base: 9,
    medium: 7,
    large: 8,
  });

  return (
    <Card testId="homepage" className="homepage-wrapper">
      {timeout && (
        <View className="time-out-alert">
          <AlertComponent title={content.timeOut.title} variation="timeOut" listItem={content.timeOut.listItem} />
        </View>
      )}
      {oktaUnverifiedRedirect && (
        <View className="oktaUnverified">
          <AlertComponent
            title={content.oktaUnverified.title}
            variation="error"
            textHeaderComponent={
              <>
                You were unable to log in with your accounting software due to an earlier attempt at creating an ABS
                account.
              </>
            }
            secondTextHeaderComponent={
              <>
                Please click the activation link you received in an email, or press ‘Log in’ below and enter your email
                to continue.
              </>
            }
            alertProps={{ isDismissible: true }}
          />
        </View>
      )}
      <Grid
        className="container-grid-dashboard"
        templateColumns={useBreakpointValue(gridBreakPoints as Record<string, unknown>) as string}
        templateRows="repeat(2, auto)"
        padding="0px"
        margin="0px"
        overflow="hidden"
        maxWidth="100vw"
      >
        <View
          columnStart={1}
          columnEnd={15}
          row={1}
          style={{
            backgroundImage: `linear-gradient(0deg, #0E1D45 0%, #153D73 50%, #326297 100%)`,
            width: '100vw',
            overflow: 'hidden',
            padding: '0px',
            margin: '0px',
          }}
        />
        <View
          column={`${colummStartHeadingImage}/15`}
          row={1}
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            overflow: 'hidden',
            height: '100%',
            width: '100%',
            maxHeight: '332px',
          }}
        >
          <Image
            alt="Header Image"
            src={headerImage}
            overflow="hidden"
            objectFit="cover"
            objectPosition={{
              base: 'top left',
              large: 'center',
            }}
          />
        </View>
        <View
          columnStart={
            useBreakpointValue({
              base: 2,
              medium: 2,
            }) as number
          }
          columnEnd={
            useBreakpointValue({
              base: 14,
              medium: 9,
            }) as number
          }
          row={1}
          alignSelf={{ medium: 'center' }}
        >
          <Card className="homepage-content" margin="0px" backgroundColor="transparent">
            <Flex
              direction="column"
              row={1}
              justifyContent="center"
              margin={{ base: '48px 0px 32px 0px ', medium: '72px 0px 48px 0px ' }}
            >
              <HomePageTextComponent
                welcome={content.welcome}
                heading={content.heading}
                summary={content.summary}
                uniqueId="homepage-content"
              />
              <Flex
                className={timeout ? 'homepage-btn-group-with-alert ' : 'homepage-btn-group'}
                justifyContent="center"
                direction={{ base: 'column', medium: 'row' }}
                width="100%"
                maxWidth={{ medium: '400px' }}
              >
                <Button
                  className="homepage-login-btn login-btn"
                  width={{ base: '100%', medium: '192px' }}
                  variation="primary"
                  testId="button-login"
                  onClick={() =>
                    oktaAuth.signInWithRedirect({
                      originalUri: '/authorisation',
                    })
                  }
                >
                  Log in
                </Button>
                <Button
                  className="homepage-login-btn signup-btn"
                  variation="link"
                  width={{ base: '100%', medium: '192px' }}
                  testId="button-signup"
                  onClick={() => {
                    oktaAuth.signInWithRedirect({
                      originalUri: '/authorisation',
                      extraParams: {
                        flow: 'signup',
                      },
                    });
                  }}
                >
                  Create Account
                </Button>
              </Flex>
            </Flex>
          </Card>
        </View>
        <View
          columnStart={
            useBreakpointValue({
              base: 2,
              medium: 2,
            }) as number
          }
          columnEnd={
            useBreakpointValue({
              base: 14,
              medium: 10,
            }) as number
          }
          row={2}
        >
          <Flex direction="column" className="homepage-left-content">
            <HomePageTextComponent
              secondSummary={content.secondSummary}
              list={content.list}
              uniqueId="homepage-content"
            />
            <Text
              testId="text-data"
              className={timeout ? 'homepage-footer-with-alert' : ''}
              textAlign="left"
              padding={{
                medium: '0rem 1rem 0rem 0rem',
              }}
            >
              If you want to know more about how we use your data visit our{' '}
              <Link
                data-testid="link-privacy"
                isExternal
                href={content.linkPrivacy}
                onClick={() => recordRumCustomEventWithPageId(RumCustomEvent.privacyAtAbsClicked, getRumAttributes())}
              >
                Privacy at the ABS
              </Link>{' '}
              and{' '}
              <Link
                data-testid="link-data"
                isExternal
                href={content.linkDataUsage}
                onClick={() =>
                  recordRumCustomEventWithPageId(RumCustomEvent.dataReportingUsageClicked, getRumAttributes())
                }
              >
                ABS Business Reporting Data Usage
              </Link>{' '}
              pages.
            </Text>
            <Text
              textAlign="left"
              testId="text-contact"
              padding={{
                medium: '0rem 1rem 0rem 0rem',
              }}
              marginBottom={{
                base: '1.5rem',
                medium: '3rem',
              }}
            >
              Need help?{' '}
              <Link
                data-testid="link-contact"
                isExternal
                href={content.linkContact}
                onClick={() => recordRumCustomEventWithPageId(RumCustomEvent.contactUsClicked, getRumAttributes())}
              >
                Contact us!
              </Link>
            </Text>
          </Flex>
        </View>
        <View
          style={{ height: '100%', gridRowStart: '1', gridRowEnd: '3' }}
          className="hero-image-view"
          columnStart={
            useBreakpointValue({
              base: 0,
              medium: 10,
            }) as number
          }
          columnEnd={
            useBreakpointValue({
              base: 0,
              medium: 14,
            }) as number
          }
          paddingRight={{
            base: '0rem',
          }}
        >
          <Image
            style={{
              display: 'inline-block',
              height: '100%',
              width: 'auto',
              objectFit: 'cover', // Corrected property name
              flexGrow: 0,
            }}
            testId="hero-image"
            src={HeroImage}
            alt="A man in an apron standing and typing on a laptop next to an open notebook in a workshop"
          />
        </View>
      </Grid>
    </Card>
  );
};

export default Homepage;
