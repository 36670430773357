import { SavedReportDataItems } from 'models';
import { countSavedDataItems, getUpdatedReportDataItems } from 'utils/getUpdatedReportDataItems';
import { ReportSaveStatus } from './currentReportStatus';

// TODO: move to general report saving utils (not current report), with anything else that is not report-specific
/**
 * Computes the count of unsaved items from local and server answers
 */

export function computeReportDataItemsUpdates(localAnswers: SavedReportDataItems, serverAnswers: SavedReportDataItems) {
  const changes = safeGetUpdatedReportDataItems(localAnswers, serverAnswers);
  const count = changes ? countSavedDataItems(changes) : 0;

  return [changes, count] as const;
}

/**
 * Same as getUpdatedReportDataItems but won't crash when the values are not present.
 *
 * @param localSaved local saved report data items
 * @param serverSaved server saved report data items
 * @returns undefined if either input does not have a savedAnswers array, otherwise same as getUpdatedReportDataItems
 */
export function safeGetUpdatedReportDataItems(
  localSaved: SavedReportDataItems | undefined,
  serverSaved: SavedReportDataItems | undefined
) {
  if (Array.isArray(localSaved?.savedAnswers) && Array.isArray(serverSaved?.savedAnswers))
    return getUpdatedReportDataItems(localSaved!, serverSaved!);
}

interface ComputeReportStatusOptions {
  reportPresent: boolean;
  saving: boolean;
  everSaved: boolean;
  unsavedAnswersCount: number;
}

/**
 * Compute the save status of a report based on presence, save operation and unsaved answers
 *
 * @param options with keys:
 *    reportPresent: report is loaded (current) or found (any)
 *    saving: current save operation is in progress
 *    everSaved: report has been saved since launch
 *    unsavedAnswersCount: count of unsaved answers, should use same count as the save payload limiter
 * @returns
 */
export function computeReportStatus({
  reportPresent,
  saving,
  everSaved,
  unsavedAnswersCount,
}: ComputeReportStatusOptions): ReportSaveStatus {
  if (!reportPresent) return 'unknown';
  if (saving) return 'saving';

  const allChangesSaved = unsavedAnswersCount === 0;

  if (everSaved) {
    if (allChangesSaved) return 'saved';
    return 'changed';
  }
  if (allChangesSaved) return 'unchanged';
  return 'unsaved';
}

/**
 * Save statuses that indicate either no report is loaded, or the loaded report has no unsaved changes.
 */
const safeStates: ReportSaveStatus[] = ['unchanged', 'saved', 'unknown'];

/**
 * Check whether a report status represents a fully saved state.
 *
 * @param reportStatus reportStatus to check
 * @returns true if the status represents no unsaved changes (no report, or report fully saved)
 */
export const isFullySavedStatus = (reportStatus: ReportSaveStatus) => safeStates.includes(reportStatus);
