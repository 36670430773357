import makeVarPersisted from 'apollo/MakeVarPersisted';
import { useEffect } from 'react';
import { useReactiveVar } from '@apollo/client';
import { isSubmittingReport, savingLocalAnswersToServer } from 'apollo/states/operationsInProgress';
import { savedReportDataItems } from 'apollo/states/SavedReportDataItem';
import { latestSavedReportDataItems } from 'apollo/states/LatestSavedReportDataItems';
import { ChosenReport } from 'models';
import { chosenReport } from 'apollo/states/ChosenReport';
import { readonlyRvUtils } from './readonlyRvUtils';
import { computeReportDataItemsUpdates, computeReportStatus, isFullySavedStatus } from './reportStatusUtil';

/**
 * Status of local report saved data items compared with server report saved data items.
 *
 * - unknown: status has not been determined or there is no matching report or loaded report
 * - unchanged: no changes to local data items since report launch
 * - unsaved: unsaved changes are present in local data items and no save has happened since report launch
 * - saving: there are local changes in the process of being saved
 * - saved: local changes have been saved and there are no more local changes to save
 * - changed: local changes have been saved and there are more local changes to save
 */
export type ReportSaveStatus = 'unknown' | 'unchanged' | 'unsaved' | 'saving' | 'saved' | 'changed';

/**
 * Status of the currently loaded report saved data items.
 *
 * 'unknown' if no report is loaded
 */
const currentReportSaveStatus = makeVarPersisted<ReportSaveStatus>('unknown', 'ASP_saveManager_currentReportStatus');
const [, useCurrentReportSaveStatusHook, updateCurrentReportSaveStatus] = readonlyRvUtils(currentReportSaveStatus);
// Exposing this for testing, but it should never be changed in the real app
/**
 * @deprecated exposed for tests only, don't use in other modules. Use hook useCurrentReportSaveStatus instead.
 */
export const currentReportSaveStatusForTestOnly = currentReportSaveStatus;

/**
 * Was the current report saved since launch?
 *
 * true/false if a report is launched, otherwise undefined
 */
const currentReportEverSaved = makeVarPersisted<boolean | undefined>(false, 'ASP_saveManager_currentReportEverSaved');
const [, , updateCurrentReportEverSaved] = readonlyRvUtils(currentReportEverSaved);

/**
 * Simple hook to get current report save status updates.
 *
 * (preferable to exposing the reactive var where other modules could change it)
 *
 * @returns current report save status any time it changes
 */
export const useCurrentReportSaveStatus = useCurrentReportSaveStatusHook;

/**
 * Computes the count of unsaved items for the loaded report (if present)
 *
 * @deprecated don't use this in other modules except for debug
 * @returns
 */
export const useUnsavedAnswersAndCount = () => {
  const localAnswers = useReactiveVar(savedReportDataItems);
  const serverAnswers = useReactiveVar(latestSavedReportDataItems);

  return computeReportDataItemsUpdates(localAnswers, serverAnswers);
};

/**
 * Hook to keep currentReportStatus up to date as reactive variables change.
 *
 * This should only be included in one component (SaveReportManager), that is
 * enough to keep it updated.
 */
export const useTrackCurrentReportSaveStatus = () => {
  const chosenReportValue = useReactiveVar(chosenReport);
  // Initial state of chosenReport is defined, so use presence of ID to confirm
  const reportLoaded = !!chosenReportValue?.userReportId;
  const savingLocalToServer = useReactiveVar(savingLocalAnswersToServer);
  const [, unsavedItemsCount] = useUnsavedAnswersAndCount();

  const currentReportEverSavedVal = useReactiveVar(currentReportEverSaved);

  // Record when any save has happened (using existing save mechanism for now)
  useEffect(() => {
    // Clear the "ever saved" status when a report is closed
    // (should this be done on every *change* to userReportId instead?)
    if (!reportLoaded) updateCurrentReportEverSaved(false);

    // Set to "ever saved" as soon as a save happens with a report loaded
    if (reportLoaded && savingLocalToServer) updateCurrentReportEverSaved(true);
  }, [reportLoaded, savingLocalToServer]);

  // Compute the current report saving status whenever data items change
  useEffect(() => {
    updateCurrentReportSaveStatus(
      computeReportStatus({
        reportPresent: reportLoaded,
        saving: savingLocalToServer,
        everSaved: currentReportEverSavedVal ?? false,
        unsavedAnswersCount: unsavedItemsCount,
      })
    );
  }, [reportLoaded, savingLocalToServer, currentReportEverSavedVal, unsavedItemsCount]);
};

/**
 * One-off check for whether the current report is fully saved.
 *
 * @returns true if the loaded report is fully saved or no report is loaded, otherwise false
 */
export const isCurrentReportFullySaved = () => isFullySavedStatus(currentReportSaveStatus());

/**
 * Wait for the current report to be fully saved (all data items saved to the server).
 * Use in async code that needs to make sure everything is saved before proceeding (e.g.
 * to closing or submitting a report).
 *
 * In a React hook or component, you can use the hook form: useCurrentReportFullySaved
 *
 * usage:
 *   await waitForCurrentReportToFullySave();
 *   submitTheReport(); // for example
 *
 * @param timeoutMs (optional) if included, will throw an error after the timeout if the report isn't fully saved yet
 * @returns nothing (as a Promise)
 */
export const waitForCurrentReportToFullySave = async (timeoutMs?: number) => {
  if (isFullySavedStatus(currentReportSaveStatus())) return;

  // Idea: resolve with boolean: true if save succeeded, false if save failed or it timed out?
  const promise = new Promise<void>((resolve, reject) => {
    let timedOut = false;
    let timeoutId: number;

    if (Number.isInteger(timeoutMs)) {
      // using window.setTimeout prevents TS assuming the wrong setTimeout
      timeoutId = window.setTimeout(() => {
        timedOut = true;
        reject();
      }, timeoutMs);
    }

    // probably a util that keeps waiting on a reactive var until it has the desired status would be good
    const handleNextStatus = (status: ReportSaveStatus) => {
      // make sure we don't do anything after timeout
      if (timedOut) return;

      if (isFullySavedStatus(status)) {
        clearTimeout(timeoutId);
        resolve();
      } else {
        currentReportSaveStatus.onNextChange(handleNextStatus);
      }
    };

    currentReportSaveStatus.onNextChange(handleNextStatus);
  });

  await promise;
};

export const useCurrentReportFullySaved = () => {
  const status = useReactiveVar(currentReportSaveStatus);

  return isFullySavedStatus(status);
};

/**
 * Determine whether a report is loaded and in a status that can be saved with SaveReport.
 *
 * Only checks that a report is launched and the launched report is not submitting or submitted.
 *
 * Assumptions:
 *  - only editable/saveable reports can be launched
 *  - chosenReport is always set to the launched report
 *  - once submit has been initiated, the report will not be saveable any more
 *    (technically not true if the submit fails)
 *  - launched reports are saveable until they are submitted
 *  - we always update currentReport.submitted to true on successful submit
 *
 * CAUTION: this is not suitable for background save since it assumes a launched report.
 */
const isReportSaveable = (currentReport: ChosenReport | undefined, submittingReport: boolean) =>
  !!currentReport && !submittingReport && !currentReport.submitted;

/**
 * @returns true when a report is loaded and not in a submitting/submitted state, otherwise false
 */
export const isCurrentReportSaveable = () => isReportSaveable(chosenReport(), isSubmittingReport());

/**
 * Hook version of isCurrentReportSaveable.
 *
 * @returns true when a report is loaded and not in a submitting/submitted state, otherwise false
 */
export const useCurrentReportSaveable = () => {
  const currentReport = useReactiveVar(chosenReport);
  const submittingReport = useReactiveVar(isSubmittingReport);
  return isReportSaveable(currentReport, submittingReport);
};
