import { AbsUserClaims } from 'lib/authConfig';
import { AccessDetail } from 'models';

/**
 * Function to determine if a user has delegation authority for an organisation
 * @param organisation
 * @param userInfo
 * @returns boolean value determining if the user is able to delegate for the specified organisation
 */
export const canUserDelegateNoDspOrganisation = (
  userInfo: AbsUserClaims | null | undefined,
  organisation: AccessDetail | undefined
) => {
  // Users systemId
  const maxDelegatesReached = organisation?.organisationDetails?.providerDetails.maxDelegatees === false;

  // When a non-dsp organisation is created,
  // the organisationId is a combination of the user's system id and the provider id
  // We use this to determine if the user is the owner of the organisation
  const isOwner = doesUserOwnOrganisation(userInfo, organisation);

  return isOwner && maxDelegatesReached;
};

export const doesUserOwnOrganisation = (
  userInfo: AbsUserClaims | null | undefined,
  organisation: AccessDetail | undefined
) => Boolean(organisation?.organisationDetails?.organisationId?.includes(userInfo?.sub ?? ''));
