import { authorisedBusinesses } from 'apollo/states/AuthorisedBusinesses';

export const setActiveBusinessAfterClaim = (id: string) => {
  const currentBusinesses = authorisedBusinesses();
  authorisedBusinesses({
    ...currentBusinesses,
    activeBusiness: {
      id,
      dsp: '',
      name: '',
      postcode: '',
      suburb: '',
      needsSetContactDetails: true,
    },
  });
};
