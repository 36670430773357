import { authorisedBusinesses } from 'apollo/states/AuthorisedBusinesses';
import { useReactiveVar } from '@apollo/client';
import { Image, Flex, Heading, Text, View, Grid, useBreakpointValue } from '@aws-amplify/ui-react';
import ValueBackImage from 'assets/value-back/infographic.png';
import PostcodeSearch from './PostcodeSearch';

import './ValueBackCard.css';

const ValueBackCard = () => {
  const currentBusinesses = useReactiveVar(authorisedBusinesses);

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const postcode: string = currentBusinesses.activeBusiness?.postcode || '';

  return (
    <Grid
      testId="value-back-component"
      borderRadius="var(--amplify-radii-large)"
      padding="var(--amplify-space-large)"
      rowGap="32px"
      backgroundColor="#EAF1FF"
      marginTop="15px"
      width="100%"
      templateColumns="repeat(8, 1fr)"
    >
      <Flex
        rowGap="large"
        column={useBreakpointValue({ base: '1/-1', large: '1/5' }) as string}
        row={useBreakpointValue({ base: '2', large: '1' }) as string}
        direction="column"
      >
        <Heading level={2} fontWeight="600" fontSize="32px" lineHeight="40px" letterSpacing="-2%">
          More for your business
        </Heading>
        <Text fontWeight="400" fontSize="16px" lineHeight="24px">
          Explore our data about people and businesses in your area with ABS Data by region.
        </Text>
        <View>
          <PostcodeSearch postcode={postcode} />
        </View>
      </Flex>
      <Flex
        alignItems="center"
        justifyContent="center"
        gap="0"
        column={useBreakpointValue({ base: '1/-1', large: '5/-1' }) as string}
        row="1"
      >
        <Image
          objectFit="scale-down"
          objectPosition="center"
          testId="value-back-infographic"
          className="value-back-infographic"
          src={ValueBackImage}
          alt="Graphs and charts emerging from a screen. Two people scrutinize the data with a giant magnifying glass."
        />
      </Flex>
    </Grid>
  );
};

export default ValueBackCard;
