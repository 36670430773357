import './EmbedVideo.css';

export const EmbedVideo = ({ src = '', title = '' }) =>
  src && src.match(/^(https?:\/\/)?(www\.youtube(-nocookie)?\.com|youtu\.be)\/.+$/g) ? (
    <div className="video-responsive" data-testid="video-iframe" style={{ marginBottom: '50px', marginTop: '25px' }}>
      <iframe
        width="853"
        height="480"
        src={src}
        allowFullScreen
        title={title}
        loading="lazy"
        style={{ border: 'none', marginTop: '0px' }}
      />
    </div>
  ) : null;
