/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import makeVarPersisted from 'apollo/MakeVarPersisted';
import { AuthorisedBusiness } from 'models';

export interface AuthorisedBusinesses {
  activeBusiness: AuthorisedBusiness | undefined;
  authorisedBusinesses: AuthorisedBusiness[];
}

export const AuthorisedBusinessesInitialState = {
  activeBusiness: undefined,
  authorisedBusinesses: [],
};

export const authorisedBusinesses = makeVarPersisted<AuthorisedBusinesses>(
  AuthorisedBusinessesInitialState,
  'authorisedBusinesses'
);
