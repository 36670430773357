/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { Fragment, useEffect, useRef, useState } from 'react';
import { Flex, Text, Expander, ExpanderItem } from '@aws-amplify/ui-react';
import { useReactiveVar } from '@apollo/client';

import {
  ExpandedReview,
  ExpandedReviewClicked,
  addToExpandersArray,
  updateExpandersArray,
} from 'apollo/states/ExpandedReview';
import { currencyFormat } from 'utils/CurrencyFormat';
import { GetTotalByTopic } from 'apollo/states/utils/GetTotalByTopic';
import { chosenReport } from 'apollo/states/ChosenReport';

import 'pages/Report/ReportPage.scss';
import { TopicFullDetail } from 'models';
import { TopicName, getTopicByName } from 'models/LocalState/TopicFullDetail';
import useCurrentReportTopics from 'apollo/states/utils/useCurrentReportTopics';
import { RumCustomEvent } from 'enums/RumCustomEvent';
import { recordRumCustomEvent } from 'services/awsRum';
import { getRumAttributes } from 'utils/getRumAttributes';

const RuleTopicTotalSection = ({ topicName }: { topicName: TopicName }) => {
  const topics = useCurrentReportTopics();
  const topic = getTopicByName(topics, topicName);
  if (!topic) return null;

  const total = currencyFormat.format(GetTotalByTopic(topicName));

  return (
    <Flex justifyContent="space-between">
      <Text>{topic?.topicTotalText}</Text>
      <Text className="profit-loss-minified-amounts">{total}</Text>
    </Flex>
  );
};

export const RuleTopicSummaries = ({ ruleTopics, topics }: { ruleTopics: TopicName[]; topics: TopicFullDetail[] }) => {
  // This is only called with defined rule, but this keeps the TS compiler happy for now
  if (!ruleTopics.length) return null;

  // Topic detail for each topic in the rule, in the order they appear
  const presentTopics = ruleTopics
    .map((topicName) => getTopicByName(topics, topicName))
    // only include if they could be found
    .filter((topic: TopicFullDetail | undefined) => topic);

  return (
    <>
      {presentTopics.map((topic: TopicFullDetail | undefined, index: number) =>
        topic ? (
          <Fragment key={index}>
            <Flex justifyContent="space-between" className="width-640">
              <Text className="sub-head">{topic.topicTotalText}</Text>
              <Text className="sub-head">{currencyFormat.format(GetTotalByTopic(topic.topic))}</Text>
            </Flex>
            <Text className="sub-desc">
              Calculated from the {topic.topicShortTitle ?? topic.topicTitle ?? topic.topic} section of this report
            </Text>
            <div className="seperator width-640" />
          </Fragment>
        ) : null
      )}
    </>
  );
};

/**
 * Expandable review/summary section of a rule topic.
 */
export const MinifiedRuleTopicSummaries = ({ topicFullDetail }: { topicFullDetail: TopicFullDetail }) => {
  const focusRef = useRef<HTMLHeadingElement>(null);

  // Expander component expects a data item ID to track expanded state, but rule sections
  // also have expanders and need to use a distinct ID.
  const mockDataItemId = `rule-summary-${topicFullDetail.key}`;

  const chosenReportDetails = chosenReport();
  const isNoDsp = chosenReportDetails.isUsingDsp === false;

  /* istanbul ignore next */
  useEffect(() => {
    if (focusRef.current != null) {
      focusRef.current.focus();
    }
    addToExpandersArray(mockDataItemId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const ruleTotal = currencyFormat.format(GetTotalByTopic(topicFullDetail.topic));

  const allExpanded = useReactiveVar(ExpandedReview);
  const allExpandedClicked = useReactiveVar(ExpandedReviewClicked);
  const [individualExpanded, setIndividualExpanded] = useState(false);

  const ReviewTitle = (
    <Flex style={isNoDsp ? { fontWeight: '600', margin: '24px 0' } : {}} width="100%" justifyContent="space-between">
      <Text>{topicFullDetail.topicTotalText}</Text>
      <Text>{ruleTotal}</Text>
    </Flex>
  );

  const ReviewBody = (
    <>
      {topicFullDetail.compiledRule?.topicNames.map((topicName) => (
        <RuleTopicTotalSection key={topicName} topicName={topicName} />
      ))}
      <div className="profit-loss-minified-divider" />
      <Flex justifyContent="space-between" paddingBottom="40px">
        <Text testId="profit-loss-minified-total-header">{topicFullDetail.topicTotalText}</Text>
        <Text className="profit-loss-minified-amounts">{ruleTotal}</Text>
      </Flex>
    </>
  );

  useEffect(() => {
    setIndividualExpanded(allExpanded);
    updateExpandersArray(mockDataItemId, allExpanded);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allExpandedClicked]);

  return isNoDsp ? (
    <div style={{ marginLeft: '32px', marginBottom: '20px' }}>
      {ReviewTitle}
      {ReviewBody}
    </div>
  ) : (
    <Expander
      type="single"
      isCollapsible
      value={individualExpanded ? 'item' : ''}
      onValueChange={() => {
        if (ExpandedReview()) ExpandedReview(false);
        setIndividualExpanded((current) => !current);
        updateExpandersArray(mockDataItemId, !individualExpanded);
        if (!individualExpanded)
          recordRumCustomEvent(RumCustomEvent.expandIndividualSection, {
            mockDataItemId,
            ...getRumAttributes(),
          });
      }}
      style={{ marginBottom: '2px' }}
    >
      <ExpanderItem value="item" className="report-list-expander" title={ReviewTitle}>
        {ReviewBody}
      </ExpanderItem>
    </Expander>
  );
};
