import { DataItem } from 'models';

export type DataItemTexts = {
  [key: string]: string | undefined;
  helpText: string | undefined;
  itemText: string | undefined;
};

export const getDataItemTexts = (dataItem: DataItem, isNoDsp: boolean): DataItemTexts => {
  const texts: DataItemTexts = {
    /* eslint-disable @typescript-eslint/no-unsafe-assignment */
    helpText: isNoDsp
      ? dataItem.dataItemInformationNoDsp ?? dataItem.dataItemInformation
      : dataItem.dataItemInformation,
    itemText: isNoDsp ? dataItem.dataItemTextNoDsp ?? dataItem.dataItemText : dataItem.dataItemText,
    /* eslint-enable @typescript-eslint/no-unsafe-assignment */
  };

  return texts;
};
