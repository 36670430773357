/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { useEffect, useMemo, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { Flex, Grid, View, useBreakpointValue } from '@aws-amplify/ui-react';

import { recordRumCustomEvent } from 'services/awsRum';
import { chosenReport } from 'apollo/states/ChosenReport';
import { resetLastSavedTimer, saveTimer } from 'apollo/states/SaveTimer';
import useCurrentReportSteps, { findNextReportStep } from 'apollo/states/utils/useCurrentReportSteps';
import { SideNavBar } from 'components/SideNavbar/SideNavbar';
import { useSaveReport } from 'utils/useSaveReport';
import { saveIntervalSeconds } from 'utils/saveInterval';
import { getRumAttributes } from 'utils/getRumAttributes';
import { useSetReportLock } from 'utils/useSetReportLock';
import { RumCustomEvent } from 'enums/RumCustomEvent';

import './ReportPage.scss';
import { PageRoutes } from 'enums/PageRoutes';
import { gridBreakPoints } from 'utils/gridBreakPoints';
import GoBackButton from 'utils/goBackButton';
import ToastNotification from 'components/ToastNotification/ToastNotification';
import { isCurrentReportFullySaved, isCurrentReportSaveable } from 'services/SaveReportManager/currentReportStatus';
import { backgroundSaveCurrentReport } from 'services/SaveReportManager/reportSaveUtil';

const ReportPage = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const chosenReportDetails = chosenReport();
  const reportPage = pathname.split('/').at(-1);
  const isConfirmationPage = reportPage === PageRoutes.ReportConfirmation.routePath;
  const isInstructionPage = reportPage === PageRoutes.ReportInstructions.routePath;

  const [saveLockedFailureNotificationOpen, setSaveLockedFailureNotificationOpen] = useState(false);
  const currentReportSteps = useCurrentReportSteps();

  const [, saveReportResponse] = useSaveReport('ReportPage (manual)');
  const backLink = findNextReportStep(currentReportSteps, pathname, true)?.link ?? '/report';

  const isLocked = useMemo(() => {
    if (chosenReportDetails.singleUserAccess?.lock) {
      setSaveLockedFailureNotificationOpen(true);
      return true;
    }
    return false;
  }, [chosenReportDetails?.singleUserAccess?.lock]);

  const unlockReport = useSetReportLock('unlock');

  // Proceed from "Save and exit" click on successful save
  useEffect(() => {
    if (saveReportResponse.success) {
      recordRumCustomEvent(RumCustomEvent.reportClose, getRumAttributes());
      navigate('/dashboard');
    }
  }, [saveReportResponse.success, navigate, unlockReport]);

  useEffect(() => {
    if (!chosenReportDetails?.userReportId && !isConfirmationPage) {
      navigate('/dashboard', { replace: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  // If this topic is not in the current report, navigate away
  useEffect(() => {
    const currentStep = currentReportSteps.find((step) => step.link === pathname);

    if (!currentStep) {
      // Not a report page, navigate to another report page
      const nextStep = findNextReportStep(currentReportSteps, pathname);
      if (nextStep) navigate(nextStep.link, { replace: true });
    }
  }, [pathname, currentReportSteps, navigate]);

  // Separate hook instance from the useSaveReport above for save & exit, otherwise the
  // save and exit behaviour would be triggered whenever timed save finished
  const [saveReport] = useSaveReport('ReportPage (timed)');

  // SaveReport hook instance for the Go Back button.
  const [saveReportGoBackButton] = useSaveReport('ReportPage (go back button)');

  /* istanbul ignore next */
  useEffect(() => {
    const timer = setInterval(() => {
      const saveTimerVal = saveTimer();
      if (saveIntervalSeconds > saveTimerVal.timer) {
        saveTimer({ ...saveTimerVal, timer: saveTimerVal.timer + 1 });
      } else if (!isConfirmationPage) {
        if (isLocked) return;
        saveReport('useEffect for timer interval', 'report page save timer elapsed');
        resetLastSavedTimer();
      }
    }, 1000);

    // clear interval on unmount
    return () => {
      clearInterval(timer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const sideBarColumns = useBreakpointValue({
    base: '1/15',
    medium: '1/5',
    xl: '1/4',
  }) as string;

  const sideBarTemplateColumns = useBreakpointValue({
    base: '0rem repeat(12, 1fr) 0rem',
    medium: '',
  }) as string;

  const sideBarOverflow = useBreakpointValue({
    base: 'visible',
    medium: 'hidden',
  }) as string;

  const sideBarTopMargin = useBreakpointValue({
    base: '0',
    medium: '60px',
  }) as string;

  const sideBarTop = useBreakpointValue({
    base: '0',
    medium: 'calc(60px + var(--top-nav-menu))',
  }) as string;

  const mainContainerColumns = useBreakpointValue({
    base: '2/14',
    medium: isConfirmationPage ? '1/13' : '5/13',
    large: isConfirmationPage ? '1/11' : '5/13',
    xl: isConfirmationPage ? '1/9' : '5/13',
  }) as string;

  const reportPageWrapperTemplateColumns = useBreakpointValue({
    base: '0rem repeat(12, 1fr) 0rem',
    medium: 'repeat(12, 1fr)',
  }) as string;

  const reportPageWrapperColumns = useBreakpointValue({
    base: '1/15',
    medium: '2/-2',
  }) as string;

  return (
    <Grid className="report" templateColumns={useBreakpointValue(gridBreakPoints as Record<string, unknown>) as string}>
      <Grid
        className="report-page-wrapper"
        column={reportPageWrapperColumns}
        templateColumns={reportPageWrapperTemplateColumns}
      >
        {!isConfirmationPage && (
          <Grid
            className="report-side-nav-wrapper"
            column={sideBarColumns}
            overflow={sideBarOverflow}
            marginTop={sideBarTopMargin}
            top={sideBarTop}
            templateColumns={sideBarTemplateColumns}
          >
            {/* Topic links */}
            <SideNavBar />
          </Grid>
        )}
        <View className="report-main-container" column={mainContainerColumns}>
          {backLink && !isConfirmationPage && (
            <div style={{ marginTop: '48px' }}>
              <GoBackButton
                onClick={() => {
                  if (isInstructionPage) {
                    if (isCurrentReportSaveable() && !isCurrentReportFullySaved()) {
                      backgroundSaveCurrentReport({
                        component: 'Go back button',
                        callSite: 'onClick',
                        triggeredBy: 'user clicked go back button',
                        triggerLocation: window.location.pathname,
                      });
                    }
                    navigate('/dashboard');
                  } else {
                    saveReportGoBackButton('saveReport hook', 'go back button');
                    navigate(backLink);
                  }
                }}
              />
            </div>
          )}
          <div id="skipTarget" />
          {!isConfirmationPage && (
            <Flex justifyContent="space-between">
              <span data-testid="report-header" className="report-header">
                <span data-testid="report-trading-name" className="trading-name">
                  {chosenReportDetails.organisationName},
                </span>{' '}
                <span data-testid="trading-name">
                  {`${chosenReportDetails.reportName}, ${chosenReportDetails.reportPeriod}`}
                </span>
                <span
                  data-testid="trading-period"
                  className="trading-period"
                >{` (${chosenReportDetails.reportDateRangeTitleText})`}</span>
              </span>
            </Flex>
          )}
          <Outlet />
        </View>
      </Grid>
      <ToastNotification
        success={false}
        title="Failed to save. The report is locked by another user."
        open={saveLockedFailureNotificationOpen}
        onClose={() => setSaveLockedFailureNotificationOpen(false)}
      />
    </Grid>
  );
};

export default ReportPage;
