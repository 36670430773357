import { Text, Flex, SwitchField, Label, Loader } from '@aws-amplify/ui-react';
import './InsightsToggle.css';
import { InputLabelTooltip } from 'components/InputLabelTooltip/InputLabelTooltip';

interface Props {
  title: string;
  minWidth?: string;
  inputId: string;
  ariaLabel: string;
  label: string;
  info?: string | React.ReactNode;
  disabled?: boolean;
  loading?: boolean;
  error?: boolean;
  handleChange: (value: boolean) => void;
  style?: React.CSSProperties;
}

const InsightsToggle: React.FC<Props> = ({
  title,
  minWidth = '150px',
  ariaLabel,
  label,
  info,
  disabled,
  handleChange,
  loading,
  error,
  style,
}) => (
  <Flex direction="column" gap="4px" testId={`select-insights-input-${title}`} minWidth={minWidth} style={style}>
    <Text
      className="select-business-label"
      data-testid="toggle-value-back-label"
      style={{ marginBottom: '0px', fontWeight: '600', fontSize: '16px', lineHeight: '24px' }}
    >
      {title}

      {info && (
        <InputLabelTooltip
          title={info}
          helpTextFor={ariaLabel}
          placement="top"
          arrow
          data-testid="toggle-value-back-tooltip"
          style={{ position: 'absolute', marginTop: '-2px' }}
        />
      )}
    </Text>
    <Flex
      direction="row"
      gap="8px"
      alignItems="center"
      paddingTop="8px"
      className="insight-toggle-switch-input-container"
    >
      <SwitchField
        label={ariaLabel}
        aria-label={ariaLabel}
        trackCheckedColor="#326297"
        isDisabled={disabled}
        onChange={(e) => {
          handleChange(Boolean(e.currentTarget.checked));

          // Checking the error and label to determine if we need to blur the element (remove focus)
          // Due to timing, we are unable to rely on the 'disabled' value/property
          if (error || !label) e.currentTarget.blur();
        }}
        id="business-data-switch-field"
        data-testid="business-data-switch-field"
        size="large"
        lineHeight="0.5"
      />
      {label && (
        <>
          {loading && <Loader aria-label="Loading..." height="16px" width="16px" style={{ cursor: 'not-allowed' }} />}
          <Label
            {...(loading && { className: 'loading-ellipsis' })}
            htmlFor="business-data-switch-field"
            color="grey"
            style={{ cursor: disabled ? 'not-allowed' : 'pointer' }}
            fontWeight="400"
          >
            {label}
          </Label>
        </>
      )}
    </Flex>
  </Flex>
);
export default InsightsToggle;
