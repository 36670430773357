/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
import { useEffect, useState } from 'react';
import { useReactiveVar } from '@apollo/client';
import { Button, Flex, Text, SelectField, TextField, VisuallyHidden } from '@aws-amplify/ui-react';
import { Controller, useForm } from 'react-hook-form';

import { chosenReport } from 'apollo/states/ChosenReport';
import { userContactDetails, UserContactInitialState } from 'apollo/states/UserContactDetails';
import { ReactComponent as IconChevronDown } from 'assets/icon-chevron-down.svg';
import { UserContactDetails } from 'models';
import { allAllowedCharsRegex, mobilePhoneRegex } from 'utils/CharacterValidation';
import { InlineError } from 'components/InlineError/InlineError';
import useSetContactDetails from 'utils/useSetContactDetails';
import { InputLabelTooltip } from 'components/InputLabelTooltip/InputLabelTooltip';
import { formatPhoneNumber } from 'utils/formatPhoneNumber';

import { ReactComponent as CloseIconSvg } from 'assets/icon-x.svg';
import { ReactComponent as TickIconSvg } from 'assets/icon-tick-small.svg';

import './ContactDetails.scss';

const roleOptions = ['Business owner', 'Business employee', 'Accountant', 'BAS or Tax agent', 'Bookkeeper', 'Other'];
const NAME_MAX_LENGTH = 32;
const MOBILE_MAX_LENGTH = 12;

interface ContactDetailsProps {
  editing?: boolean;
  lockEditing?: () => void;
}

const ContactDetails = (props: ContactDetailsProps) => {
  const { editing = true, lockEditing } = props;
  const user = useReactiveVar(userContactDetails);
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<UserContactDetails>();

  const [inlineError, setInlineError] = useState(false);

  const [newUser, setNewUser] = useState<UserContactDetails>(UserContactInitialState);

  const localChosenReport = chosenReport();

  const [callUpdateContactDetail, responseUpdateContactDetail] = useSetContactDetails();

  const contactNamePattern = {
    value: allAllowedCharsRegex,
    message: 'Only alphanumeric and standard special characters allowed',
  };

  const contactNameLengthValidation = { value: NAME_MAX_LENGTH, message: `Max ${NAME_MAX_LENGTH} characters` };

  const handleLockEditing = () => {
    if (lockEditing) lockEditing();
  };

  /* istanbul ignore next */
  const onSubmit = (data: UserContactDetails) => {
    setInlineError(false);
    setNewUser(data);
    callUpdateContactDetail({
      variables: {
        saveContactDataObject: {
          userReportId: localChosenReport.userReportId || '',
          dspProvider: localChosenReport?.dspProvider || '',
          organisationId: localChosenReport?.organisationId || '',
          contactDataObject: {
            email: data.email || 'user@email.com',
            firstName: data?.firstName || '',
            lastName: data?.lastName || '',
            phone: data?.mobile || '',
            role: data?.role || '',
          },
        },
      },
    });
  };

  const handleReset = () => {
    reset(user);
    handleLockEditing();
  };

  /* istanbul ignore next */
  useEffect(() => {
    if (
      !responseUpdateContactDetail.error &&
      !responseUpdateContactDetail.loading &&
      responseUpdateContactDetail.data
    ) {
      if (responseUpdateContactDetail.data.setContactDetails.success) {
        userContactDetails(newUser);
        handleLockEditing();
      } else {
        setInlineError(true);
      }
    } else if (responseUpdateContactDetail.error) {
      setInlineError(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [responseUpdateContactDetail.error, responseUpdateContactDetail.loading]);

  useEffect(() => {
    setInlineError(false);
  }, [editing]);

  return (
    <div>
      <form data-testid="contact-details-form" onSubmit={handleSubmit(onSubmit)}>
        <Flex direction="column" gap="0px">
          <Controller
            name="firstName"
            rules={{
              required: 'First name is required',
              pattern: contactNamePattern,
              maxLength: contactNameLengthValidation,
            }}
            control={control}
            defaultValue={user.firstName}
            render={({ field }) => (
              <TextField
                className="contact-input-field name-input report-input-field"
                testId="contact-first-name"
                label="First name"
                {...field}
                disabled={!editing}
                hasError={!!errors.firstName}
                errorMessage={errors.firstName?.message}
                maxLength={NAME_MAX_LENGTH}
                autoComplete="given-name"
              />
            )}
          />

          <Controller
            name="lastName"
            rules={{
              required: 'Last name is required',
              pattern: contactNamePattern,
              maxLength: contactNameLengthValidation,
            }}
            control={control}
            defaultValue={user.lastName}
            render={({ field }) => (
              <TextField
                className="contact-input-field name-input report-input-field"
                testId="contact-last-name"
                label="Last name"
                {...field}
                disabled={!editing}
                hasError={!!errors.lastName}
                errorMessage={errors.lastName?.message}
                maxLength={NAME_MAX_LENGTH}
                autoComplete="family-name"
              />
            )}
          />
          <Controller
            name="mobile"
            rules={{
              required: 'Mobile number is required',
              pattern: {
                value: mobilePhoneRegex,
                message: 'This mobile number must be 10 digits or 12 digits including the country code +614',
              },
            }}
            control={control}
            defaultValue={user.mobile}
            render={({ field }) => (
              <TextField
                className="contact-input-field report-input-field"
                testId="contact-mobile"
                label={
                  <Flex alignItems="center" gap="4px">
                    <Text>Mobile number</Text>
                    <InputLabelTooltip
                      title={
                        <Text id="mobile-tooltip" role="tooltip">
                          This must be an Australian mobile number. It must be 10 digits or 12 digits including the
                          country code +614.
                        </Text>
                      }
                      helpTextFor="Mobile number"
                      placement="top"
                      arrow
                    />
                  </Flex>
                }
                {...field}
                value={editing ? field.value : formatPhoneNumber(field.value)}
                disabled={!editing}
                hasError={!!errors.mobile}
                errorMessage={errors.mobile?.message}
                maxLength={MOBILE_MAX_LENGTH}
                autoComplete="tel"
              />
            )}
          />
          <Controller
            name="email"
            rules={{ required: 'Email is required' }}
            control={control}
            defaultValue={user.email}
            render={({ field }) => (
              <TextField
                className="contact-input-field email-input report-input-field"
                testId="contact-email"
                label="Email address"
                {...field}
                readOnly
                hasError={!!errors.email}
                errorMessage={errors.email?.message}
                tabIndex={-1}
              />
            )}
          />
          <Controller
            name="role"
            control={control}
            defaultValue={user.role}
            render={({ field }) => (
              <SelectField
                className="contact-input-field role-input report-input-field"
                testId="contact-role"
                label="Role"
                options={roleOptions}
                placeholder={editing ? 'Choose your company role' : 'No role selected'}
                {...field}
                disabled={!editing}
                // eslint-disable-next-line react/jsx-no-useless-fragment
                icon={editing ? <IconChevronDown width={24} height={24} /> : <></>}
              />
            )}
          />
        </Flex>
        {editing && (
          <>
            <Flex justifyContent="end" gap="8px">
              <Button
                className="contact-details-btn"
                testId="contact-details-save-btn"
                isLoading={responseUpdateContactDetail.loading}
                variation="primary"
                type="submit"
              >
                <VisuallyHidden>save</VisuallyHidden>
                <TickIconSvg className="icon" />
              </Button>
              <Button
                className="contact-details-btn"
                testId="contact-details-cancel-btn"
                disabled={responseUpdateContactDetail.loading}
                onClick={handleReset}
              >
                <VisuallyHidden>cancel</VisuallyHidden>
                <CloseIconSvg />
              </Button>
            </Flex>
            <Flex justifyContent="end" direction="row">
              {inlineError && <InlineError />}
            </Flex>
          </>
        )}
      </form>
    </div>
  );
};

export default ContactDetails;
