import './Text.scss';
import { Heading, Card, Text } from '@aws-amplify/ui-react';

interface Props {
  welcome?: string;
  heading?: string;
  summary?: string;
  secondSummary?: string;
  list?: string[];
  uniqueId: string;
}
const color = '#FFFF';
const textFontSize = { base: '18px', medium: '20px' };

// This component is very similar to the Text.tsx component however there are some differences and the way that the responsive redesign was done it was easier
// to split the homepage out into it's own component because it wasn't clear how much the changes would effect the other designs being worked on
// however the text.tsx component and this one can be merged in the future to clean up the code base

export const HomePageTextComponent: React.FC<Props> = ({
  welcome,
  heading,
  summary,
  secondSummary,
  list,
  uniqueId,
}) => (
  <Card className="text-component" backgroundColor="transparent" testId={`text-component-card-container-${uniqueId}`}>
    {welcome && (
      <Heading
        testId={`text-component-card-heading-${uniqueId}`}
        color={color}
        level={2}
        fontWeight="600"
        fontSize="20px"
        textAlign="left"
        padding={{
          base: '0px',
        }}
      >
        {welcome}
      </Heading>
    )}
    {heading && (
      <Heading
        testId={`text-component-card-heading-${uniqueId}`}
        color={color}
        level={2}
        fontWeight="600"
        fontSize={{ base: '40px', medium: '48px' }}
        textAlign="left"
        padding="0rem 0rem 1.5rem 0rem"
      >
        {heading}
      </Heading>
    )}
    {summary && (
      <Text
        fontSize={textFontSize}
        testId={`text-component-card-text-${uniqueId}`}
        as="p"
        color={color}
        padding="0rem 1.5rem 1.5rem 0rem"
        textAlign="left"
        margin="0px"
      >
        {summary}
      </Text>
    )}
    {secondSummary ? (
      <Text
        marginTop={{ base: '0px', medium: '16px' }}
        fontSize={textFontSize}
        testId={`text-component-card-text-${uniqueId}`}
        as="p"
        textAlign="left"
      >
        {secondSummary}
      </Text>
    ) : null}
    {list ? (
      <ul data-testid="content-list">
        {list?.map((content, id) => (
          <li key={id} data-testid={`list-item-${id}`}>
            {content}
          </li>
        ))}
      </ul>
    ) : null}
  </Card>
);
