/* eslint-disable @typescript-eslint/no-unsafe-argument */
import { useEffect, useState } from 'react';
import { BaseLoaderProps, Flex, ForwardRefPrimitive, Loader, Text } from '@aws-amplify/ui-react';
import { useReactiveVar } from '@apollo/client';

import { savingLocalAnswersStatus } from 'apollo/states/operationsInProgress';

import { ReactComponent as TickIconSvg } from 'assets/icon-progress-indicator-visited.svg';
import { ReactComponent as CrossIconSvg } from 'assets/icon-x-not-saved.svg';
import { ReactComponent as UnsavedChanges } from 'assets/icon-unsaved-changes.svg';

import './ReportSaveStatus.css';

interface SaveStatus {
  text: string;
  color: string;
  background: string;
  icon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>> | ForwardRefPrimitive<BaseLoaderProps, 'svg'>;
}

// Default status is blank to reserve height of card to avoid layout shift when rendering other statuses
const defaultStatus: SaveStatus = { text: '', color: '', background: '' };
const savingStatus: SaveStatus = { text: 'Saving...', color: '#002266', background: '#E6EEFE', icon: Loader };
const savedStatus: SaveStatus = { text: 'Saved', color: '#002266', background: '#E6EEFE', icon: TickIconSvg };
const notSavedStatus: SaveStatus = { text: 'Not saved', color: '#660000', background: '#FCE9E9', icon: CrossIconSvg };
// TODO: Implement unsavedChanges status
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const unsavedChangesStatus: SaveStatus = {
  text: 'Unsaved Changes',
  color: '#5C6670',
  background: '#EFF0F0',
  icon: UnsavedChanges,
};

const ReportSaveStatus = () => {
  const [status, setStatus] = useState<SaveStatus>(defaultStatus);

  const saveStatus = useReactiveVar(savingLocalAnswersStatus);

  useEffect(() => {
    switch (saveStatus) {
      case 'idle':
        setStatus(defaultStatus);
        break;
      case 'saving':
        setStatus(savingStatus);
        break;
      case 'saved':
        setStatus(savedStatus);
        break;
      case 'error':
        setStatus(notSavedStatus);
        break;
      default:
        setStatus(defaultStatus);
    }
  }, [saveStatus]);

  return (
    <Flex
      testId="report-save-status-text"
      justifyContent="center"
      alignItems="center"
      gap="xxxs"
      height="100%"
      aria-live="polite"
      backgroundColor={status.background}
      borderRadius="12px"
      padding="0 xs"
      minHeight="24px"
    >
      {status.icon && (
        <status.icon
          className={status === savingStatus ? 'saving-rotate-icon' : ''}
          data-testid="report-save-status-icon"
          fill={status.color}
          height="16px"
          width="16px"
        />
      )}
      {status.text && (
        <Text as="span" color={status.color} fontWeight="400" fontSize="14px" lineHeight="24px">
          {status.text}
        </Text>
      )}
    </Flex>
  );
};

export default ReportSaveStatus;
