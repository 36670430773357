import { AbsUserClaims } from 'lib/authConfig';
import { Contact } from 'models/GraphQL/Contact';

/**
 * Build Contact Details object from userInfo and role
 * @param userInfo userInfo object returned by useUserInfo
 * @param role role of the organisation
 * @returns A partial of the Contact type
 */
export const buildContactDetails = (userInfo: AbsUserClaims | null, role: string): Partial<Contact> => {
  // Pega only accepts 32 chars for name fields
  const firstName = userInfo?.firstName?.substring(0, 32);
  const lastName = userInfo?.lastName?.substring(0, 32);
  return { firstName, lastName, email: userInfo?.email, phone: userInfo?.mobile, role };
};
