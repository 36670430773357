import { Button, Flex, Text, Image, Heading, View } from '@aws-amplify/ui-react';
import { externalLinks } from 'lib/externalLinks';
import Menu from 'components/Menu/Menu';

import ABSLogo from 'assets/logo-ABS.svg';

import './ServiceDown.css';
import { useLocation, useNavigate } from 'react-router-dom';
import { getRumAttributes } from 'utils/getRumAttributes';
import { recordRumCustomEventWithPageId } from 'services/awsRum';
import { RumCustomEvent } from 'enums/RumCustomEvent';

export const ServiceDown = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const handlePress = () => {
    // if cloudfront is pointing to /service-down route, button should send user home.
    if (location.pathname === '/service-down') navigate('/');

    // when service is down, dashboard redirects to /error before the service down page is shown.
    // making /error it the last user location, needs overide to return to dashboard.
    if (location.pathname === '/error') navigate('/dashboard');

    window.location.reload();
  };

  const handleContactUs = () => {
    window.open(externalLinks.absContactUs)?.focus();
    recordRumCustomEventWithPageId(RumCustomEvent.contactUsClicked, getRumAttributes());
  };

  return (
    <View className="container">
      <Menu />
      <Flex className="content">
        <Flex className="modal">
          <Image src={ABSLogo} alt="Australian Bureau of Statistics Logo" />
          <Heading level={1}>ABS Business Reporting is unavailable</Heading>
          <Text>Please try again later</Text>
          <Flex className="button-group">
            <Button variation="link" onClick={handleContactUs}>
              Help
            </Button>
            <Button variation="primary" onClick={handlePress} className="primary-button-radii-override">
              Reload page
            </Button>
          </Flex>
        </Flex>
      </Flex>
    </View>
  );
};
