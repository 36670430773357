import { ReactiveVar, useReactiveVar } from '@apollo/client';

/**
 * Reactive variables will trigger on every update, even if the value is identical.
 * Sometimes we want to avoid doing all the work and re-checking when the new value is
 * the same as the previous value.
 *
 * This wrapper will return an update function that will check the current value of
 * the wrapped rv is different (according to ===) before updating it.
 *
 * THIS DOES NOT RETURN A REACTIVE VARIABLE! Just an update function that will update
 * it if the given value is different from the existing one.
 */
const getReactiveVarIdempotentUpdate =
  <T>(rv: ReactiveVar<T>) =>
  (newVal: T) => {
    if (newVal !== rv()) rv(newVal);
  };

/**
 * Get a set of wrappers to expose a reactive variable as read-only so that only
 * the owning module can update them.
 *
 * @param rv the reactive variable to wrap
 * @returns [readRV, useRV, updateRV] (array with 3 functions):
 *   readRV: call to get the current RV value, the same as calling the RV with no arguments,
 *           but does not accept arguments to update the RV and cannot be used with useReactiveVar.
 *   useRV: a hook that returns the reactive variable value, equivalent of useReactiveVar(rv).
 *   updateRV: idempotent update function for the RV, call with the new value and it will update
 *             the RV unless it already has that value. Use this instead of calling the RV directly
 *             when you want to avoid triggering updates for identical values.
 */
export const readonlyRvUtils = <T>(rv: ReactiveVar<T>) =>
  [() => rv(), () => useReactiveVar(rv), getReactiveVarIdempotentUpdate(rv)] as const;
