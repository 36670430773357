/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { SavedActionListNumeric, SavedActionNumber, SavedActionText, SavedReportDataItem } from 'models';

export const getSavedAnswerCount = (savedAnswers: SavedReportDataItem[]): number => {
  let count = 0;
  if (savedAnswers.length === 0) return count;
  savedAnswers.forEach((answer) => {
    // TODO: exclude answers with ASPFeedback topic, they don't count as part of the form
    if (
      answer.action?.match(/LIST/) &&
      (answer.savedReportDataItem as SavedActionListNumeric).selectedReportDataItems.length !== 0 &&
      (answer.savedReportDataItem as SavedActionListNumeric).selectedReportDataItems.every((x) => x.userValue !== '')
    )
      return (count += 1);
    if (answer.action?.match(/NUMBER/) && (answer.savedReportDataItem as SavedActionNumber).savedNumber !== undefined)
      return (count += 1);
    if (answer.action?.match(/TEXT/) && (answer.savedReportDataItem as SavedActionText).savedText !== '')
      return (count += 1);
  });
  return count;
};
