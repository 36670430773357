import useCurrentReportSteps, { StepID } from 'apollo/states/utils/useCurrentReportSteps';
import ReportButtonGroup from '../ReportButtonGroup/ReportButtonGroup';

interface ReportButtonAutoGroupProps {
  currentStep: StepID;
}

const ReportButtonAutoGroup = ({ currentStep }: ReportButtonAutoGroupProps) => {
  const steps = useCurrentReportSteps();
  const currentIndex = steps.findIndex((step) => step.id === currentStep);

  const nextButton = {
    text: currentStep === 'instructions' ? 'Continue' : 'Save and continue',
    link: steps[currentIndex + 1].link,
    useSave: currentStep !== 'instructions',
    showIcon: true,
  };

  const exitButton = {
    text: currentStep === 'instructions' ? 'Exit' : 'Save and exit',
    link: '/dashboard',
    useSave: currentStep !== 'instructions',
    showIcon: true,
  };

  return <ReportButtonGroup nextButton={nextButton} exitButton={exitButton} showSyncButton />;
};

export default ReportButtonAutoGroup;
