import { externalLinks } from 'lib/externalLinks';

export const DSPwelcomepageprop = {
  list: [
    'If your business uses Xero, MYOB or QuickBooks, connect to your accounting software to save time.',
    'Information from your accounting software will be shown on screen to help you complete your survey quickly and easily.',
  ],
  uniqueId: 'welcome',
};

export const DSPabsusedatapageprop = {
  style: 'card',
  heading: 'Why connect to accounting software?',
  list: [
    'Connecting to accounting software will save you time.',
    'We will show your accounting data on the screen so you can choose what to include for each question. A total will be calculated automatically.',
    'Only the total calculated for each question is submitted to and used by the ABS.',
    'The information you provide will remain strictly confidential to the ABS. It is collected under the Census and Statistics Act 1905. This means we are legally required to protect your data and maintain its confidentiality.',
    'We will not access personal information like employee details or tax file numbers (TFNs)',
    'You can revoke ABS access to your accounting software at any time',
  ],
  linkContact: externalLinks.absContactUs,
  footer: {
    text: 'If you want to know more about how we use your data visit our Privacy at the ABS and ABS Business Reporting Data Usage pages', // THIS TEXT
    link: {
      url: externalLinks.absDataPrivacySecurity,
      text: 'Privacy at the ABS', // If you change this text make sure you change the text above to match
    },
    linkTwo: {
      url: externalLinks.absBusinessReportingDataUsage,
      text: 'ABS Business Reporting Data Usage', // If you change this text make sure you change the text above to match
    },
  },
  uniqueId: 'usage',
};
