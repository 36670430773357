import MyobLogo from 'assets/logo-myob-sm.svg';
import XeroLogo, { ReactComponent as XeroLogoSvg } from 'assets/logo-xero.svg';
import QuickBooksLogo, { ReactComponent as QuickBooksLogoSvg } from 'assets/dsp/icon-quickbooks.svg';
import QuickBooksIntuitLogo from 'assets/dsp/icon-quickbooks-intuit.svg';
import NoDspLogo, { ReactComponent as NoDspLogoSvg } from 'assets/logo-no-dsp.svg';
import { ReactComponent as NoDspManageLogoSvg } from 'assets/logo-no-dsp-manage.svg';

// For ReportListHeader
import { ReactComponent as MyobLogoSvg } from 'assets/logo-myob.svg';
import MyobLogoPng from 'assets/logo-myob.png';
import { Image, Link } from '@aws-amplify/ui-react';

// Index of all DSPs, with DSP-specific data

export type DspId = 'MYOB' | 'XERO' | 'QUICKBOOKS' | 'NODSP';

interface DspFlags {
  showDspBusinessName: boolean;
  showBusinessDisconnectMessaging: boolean;
  isUsingDspProvider: boolean;
}

interface DspInfoShape extends DspFlags {
  id: DspId;
  displayName: string;
  // for order on login page
  position: number;
  iconSrc: string;
  // TODO: consider deriving logo icon alt text from displayName
  iconAltText: string;
  // TODO: see if addFromIcon and manageConnectionsIcon can be standardised elements so just logo can pass through
  addFromIcon: JSX.Element | null;
  addFromModalIcon: string;
  manageConnectionsIcon: JSX.Element | null;
  authoriseDspIcon: React.FunctionComponent<React.SVGProps<SVGSVGElement> & { title?: string | undefined }>;
  // TODO: set up types to make required when showBusinessDisconnectMessaging is true, if possible
  businessDisconnectMessagingContent?: JSX.Element;
}

type DspInfo = Readonly<DspInfoShape>;

// TODO: enforce key to match id in the data structure, or remove id from structure
type DspIndex = Readonly<Record<DspId, DspInfo>>;

// TODO: consider DSP logo component that can render any of the variations needed to de-duplicate the icon properties

// Default false flags to include so that only true flags need be included
const DEFAULT_FLAGS: DspFlags = {
  showDspBusinessName: false,
  showBusinessDisconnectMessaging: false,
  isUsingDspProvider: true,
};

const DSP_INFOS: DspIndex = {
  XERO: {
    id: 'XERO',
    displayName: 'Xero',
    position: 0,
    iconSrc: XeroLogo,
    iconAltText: 'logo of Xero',
    addFromIcon: <XeroLogoSvg height={24} data-testid="add-from-logo" />,
    addFromModalIcon: XeroLogo,
    manageConnectionsIcon: <XeroLogoSvg height={32} width={32} />,
    authoriseDspIcon: XeroLogoSvg,
    ...DEFAULT_FLAGS,
    // Override flags below here
    showDspBusinessName: true,
  },
  MYOB: {
    id: 'MYOB',
    displayName: 'MYOB',
    position: 1,
    iconSrc: MyobLogo,
    iconAltText: 'logo of MYOB',
    // Using png for MYOB logo due to inclusion of gradients in svg version
    // which causes duplicate id when there are multiple instances
    addFromIcon: <Image alt="" src={MyobLogoPng} height="15px" />,
    addFromModalIcon: MyobLogoPng,
    manageConnectionsIcon: <img src={MyobLogoPng} alt="myob company logo" height="auto" width={32} />,
    authoriseDspIcon: MyobLogoSvg,
    ...DEFAULT_FLAGS,
    // Override flags below here
    showBusinessDisconnectMessaging: true,
    businessDisconnectMessagingContent: (
      <p className="dialog-text">
        Your MYOB account will still show a connection to ABS Business Reporting. To fully disconnect visit{' '}
        <Link href="https://secure.myob.com" isExternal>
          secure.myob.com
        </Link>
        . Doing this will disconnect all businesses associated with your MYOB account.
      </p>
    ),
  },
  QUICKBOOKS: {
    id: 'QUICKBOOKS',
    displayName: 'QuickBooks',
    position: 2,
    iconSrc: QuickBooksLogo,
    iconAltText: 'logo of QuickBooks',
    addFromIcon: <QuickBooksLogoSvg height={24} data-testid="add-from-logo" />,
    addFromModalIcon: QuickBooksIntuitLogo,
    authoriseDspIcon: QuickBooksLogoSvg,
    manageConnectionsIcon: <QuickBooksLogoSvg height={32} width={32} />,
    ...DEFAULT_FLAGS,
    // Override flags below here
  },
  NODSP: {
    id: 'NODSP',
    displayName: '',
    position: 4,
    iconSrc: NoDspLogo,
    iconAltText: 'logo of no dsp',
    addFromIcon: null,
    addFromModalIcon: '',
    authoriseDspIcon: NoDspLogoSvg,
    manageConnectionsIcon: <NoDspManageLogoSvg height={26} width={26} />,
    ...DEFAULT_FLAGS,
    // Override flags below here
    isUsingDspProvider: false,
  },
} as const;

// Default is built up from default cases in the code when centralising DSP info.
// Notes show where defaults were pulled from
const DEFAULT_DSP: DspInfo = {
  // BusinessSelectMenuItem used Xero logo when DSP did not match xero or myob
  // so Xero is used as basis default
  ...DSP_INFOS.XERO,
  addFromIcon: null,
  // Existing default in getDspDisplayName
  displayName: '',
  addFromModalIcon: '',
  // Existing default in ManageConnections
  manageConnectionsIcon: <img alt="" />,
};

// Regex that will get the DSP id (case insensitive) from the front of any string
// capture group 1 is the matched DSP id
const dspIdExtractRegex = new RegExp(`^(${Object.keys(DSP_INFOS).join('|')})`, 'i');

const extractDspId = (dspString: string | undefined) =>
  dspString?.match(dspIdExtractRegex)?.[1]?.toUpperCase() as DspId;

export const getDsp = (dspString: string | undefined) => DSP_INFOS[extractDspId(dspString)] ?? DEFAULT_DSP;
export const getAllDsps = () => Object.values(DSP_INFOS).sort((a, b) => a.position - b.position);
