/**
 * set the current focus of the page to the id of the target element
 * @param id the id of the target HTML element
 */
export const setFocus = (id: string) => {
  const container: HTMLElement | null = document.getElementById(id) ?? document.querySelector('main');

  if (container) {
    container.tabIndex = -1;
    container.focus();
    setTimeout(() => container.removeAttribute('tabindex'), 1000);
  }
};
