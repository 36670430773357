import { Heading } from '@aws-amplify/ui-react';

import DashboardLayout from 'components/DashboardLayout/DashboardLayout';
import ManageConnections from 'components/ManageConnections/ManageConnections';

import './ManageConnectionsPage.css';

const ManageConnectionsPage = () => (
  <DashboardLayout>
    <div id="skipTarget" />
    <div className="manage-connections-page-main-wrapper">
      <Heading className="manage-connections-page-main-heading" testId="manage-connections-page-heading" level={1}>
        Manage connections
      </Heading>

      <ManageConnections />
    </div>
  </DashboardLayout>
);

export default ManageConnectionsPage;
