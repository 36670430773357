import { CurrentReportData } from 'models';
import makeVarPersisted from 'apollo/MakeVarPersisted';
import { mockDefaultTopics } from 'testutils/defaultTopicRepresentations';

export const CurrentReportDataInitialState: CurrentReportData = {
  dataItems: [],
  dspDataItems: [],
  topics: Object.values(mockDefaultTopics),
};

export const currentReportData = makeVarPersisted<CurrentReportData>(
  CurrentReportDataInitialState,
  'aspCurrentReportData'
);
