import './Menu.css';
import { useCallback, useEffect, useState } from 'react';
import {
  Flex,
  Grid,
  Menu,
  MenuButton,
  MenuItem,
  Text,
  View,
  Link,
  Loader,
  useBreakpointValue,
  VisuallyHidden,
  Button,
} from '@aws-amplify/ui-react';
import { NavLink, useLocation } from 'react-router-dom';
import { useOktaAuth } from '@okta/okta-react';
import { useSaveReport } from 'utils/useSaveReport';
import { externalLinks } from 'lib/externalLinks';
import { doLogout } from 'utils/doLogout';
import { recordRumCustomEvent, recordRumCustomEventWithPageId } from 'services/awsRum';
import { isCurrentReportFullySaved, isCurrentReportSaveable } from 'services/SaveReportManager/currentReportStatus';
import { backgroundSaveCurrentReport } from 'services/SaveReportManager/reportSaveUtil';
import { getRumAttributes } from 'utils/getRumAttributes';
import { useUserInfo } from 'lib/userInfoHook';
import { RumCustomEvent } from 'enums/RumCustomEvent';

import { ReactComponent as ABSLogo } from 'assets/logo-ABS.svg';
import { ReactComponent as UserIcon } from 'assets/icon-user.svg';
import { ReactComponent as OpenMenuIconSvg } from 'assets/icon-menu-open.svg';
import { useReactiveVar } from '@apollo/client';
import { authorisedBusinesses } from 'apollo/states/AuthorisedBusinesses';
import { toggleShowSideNav } from 'utils/getShowSideNav';
import { gridBreakPoints } from 'utils/gridBreakPoints';
import { useSetReportLock } from 'utils/useSetReportLock';
import { isCurrentReportLocked } from 'apollo/states/isCurrentReportLocked';
import { PageRoutes } from 'enums/PageRoutes';

/**
 * Various amplify default behaviours cause these items to still be focusable
 * (despite aria-hidden being set to true) when the menu is opened.
 * This is a serious ARIA defect, and we must manually fix them here.
 */
const updateAriaFocus = (open: boolean) => {
  const skipLink = document.querySelector('.skip-link');
  const spans = document.querySelectorAll('span[data-aria-hidden="true"]');
  const main = document.querySelector('main.main-wrapper');
  if (skipLink) {
    if (open) {
      skipLink.setAttribute('disabled', 'true');
      skipLink.setAttribute('aria-hidden', 'true');
    } else {
      skipLink.setAttribute('disabled', 'false');
      skipLink.setAttribute('aria-hidden', 'false');
    }
  }
  if (spans.length) {
    if (open) {
      spans.forEach((span) => {
        span.setAttribute('aria-disabled', 'true');
        span.setAttribute('aria-hidden', 'true');
        span.setAttribute('tabindex', '-1');
      });
    } else {
      spans.forEach((span) => {
        span.setAttribute('aria-disabled', 'false');
        span.setAttribute('aria-hidden', 'false');
        span.setAttribute('tabindex', '0');
      });
    }
  }
  if (main) {
    if (open) {
      main.setAttribute('aria-disabled', 'true');
      main.setAttribute('aria-hidden', 'true');
      main.setAttribute('tabindex', '-1');
    } else {
      main.setAttribute('aria-disabled', 'false');
      main.setAttribute('aria-hidden', 'false');
      main.setAttribute('tabindex', '0');
    }
  }
};

const showLeftNavList: string[] = ['dashboard', 'manage-connections', 'industry-insights'];

const loggedInMenuList: string[] = [
  'dashboard',
  'report',
  'select-business',
  'manage-connections',
  'authorisation',
  'claim-obligation',
  'manual-authorisation',
  'industry-insights',
];
const loggedInNavList: string[] = ['report', 'dashboard'];

const HeaderMenu = () => {
  const location = useLocation();
  const { pathname } = location;
  const showUserNav: boolean = loggedInNavList.indexOf(location.pathname.replace(/^\/+/, '').split('/')[0]) >= 0;
  const showUserMenu: boolean = loggedInMenuList.indexOf(location.pathname.replace(/^\/+/, '').split('/')[0]) >= 0;
  const showLeftNav: boolean = showLeftNavList.indexOf(location.pathname.replace(/^\/+/, '').split('/')[0]) >= 0;
  const onDashboard: boolean = location.pathname.replace(/^\/+/, '').split('/')[0] === 'dashboard';
  const userInfo = useUserInfo();
  const { oktaAuth } = useOktaAuth();
  const [saveReport, saveReportResponse] = useSaveReport('HeaderMenu');
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [logoutClicked, setLogoutClicked] = useState(false);
  const businesses = useReactiveVar(authorisedBusinesses);
  const businessName = businesses.activeBusiness?.name;
  const unlockReport = useSetReportLock('unlock');
  const lockReport = useSetReportLock('lock');
  const isReportLocked = useReactiveVar(isCurrentReportLocked);

  const handleDashboardClick: React.MouseEventHandler<HTMLAnchorElement> = () => {
    if (!onDashboard) {
      recordRumCustomEvent(RumCustomEvent.reportClose, getRumAttributes());

      // Save before wiping out the report state if needed.
      if (isCurrentReportSaveable() && !isCurrentReportFullySaved()) {
        backgroundSaveCurrentReport({
          component: 'HeaderMenu',
          callSite: 'handleDashboardClick',
          triggeredBy: 'user clicked Dashboard',
          triggerLocation: window.location.pathname,
        });
      }
    }
  };

  const isReportPage = pathname.split('/')[1] === 'report';
  const reportPage = String(pathname.split('/').at(-1));
  const isConfirmationPage = reportPage === PageRoutes.ReportConfirmation.routePath;
  const shouldReportBeLocked = isReportPage && !isConfirmationPage;

  useEffect(() => {
    if (shouldReportBeLocked) lockReport();
    else unlockReport();
    // We do not want to rerun this when unlockReport updates
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldReportBeLocked]);

  // TODO: Because we have limitations for handling browser closing, we will not be doing 'event.preventdefault'
  // This implementation should still handle changes when a user navigates to a different site
  const handleCloseBrowser = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    (_event: BeforeUnloadEvent) => {
      recordRumCustomEvent(RumCustomEvent.windowClose, getRumAttributes());
      unlockReport();

      // It would be better to delay to closing the browser until the report is unlocked,
      // but we do not track that state at the moment and
      // it will be too much work to track the lock state at the moment,
      // so we just assume the unlock will work and don't delay closing the browser
    },
    [unlockReport]
  );

  useEffect(() => {
    if (shouldReportBeLocked && isReportLocked) {
      window.addEventListener('beforeunload', handleCloseBrowser);

      // Cleanup detaches handler whenever the report is in an unlocked state
      // see https://developer.mozilla.org/en-US/docs/Web/API/Window/beforeunload_event )
      return () => {
        window.removeEventListener('beforeunload', handleCloseBrowser);
      };
    }
  }, [isReportLocked, shouldReportBeLocked, handleCloseBrowser]);

  // Continue operations initiated by save
  useEffect(() => {
    // Only proceed if saveReport is complete
    // User is already leaving, so treat errors as unrecoverable
    const { success, error } = saveReportResponse;
    if (!(success || error)) return;

    // Note: could add a RUM log for error state here, since it represents failing to save
    //       all data before closing the report.

    if (logoutClicked) {
      setLogoutClicked(false);
      unlockReport();
      doLogout(oktaAuth);
    }

    // Intentionally only running on completed save, not when links become clicked
    // (results from a prior save would trigger behaviour when not desired)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saveReportResponse.success, saveReportResponse.error]);

  const handleMenuOpenChange = (open: boolean) => {
    setMenuIsOpen(open);
    updateAriaFocus(open);
  };

  const HandleLogout = () => {
    // if user in report and it has unsaved changes that can be saved, delay logout to save it
    if (showUserNav && !onDashboard && isCurrentReportSaveable() && !isCurrentReportFullySaved()) {
      saveReport('HandleLogout', 'user clicked user menu > Log out', false);
      // Delay actual logout until save has completed, or save will fail
      setLogoutClicked(true);
      return;
    }

    unlockReport();
    doLogout(oktaAuth);
  };

  const handleContactUs = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();
    window.open(`${externalLinks.absContactUs}`, '_blank');
    setMenuIsOpen(false);
    recordRumCustomEventWithPageId(RumCustomEvent.contactUsClicked, getRumAttributes());
  };

  const handleUserName = () => {
    const lengthToCut = 46;
    const text = `${userInfo?.firstName} ${userInfo?.lastName}`;
    let username = text;
    if (text.length > lengthToCut) {
      username = text?.substr(0, lengthToCut);
      username += '...';
    }
    return username;
  };

  // Hold menu open if report is saving before doing logout
  const menuOpen = menuIsOpen || logoutClicked;

  const columnStart = useBreakpointValue({
    base: 2,
  });

  const columnEnd = useBreakpointValue({
    base: 4,
    medium: 8,
  });

  const columnStartLogin = useBreakpointValue({
    base: 11,
    small: 10,
  });

  const columnEndLogin = useBreakpointValue({
    base: -2,
  });

  const handleToggleSideNav = () => {
    toggleShowSideNav();
  };

  return (
    <header
      className="menu-outer-wrapper"
      data-testid="menu-outer-wrapper"
      style={{ borderBottom: '4px solid #326297' }}
    >
      <Grid
        className="container-grid menu-wrapper"
        templateColumns={useBreakpointValue(gridBreakPoints as Record<string, unknown>) as string}
        alignItems="center"
        maxWidth="100vw"
      >
        <View columnSpan={{ base: 7 }} columnStart={columnStart as number} columnEnd={columnEnd as number}>
          <Flex justifyContent="flex-start" alignItems="center" paddingLeft="0rem">
            {showLeftNav && (
              <Button className="open-menu-button" id="menu-button-focus-target" onClick={handleToggleSideNav}>
                <OpenMenuIconSvg />
              </Button>
            )}
            <View
              ariaLabel="Australian Bureau of Statistics Logo"
              width="62px"
              height="55px"
              testId="nav-menu-logo"
              className="absLogo"
            >
              <NavLink
                style={{ textDecoration: 'none' }}
                to={showUserMenu ? '/dashboard' : '/'}
                data-testid="menu-home"
                onClick={handleDashboardClick}
              >
                <VisuallyHidden>Australian Bureau of Statistics Logo</VisuallyHidden>
                <ABSLogo />
              </NavLink>
            </View>
            <NavLink
              style={{ textDecoration: 'none' }}
              to={showUserMenu ? '/dashboard' : '/'}
              data-testid="menu-home"
              onClick={handleDashboardClick}
            >
              <Text
                textDecoration="none"
                as="span"
                display={{
                  base: 'block',
                  medium: 'inline',
                }}
                fontSize={{
                  base: '12px',
                  medium: '20px',
                }}
                lineHeight={{
                  base: '1',
                  medium: 'normal',
                }}
                marginTop={{
                  base: '2px',
                  medium: '0px',
                }}
                marginBottom={{
                  base: '0px',
                }}
                fontWeight="700"
                testId="nav-menu-heading"
              >
                ABS Business Reporting
              </Text>
            </NavLink>
          </Flex>
        </View>

        {showUserMenu ? (
          <View className="menu-right" columnStart={columnStartLogin as number} columnEnd={columnEndLogin as number}>
            {userInfo && (
              <Menu
                menuAlign="end"
                isOpen={menuOpen}
                onOpenChange={handleMenuOpenChange}
                trigger={
                  <MenuButton
                    borderRadius="medium"
                    className={menuOpen ? 'name-menu-open' : 'name-menu'}
                    padding="0rem 0rem"
                    marginRight={{ base: '0rem' }}
                    gap={{ base: '0px', medium: '8px' }}
                    testId="account-button"
                  >
                    <View className="user-name-and-business-name">
                      <Text
                        style={{
                          fontSize: '16px',
                          fontWeight: '600',
                          lineHeight: '24px',
                          textAlign: 'right',
                          color: '#002749',
                        }}
                        display={{ base: 'none', medium: 'inline' }}
                      >
                        {' '}
                        {handleUserName()}
                      </Text>
                      <Text
                        style={{
                          fontSize: '12px',
                          fontWeight: '400',
                          lineHeight: '16px',
                          textAlign: 'right',
                          letterSpacing: '0.02em',
                          color: '#5C6670',
                        }}
                        display={{ base: 'none', medium: 'inline' }}
                      >
                        {businessName}
                      </Text>
                    </View>
                    <View testId="user-icon" ariaLabel="user-icon" className="user-icon">
                      <Flex
                        className="user-icon"
                        direction="column"
                        alignItems="center"
                        justifyContent="center"
                        padding="0px"
                        margin="0px"
                        gap="0px"
                      >
                        <UserIcon />
                        <Text
                          testId="menu-user-name"
                          fontSize="12px"
                          color="#0F487C"
                          padding="none"
                          margin="none"
                          display={{ base: 'inline', medium: 'none' }}
                        >
                          Account
                        </Text>
                      </Flex>
                    </View>
                  </MenuButton>
                }
              >
                <MenuItem display={{ base: 'flex', medium: 'none' }} borderRadius="small" className="menuName">
                  <Text
                    style={{
                      fontSize: '16px',
                      fontWeight: '400',
                      lineHeight: '24px',
                      textAlign: 'left',
                      color: '#0D1926',
                    }}
                  >
                    {' '}
                    {handleUserName()}
                  </Text>
                  <Text
                    style={{
                      fontSize: '12px',
                      fontWeight: '400',
                      lineHeight: '16px',
                      textAlign: 'left',
                      letterSpacing: '0.02em',
                      color: '#5C6670',
                    }}
                  >
                    {businessName}
                  </Text>
                </MenuItem>
                <MenuItem onClick={handleContactUs} borderRadius="small" className="contact-us-card">
                  <Link
                    isExternal
                    href={externalLinks.absContactUs}
                    data-testid="dashboard-contact-us"
                    className="contact-us"
                  >
                    Contact us
                  </Link>
                </MenuItem>
                <MenuItem onClick={HandleLogout} borderRadius="small" className="log-out-card-alert">
                  <Text data-testid="dashboard-log-out" className="log-out-alert">
                    Log out
                    {logoutClicked && <LayoutPreservingLoader />}
                  </Text>
                </MenuItem>
              </Menu>
            )}
          </View>
        ) : (
          <View
            className="menu-right"
            position="relative"
            columnStart={columnStartLogin as number}
            columnEnd={columnEndLogin as number}
            onClick={() => oktaAuth.signInWithRedirect({ originalUri: '/authorisation' })}
          >
            <Flex
              direction={{
                base: 'row',
                medium: 'row',
              }}
              gap={{ base: '0px', medium: '8px' }}
              paddingRight={{
                base: '0rem',
              }}
              alignItems="center"
              justifyContent="flex-end"
            >
              <View testId="user-icon" ariaLabel="user-icon" className="user-icon">
                <Text
                  testId="menu-user-name"
                  className="username"
                  color="#0F487C"
                  fontSize={{
                    base: '12px',
                    medium: '16px',
                  }}
                  margin="0"
                  padding="0"
                  lineHeight="1"
                >
                  Log in
                </Text>
                <UserIcon />
              </View>
            </Flex>
          </View>
        )}
      </Grid>
    </header>
  );
};
export default HeaderMenu;

/**
 * Quick inline loader that does not affect layout (takes up no space)
 * @returns
 */
function LayoutPreservingLoader() {
  return (
    <span
      style={{
        display: 'inline-block',
        position: 'relative',
        width: 0,
        height: 0,
      }}
    >
      <Loader position="absolute" left="0.5em" bottom="0" aria-label="Saving changes" />
    </span>
  );
}
