import { ReactNode } from 'react';
import { Grid, View, useBreakpointValue } from '@aws-amplify/ui-react';

import DashboardSideNav from 'components/DashboardSideNav/DashboardSideNav';

import './DashboardLayout.css';
import { gridBreakPoints } from 'utils/gridBreakPoints';

interface DashboardLayoutProps {
  children?: ReactNode;
}
const DashboardLayout = ({ children }: DashboardLayoutProps) => (
  <Grid
    className="container-grid"
    templateColumns={useBreakpointValue(gridBreakPoints as Record<string, unknown>) as string}
    maxWidth="100vw"
  >
    <View columnStart="2" columnEnd={{ base: '6', xl: '5' }}>
      <DashboardSideNav />
    </View>

    <View className="dashboard-container-right" columnStart={{ base: '2', medium: '6' }} columnEnd={{ base: '14' }}>
      {children}
    </View>
  </Grid>
);

export default DashboardLayout;
