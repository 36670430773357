import { MutationTuple, OperationVariables, useMutation } from '@apollo/client';
import { recordRumError } from 'services/awsRum';
import { SET_CONTACT_DETAILS } from 'apollo/mutations/setContactDetails';
import { SaveContactDataObjectResponse } from 'models';

const useSetContactDetails = (): MutationTuple<SaveContactDataObjectResponse, OperationVariables> => {
  const [callSetContactDetails, setContactDetailsResponse] = useMutation<SaveContactDataObjectResponse>(
    SET_CONTACT_DETAILS,
    {
      fetchPolicy: 'network-only',
      onError: (error) => recordRumError(error),
    }
  );

  return [callSetContactDetails, setContactDetailsResponse];
};

export default useSetContactDetails;
