import { useState } from 'react';
import { Flex, Text, TextField, View } from '@aws-amplify/ui-react';
import { useReactiveVar } from '@apollo/client';

import { DataItem, SavedReportDataItem, SavedActionNumber } from 'models';
import { savedReportDataItems } from 'apollo/states/SavedReportDataItem';
import { latestSavedReportDataItems } from 'apollo/states/LatestSavedReportDataItems';
import { chosenReport } from 'apollo/states/ChosenReport';
import { TooltipComponent } from 'components/TooltipComponent/TooltipComponent';

import { ReactComponent as IconInfoWarnSvg } from 'assets/icon-info-warn.svg';

import './ReportNumber.css';

const regex = /\D/;

const defaultLabel = 'Type the number below';

interface ReportNumberProps {
  dataItem: DataItem;
  helpTexts?: string[];
  itemText?: string;
  label?: string;
  minified?: boolean;
}
const ReportNumber = ({ dataItem, helpTexts, itemText, label, minified }: ReportNumberProps) => {
  const savedValues = useReactiveVar(savedReportDataItems);
  const serverValues = useReactiveVar(latestSavedReportDataItems);

  const savedValue: SavedReportDataItem | undefined = savedValues.savedAnswers.find(
    (x) => x.dataItemId === dataItem.dataItemId
  );
  const savedInServer = serverValues.savedAnswers.find((x) => x.dataItemId === dataItem.dataItemId);

  const [hasError, setHasError] = useState<boolean>(false);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;

    if (regex.test(value) || Number.isNaN(value)) {
      setHasError(true);
      return;
    }

    setHasError(false);

    const responseUid = savedValue
      ? (savedValue?.savedReportDataItem as SavedActionNumber).responseUid
      : `ASP-${crypto.randomUUID()}`;

    const savedActionNumber: SavedActionNumber = {
      savedNumber: Number.isNaN(parseInt(value)) ? undefined : parseInt(value),
      responseUid,
    };

    if (savedValue === undefined) {
      // TODO: figure out if calculated or not
      const newReportDataItem: SavedReportDataItem = {
        dataItemId: dataItem.dataItemId,
        isCalculated: false,
        savedReportDataItem: savedActionNumber,
        topic: dataItem.topic,
        accountClass: dataItem.accountClass || '',
        overwrite: false,
        action: dataItem.action,
      };
      savedReportDataItems({
        savedAnswers: [...savedValues.savedAnswers, newReportDataItem],
      });
    } else if (value === '' && !savedInServer) {
      // remove the item from saved answers
      const excludedSavedReportDataItems = savedValues.savedAnswers.filter(
        (i: SavedReportDataItem) => i.dataItemId !== savedValue.dataItemId
      );
      savedReportDataItems({ savedAnswers: excludedSavedReportDataItems });
    } else {
      const updatedSavedReportDataItems = savedValues.savedAnswers.map((i: SavedReportDataItem) =>
        i.dataItemId === dataItem.dataItemId ? { ...i, savedReportDataItem: savedActionNumber } : i
      );

      savedReportDataItems({ savedAnswers: updatedSavedReportDataItems });
    }
  };

  const [inputFocused, setInputFocused] = useState(false);
  const chosenReportDetails = chosenReport();
  const isNoDsp = chosenReportDetails.isUsingDsp === false;

  const showWarning =
    chosenReportDetails.returningUser &&
    (savedValue?.savedReportDataItem as SavedActionNumber)?.savedNumber === undefined &&
    !inputFocused;

  const errorMessage = 'Whole numbers only';

  if (minified) {
    return (
      <Flex
        direction="column"
        justifyContent="space-between"
        alignItems="left"
        {...(isNoDsp && { paddingLeft: '32px' })}
      >
        <Flex justifyContent="space-between">
          <Text fontWeight={600} className="report-number-minified-question" testId="report-number-minified-question">
            {itemText ?? dataItem.dataItemText}
          </Text>
          {showWarning && <IconInfoWarnSvg className="review-warning-icon" style={{ flex: 'none' }} />}
        </Flex>
        {isNoDsp ? (
          <Text fontWeight={600} textAlign="end">
            {(savedValue?.savedReportDataItem as SavedActionNumber)?.savedNumber ?? 'No response provided'}
          </Text>
        ) : (
          <TextField
            type="text"
            label={label || defaultLabel}
            labelHidden
            value={(savedValue?.savedReportDataItem as SavedActionNumber)?.savedNumber ?? ''}
            onChange={handleChange}
            className="report-number-input report-input-field"
            errorMessage={errorMessage}
            testId="report-number-input"
            hasError={hasError}
            pattern="^\d$"
            maxLength={12}
            onFocus={() => setInputFocused(true)}
            textAlign={minified ? 'left' : ''}
          />
        )}
      </Flex>
    );
  }

  return (
    <View className="number-wrapper">
      <TooltipComponent
        text={String(itemText) ?? dataItem.dataItemText}
        helpTexts={helpTexts}
        headingLevel={2}
        alwaysDisplay
        dataItemId={dataItem.dataItemId}
      />
      <TextField
        type="text"
        label={label || defaultLabel}
        value={(savedValue?.savedReportDataItem as SavedActionNumber)?.savedNumber ?? ''}
        onChange={handleChange}
        className="report-number-input report-input-field"
        errorMessage={errorMessage}
        testId="report-number-input"
        hasError={hasError}
        pattern="^\d$"
        maxLength={12}
      />
    </View>
  );
};

export default ReportNumber;
