import { TopicKey } from 'models';

import 'pages/Report/ReportPage.scss';
import useCurrentReportTopics from 'apollo/states/utils/useCurrentReportTopics';
import { getTopicByKey } from 'models/LocalState/TopicFullDetail';
import { useParams } from 'react-router-dom';
import ErrorPage from 'pages/ErrorPage/ErrorPage';
import { ErrorPageStatus } from 'enums/ErrorPageStatus';
import { ReportTopic } from './ReportTopic';

// Common display logic for all the "standard" topics that are just a heading and the topic questions
export const GeneralReportTopic = () => {
  const { topic } = useParams();
  const topicKey = topic as TopicKey;
  const topics = useCurrentReportTopics();

  if (!topic) {
    // copied from 404 route, could do this another way
    return <ErrorPage selectedError={ErrorPageStatus.NotFound404} />;
  }

  const topicDetail = getTopicByKey(topics, topicKey);

  if (!topicDetail) {
    return <ErrorPage selectedError={ErrorPageStatus.NotFound404} />;
  }

  return <ReportTopic topicFullDetail={topicDetail} key={topic} />;
};
