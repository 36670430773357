import { Flex, Heading, Text, View } from '@aws-amplify/ui-react';
import 'components/DetailedTopic/DetailedTopicComponent.css';
import { Link } from 'react-router-dom';

import { currencyFormat } from 'utils/CurrencyFormat';
import { ReactComponent as IconEditPencil } from 'assets/icon-pencil.svg';

interface DetailedTopicComponentProps {
  link?: string;
  title: string;
  total?: number;
  children?: JSX.Element;
}

const DetailedTopicComponent = ({ link, title, total, children }: DetailedTopicComponentProps) => (
  <div data-testid={`${title}-topic-component`} className="detailed-topic-component">
    <Flex testId={`${title}-topic-title`} className="detailed-topic-header">
      <Heading level={2} className="detailed-topic-title-text" testId="detailed-topic-title">
        {title}
      </Heading>
      <Flex direction="column" alignItems="end" justifyContent="start" gap="8px" marginTop="32px">
        {total !== undefined && (
          <View className="detailed-topic-title-total" testId="detailed-topic-total">
            {currencyFormat.format(total)}
          </View>
        )}
      </Flex>
    </Flex>
    {children}
    {link && (
      <Link to={link} className="detailed-topic-title-link" test-id="detailed-topic-link">
        <Flex alignItems="center" gap="10px">
          <IconEditPencil className="detailed-topic-title-link-icon" aria-label={`Edit ${title}`} />
          <Text>Edit section</Text>
        </Flex>
      </Link>
    )}
  </div>
);

export default DetailedTopicComponent;
