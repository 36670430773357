import { Button, Flex, Text, Card, Heading, HeadingLevel } from '@aws-amplify/ui-react';
import { useState } from 'react';
import { ReactComponent as TooltipDefault } from 'assets/tooltip/tooltip-default.svg';
import { ReactComponent as TooltipHover } from 'assets/tooltip/tooltip-hover.svg';
import { ReactComponent as TooltipSelected } from 'assets/tooltip/tooltip-select.svg';

import './TooltipComponent.scss';
import { getRumAttributes } from 'utils/getRumAttributes';
import { recordRumCustomEventWithPageId } from 'services/awsRum';
import { RumCustomEvent } from 'enums/RumCustomEvent';

// 1. pass in a folder path
// 2. onclick return one or more actions(pass data/ dirct to other page/display or hide text...)
interface Props {
  text: string;
  headingLevel?: HeadingLevel;
  helpTexts?: string[];
  alwaysDisplay?: boolean;
  dataItemId: string;
}

export const TooltipComponent: React.FC<Props> = ({
  text,
  helpTexts,
  headingLevel,
  alwaysDisplay = false,
  dataItemId,
}: Props) => {
  const [displayHelpText, setDisplayHelpText] = useState<boolean>(false);
  const [tooltipIconHover, setTooltipIconHover] = useState<boolean>(false);

  const checkHelpText = () => {
    if (helpTexts === null || helpTexts?.length === 0 || helpTexts === undefined) {
      return false;
    }

    return true;
  };

  const handleTooltipClick = () => {
    setDisplayHelpText(!displayHelpText);
    recordRumCustomEventWithPageId(RumCustomEvent.infoTooltipClicked, {
      tooltipFor: dataItemId,
      ...getRumAttributes(),
    });
  };

  return (
    <Card testId="tooltip-component" className="tooltip-component">
      <Flex gap="6px" justifyContent="space-between">
        {headingLevel ? (
          <Heading level={headingLevel} testId="tooltip-heading" className="heading-text">
            {text}
          </Heading>
        ) : (
          <Text testId="tooltip-heading" className="title-text" style={{ paddingTop: '2rem' }}>
            {text}
          </Text>
        )}

        <Card>
          {checkHelpText() && !alwaysDisplay ? (
            <Button
              aria-label={`information about ${text}`}
              aria-expanded={displayHelpText}
              testId="tooltip-icon"
              className={`${displayHelpText && 'selected'} tooltip-icon`}
              onClick={handleTooltipClick}
              onMouseEnter={() => {
                setTooltipIconHover(true);
              }}
              onMouseLeave={() => {
                setTooltipIconHover(false);
              }}
            >
              <TooltipIcon displayHelpText={displayHelpText} tooltipIconHover={tooltipIconHover} />
            </Button>
          ) : null}
        </Card>
      </Flex>
      <Card className="help-texts-card" display={alwaysDisplay || displayHelpText ? 'block' : 'none'}>
        {helpTexts?.map((helpText, i) => (
          <Text key={i} className="helptext" testId="tooltip-helptexts">
            {helpText}
          </Text>
        ))}
      </Card>
    </Card>
  );
};

const TooltipIcon = ({
  displayHelpText,
  tooltipIconHover,
}: {
  displayHelpText: boolean;
  tooltipIconHover: boolean;
}) => {
  if (displayHelpText) {
    return <TooltipSelected />;
  }
  return tooltipIconHover ? <TooltipHover /> : <TooltipDefault />;
};
