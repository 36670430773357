import { useEffect, useRef } from 'react';
import { Button, Flex, Grid, Heading, TabItem, Tabs } from '@aws-amplify/ui-react';

import { chosenReport } from 'apollo/states/ChosenReport';
import { InstructionCard } from 'components/InstructionCard/InstructionCard';
import ReportButtonAutoGroup from 'components/ReportComponents/ReportButtonAutoGroup/ReportButtonAutoGroup';
import { HorizontalRule } from 'utils/horizontalLine';
import { ReactComponent as ArrowIconSvg } from 'assets/arrow.svg';
import { FetchInstructionsData } from './content';
import type { InstructionData } from './content';

import 'pages/Report/ReportPage.scss';

const InstructionCategory = ({
  category,
  instructionsData,
}: {
  category: string;
  instructionsData: InstructionData[];
}) => {
  const data =
    category === 'all' ? instructionsData : instructionsData.filter(({ categories }) => categories.includes(category));

  return (
    <Grid templateColumns={{ base: '1fr', large: 'repeat(2, 1fr)' }} gap="32px" marginTop="48px">
      {data.map((instruction, i) => (
        <InstructionCard
          title={instruction.title}
          text={instruction.text}
          imgPath={instruction.imgPath}
          altText=""
          key={i}
        />
      ))}
    </Grid>
  );
};

const ReportInstructions = () => {
  const focusRef = useRef<HTMLHeadingElement>(null);

  const chosenReportDetails = chosenReport();
  const { isUsingDsp } = chosenReportDetails;

  const { headings, instructionsData } = FetchInstructionsData(isUsingDsp);

  /* istanbul ignore next */
  useEffect(() => {
    if (focusRef.current != null) {
      focusRef.current.focus();
    }
  }, []);

  return (
    <div data-testid="report-instructions" className="report-instructions">
      <Heading
        level={1}
        ref={focusRef}
        tabIndex={-1}
        className="report-step-header report-step-header-buffer"
        testId="instructions-header"
      >
        Instructions
      </Heading>

      <Tabs className="instructions-tab">
        {headings.map(({ category, title }) => (
          <TabItem title={title} testId={`tab-${category}`} key={category}>
            <InstructionCategory category={category} instructionsData={instructionsData} />
          </TabItem>
        ))}
      </Tabs>
      <div className="back-to-top-button-container">
        <Button
          aria-label="Back to top button"
          className="back-to-top-button"
          onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
        >
          {' '}
          <div style={{ width: '24px' }}>
            <ArrowIconSvg width="16px" height="16px" />
          </div>
          Back to top
        </Button>
      </div>
      <HorizontalRule />
      <Flex direction="column">
        <ReportButtonAutoGroup currentStep="instructions" />
      </Flex>
    </div>
  );
};

export default ReportInstructions;
