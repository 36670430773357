/* istanbul ignore file */
import { useCallback, useEffect, useRef, useState } from 'react';
import { DefaultContext, MutationFunctionOptions, OperationVariables, useMutation } from '@apollo/client';
import { SaveReportResponse } from 'models';
import { SAVE_REPORT } from 'apollo/mutations/saveReport';
import {
  isSubmittingReport,
  savingLocalAnswersStatus,
  savingLocalAnswersToServer,
  syncingDspItems,
} from 'apollo/states/operationsInProgress';
import { savedReportDataItems } from 'apollo/states/SavedReportDataItem';
import { latestSavedReportDataItems } from 'apollo/states/LatestSavedReportDataItems';
import { resetLastSavedTimer } from 'apollo/states/SaveTimer';
import { recordRumError } from 'services/awsRum';
import { acquireSaveReportMutex } from 'services/SaveReportManager/saveReportMutex';
import { getSaveReportOptions, SaveReportOptions } from './getSaveReportOptions';
import { useSetReportLock } from './useSetReportLock';
import { getMergedReportDataItems } from './getUpdatedReportDataItems';

interface BeginSaveArgs {
  callContext: string;
  uiContext: string;
  lockReport?: boolean;
}

export type SaveReportResult = Readonly<{
  called: boolean;
  loading: boolean;
  success: boolean;
  error: boolean;
}>;

// Actual combinations are limited, so we can re-use the same objects (since they are readonly)
const defaultSaveReportResult = { called: false, loading: false, success: false, error: false } as const;
const loadingSaveReportResult = { called: true, loading: true, success: false, error: false } as const;
const successSaveReportResult = { called: true, loading: false, success: true, error: false } as const;
const failureSaveReportResult = { called: true, loading: false, success: false, error: true } as const;

// TODO: add a prop to replace the removed updateLatest prop, that makes save happen in the background with a
//       snapshot of the report state, so save can complete without delaying unloading the current report.
// TODO: cache component name where useSaveReport is used (auto if possible, otherwise pass in) and use to build
//       the call site value. Then specific callback calls just need to include the call site.
/**
 * Used to save report with the option to await a response
 *
 * REMOVED @param updateLatest by default updates local state after save. Use false when navigating away from report on save call
 *
 * @param componentContext unique string for this useSaveReport that will appear in logs and can help with debugging,
 *                         usually the name of the component using the hook, but add something if the component uses
 *                         the hook multiple times (e.g. "MyComponent 1" and "MyComponent 2")
 */
export const useSaveReport = (componentContext: string) => {
  // Unique ID for the next save operation (within the context of this useSaveReport instance)
  // This is used to check whether another save should be initiated (set on triggering save and on partial save).
  const [saveId, setSaveId] = useState(0);

  // Arguments passed to the save callback by the caller, cached to use for multi-stage save.
  const [saveArgs, setSaveArgs] = useState<BeginSaveArgs>();

  // Result object to be returned by the hook
  const [result, setResult] = useState<SaveReportResult>(defaultSaveReportResult);

  // Whether any save mutation has been called during this save request
  const calledSaveReportToServer = useRef(false);

  // Release function to release the save report mutex
  // A ref must be used so we can guarantee whether or not we have the mutex, without waiting for
  // state updates. This is especially important to make sure the mutex is released on component unmount
  // TODO: wrap this functionality in a hook that can return everything, like { requestedMutex, haveMutex, acquireMutex, releaseMutex }
  //       especially because we don't have to remember to remove the release function each time it has been called
  const releaseMutexRef = useRef<() => void>();
  // Ref to flip to false on component unmount, so we can release any mutex that is granted after unmount
  const hookStillMounted = useRef(true);

  const unlockReport = useSetReportLock('unlock');

  // TODO: wrap with useCallback?
  const handleCompleted = (
    response: SaveReportResponse,
    clientOptions?: MutationFunctionOptions<SaveReportResponse, OperationVariables, DefaultContext>
  ) => {
    if (response.saveReport.success) {
      // have to cast this because the apollo types do not use the variable and context type variables properly
      const saveContext = clientOptions?.context as SaveReportOptions['context'] | undefined;
      const updatesInSave = saveContext?.updatesInSave ?? { savedAnswers: [] };

      // compute the new server values after this save
      const serverSavedValues = getMergedReportDataItems(
        savedReportDataItems(),
        latestSavedReportDataItems(),
        updatesInSave
      );
      latestSavedReportDataItems(serverSavedValues);

      // Queue a new save-check. It will either save more items, or set to success result if everything is saved.
      setSaveId((id) => id + 1);

      // Either reset to resume timer-based saving, or reset to prevent timed save during next save
      resetLastSavedTimer();
    } else {
      savingLocalAnswersStatus('error');
      savingLocalAnswersToServer(false);

      // Save has failed. No further saves will be attempted.
      setResult(failureSaveReportResult);
      const releaseMutexFunc = releaseMutexRef.current;
      releaseMutexRef.current = undefined;
      releaseMutexFunc?.();
    }
  };

  const [saveReportToServer] = useMutation<SaveReportResponse>(SAVE_REPORT, {
    onCompleted: handleCompleted,
    onError: (error) => {
      recordRumError(error);
      savingLocalAnswersStatus('error');
      savingLocalAnswersToServer(false);

      // ensure state is reset so that the next save can work as expected
      calledSaveReportToServer.current = false;

      // Save has failed. No further saves will be attempted.
      setResult(failureSaveReportResult);
      const releaseMutexFunc = releaseMutexRef.current;
      releaseMutexRef.current = undefined;
      releaseMutexFunc?.();
    },
  });

  /**
   * Check for items to save and save them, or complete with success if all are saved.
   *
   * If no changes have been made and lockReport is false, the report is unlocked.
   *
   * If there is nothing to save, treat it as a successful save. This is the mechanism
   * used to ensure a save includes all items before reporting success or unlocking
   * the report.
   */
  const beginOrCompleteSave = useCallback(
    (callerArgs: BeginSaveArgs | undefined) => {
      // Note: this will potentially unlock the report on the first save of several, but this is
      //       not a significant risk for concurrent editing since it represents only seconds.

      const { callContext, uiContext, lockReport } = callerArgs ?? {};
      const contextString = `component: ${componentContext}; call_site: ${callContext}; triggered_by: ${uiContext}`;

      // TODO: this prevents a crash when save is called after report unload. We can remove it
      //       when we are confident that it will never be called after report unload.
      let saveReportOptions: ReturnType<typeof getSaveReportOptions>;
      try {
        saveReportOptions = getSaveReportOptions(contextString, lockReport);
      } catch (error) {
        // expect error to be: report has unloaded so saved answers are not defined, causing failure
        console.error(`beginSave crashed at getSaveReportOptions (for ${callContext})`, error);

        savingLocalAnswersToServer(false);
        savingLocalAnswersStatus('error');
        setResult(failureSaveReportResult);
        const releaseMutexFunc = releaseMutexRef.current;
        releaseMutexRef.current = undefined;
        releaseMutexFunc?.();
        return;
      }

      const haveUnsavedItems = saveReportOptions.variables.reportDataObject.reportDataItems?.length;

      if (haveUnsavedItems) {
        // TODO: could wrap in idempotent update to avoid setting on every part of multi-part saves
        savingLocalAnswersToServer(true);
        savingLocalAnswersStatus('saving');

        // Record that we have called the mutation, so that separate report lock/unlock below will
        // be skipped and we know we need to set the reactive variables back to inactive states
        calledSaveReportToServer.current = true;
        saveReportToServer(saveReportOptions);
        return;
      }

      // Nothing to save, count that a success and finish loading
      if (calledSaveReportToServer.current) {
        // Something was saved, set reactive variables to saved status
        savingLocalAnswersToServer(false);
        savingLocalAnswersStatus('saved');
      }
      setResult(successSaveReportResult);

      // unlock the report if requested and nothing left to save
      // FIXME: shouldn't there also be report locking if lockReport true is passed in?
      if (!calledSaveReportToServer.current && lockReport === false) {
        unlockReport();
      }

      // reset for the next requested save
      calledSaveReportToServer.current = false;

      // MUST release the mutex or no other saves will be possible
      const releaseMutexFunc = releaseMutexRef.current;
      releaseMutexRef.current = undefined;
      releaseMutexFunc?.();
    },
    [componentContext, saveReportToServer, unlockReport]
  );

  // Kick off a save every time the saveId changes
  useEffect(() => {
    // Using initial value of 0 to indicate not set yet
    if (!saveId) return;

    beginOrCompleteSave(saveArgs);
    // intentionally only using change of saveId to trigger a save, otherwise saves might happen multiple times
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saveId]);

  // Make sure the mutex is released when this hook unmounts
  useEffect(
    () => () => {
      // mark this useSaveReport hook instance as unmounted, so callbacks can immediately release any mutex they receive
      hookStillMounted.current = false;
      if (releaseMutexRef.current) {
        // if the mutex is held and a save mutation has been initiated, the reactive variables would have been
        // set to a saving state. Return them to saved state.
        if (calledSaveReportToServer.current) {
          savingLocalAnswersToServer(false);
          savingLocalAnswersStatus('saved');
        }
        releaseMutexRef.current?.();
      }
    },
    // Don't want to run the hook on any releaseMutex change, since that would call
    // the cleanup function on every change after the first except just on unmount
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  /**
   * Queue a save to happen by incrementing saveId and storing the arguments to use for the save
   *
   * @param callContext to log calling component for the save (included in payload and used on the server)
   * @param lockReport (optional) locks or unlocks the report with each save, or unlocks the report if there were no saves. Unlocking allows other users to edit the report.
   */
  const queueSave = useCallback((callContext: string, uiContext: string, lockReport?: boolean) => {
    setSaveArgs({ callContext, uiContext, lockReport });
    setSaveId((id) => id + 1);
  }, []);

  /**
   * Returns a function that initiates the save report process.
   *
   * Note: actual save may not be immediate, if other save is in progress, but loading state will be returned
   * immediately.
   *
   * @param callContext unique name for this call side of requestSave, to be included in logs alongside componentContext
   *                    for debugging purposes. This could be the name of the handler function the call is made from,
   *                    or something else specific to the code.
   * // TODO: param uiContext unique name to identify the UI interaction that triggered this save, if appropriate.
   * //             Ideally exactly match a label so it is straightforward to idenfity it in the UI. For saves not
   * //             based on UI interaction, use something appropriate (e.g. "saving/saved indicator", "browser close", etc.)
   * @param lockReport (optional) unlocks the report after all saves are done. Unlocking allows other users to edit the report.
   *     Sent with the save, or as a separate unlock (TODO: also lock) call if no saves happen
   */
  const requestSave = useCallback(
    async (callContext: string, uiContext: string, lockReport?: boolean) => {
      // Prevent saving while submitting. This is not recoverable since we can't change answers after submit.
      // (we should not allow this state to be reached)
      if (isSubmittingReport()) {
        console.log('Attempted to save while report is submitting', callContext);
        // this is an error condition, so set error result immediately
        setResult(failureSaveReportResult);
        return;
      }

      // TODO: check if there are any changes to save before proceeding.
      //       Only if not syncing DSP items since that can introduce changes.
      //       Potential to return boolean true when already saved.

      // we return loading state from request until a save errors or the final save is completed
      // (even if waitinf for another operation to complete)
      setResult(loadingSaveReportResult);

      // TODO: allow hookStillMounted ref to be passed to acquireSaveReportMutex so it can reject if the component
      //       is unmounted by the time the mutex is available.
      // const releaseMutexCallback = await acquireSaveReportMutex(hookStillMounted);
      const releaseMutexCallback = await acquireSaveReportMutex();
      // If we somehow already had a mutex release function, call it to make sure nothing is held
      // (safe to call again since it checks if the call is from the currently granted mutex)
      if (releaseMutexRef.current) {
        releaseMutexRef.current();
      }

      if (hookStillMounted.current) {
        releaseMutexRef.current = releaseMutexCallback;
      } else {
        // The component has unmounted, release the mutex so it isn't held forever
        releaseMutexCallback();
        // No longer mounted, so we skip any further check or attempt to save
        return;
      }

      // TODO: refactor waiting for syncing (or any reactive var) to be false into a promise that we can await
      //       (the rest of the code in this function would be replaced with awaiting it)
      if (!syncingDspItems()) {
        // Optimization: if there is nothing to save, stop here and report success (and release any mutex)
        queueSave(callContext, uiContext, lockReport);
        return;
      }

      // Ensure timer will not trigger additional saves while this is waiting (controlled by interval in ReportPage)
      // this will work as long as sync takes under 60s
      resetLastSavedTimer();

      const saveOrWait = (syncing: boolean) => {
        if (syncing) {
          // Keep listening for changes until not syncing
          syncingDspItems.onNextChange(saveOrWait);
        } else {
          queueSave(callContext, uiContext, lockReport);
        }
      };

      syncingDspItems.onNextChange(saveOrWait);
    },
    [queueSave]
  );

  return [requestSave, result] as const;
};
