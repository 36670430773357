import { gql } from '@apollo/client';

export const ASSIGN_DELEGATE = gql`
  mutation delegation($providerId: String!, $organisationId: String!, $delegatees: [DelegateeInput!]!) {
    delegation(providerId: $providerId, organisationId: $organisationId, delegatees: $delegatees) {
      errorMessages {
        errorCode
        language
        message
        metaData
        errorType
        name
        errorStatusCode
        stack
        url
        time
      }
      success
    }
  }
`;
