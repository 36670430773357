import AddingManualAccounts from 'assets/instructions/adding-manual-accounts.png';
import AutomaticLogout from 'assets/instructions/automatic-logout.png';
import CompletingYourReport from 'assets/instructions/completing-your-report.png';
import DownloadYourReport from 'assets/instructions/download-your-report.png';
import EditingContactDetails from 'assets/instructions/editing-contact-details.png';
import GettingStarted from 'assets/instructions/getting-started.png';
import LoggingOut from 'assets/instructions/logging-out.png';
import ManualAccounts from 'assets/instructions/manual-accounts.png';
import NavigatingTheReport from 'assets/instructions/navigating-the-report.png';
import RefreshingYourData from 'assets/instructions/refreshing-your-data.png';
import RemovingAnAllocatedAccount from 'assets/instructions/removing-an-allocated-account.png';
import ReturningToDashboard from 'assets/instructions/returning-to-dashboard.png';
import ReviewingAndSubmitting from 'assets/instructions/review-and-submit-report.png';
import SaveAndAutosave from 'assets/instructions/save-and-autosave.png';
import SplittingAnAccount from 'assets/instructions/splitting-an-account.png';
import YourNextReport from 'assets/instructions/your-next-report.png';

import CompletingYourReportNoDsp from 'assets/instructions/completing-your-report-no-dsp.png';
import ReturningToDashboardNoDsp from 'assets/instructions/returning-to-dashboard-no-dsp.png';
import DownloadCopyOfReportNoDsp from 'assets/instructions/download-your-report-no-dsp.png';
import ReviewingAndSubmittingNoDsp from 'assets/instructions/review-and-submit-report-no-dsp.png';

// Title and body are from copy deck page
// https://govteams.sharepoint.com/:x:/s/featuretest/ER3lcyC-RTNBjAyrB4pD4UQBq-IeYZaB-cQ87AeQBOGkhg?e=x1b0px&nav=MTVfezczQjcyREFGLTJCNjUtNEUyNC05Q0NDLTU2RkUxN0Y4RTA0Qn0

const nonDspHeading = ({ category }: { category: string }) => category !== 'completing-your-survey';

export const FetchInstructionsData = (isUsingDsp = true) => ({
  headings: isUsingDsp ? headings : headings.filter(nonDspHeading),
  instructionsData: instructionsData.filter((instruction) =>
    instruction.reportType.includes(isUsingDsp ? 'dsp' : 'noDsp')
  ),
});

const headings = [
  { category: 'getting-started', title: 'Getting started' },
  { category: 'completing-your-survey', title: 'Completing your survey' },
  { category: 'navigation', title: 'Navigation' },
  { category: 'save-and-log-out', title: 'Save and log out' },
  { category: 'review-and-submit', title: 'Review and submit' },
  { category: 'all', title: 'All' },
];

export interface InstructionData {
  title: string;
  reportType: string[];
  categories: string[];
  text: string[];
  imgPath: string;
}

const instructionsData: InstructionData[] = [
  {
    title: 'Getting started',
    reportType: ['dsp'],
    categories: ['getting-started'],
    text: [
      "Press the 'Add from' button on a survey item.",
      'We will show you a list of accounts from your accounting software and ask you to select those that you want to include for each survey item.',
    ],
    imgPath: GettingStarted,
  },
  {
    title: 'Completing your survey',
    reportType: ['dsp'],
    categories: ['getting-started'],
    text: [
      'Allocate your accounts to the correct survey items. You can also add manual accounts if you need to allocate something not in your accounting software.',
      'As you add accounts to the survey they will disappear from the account list.',
    ],
    imgPath: CompletingYourReport,
  },
  {
    title: 'Completing your survey',
    reportType: ['noDsp'],
    categories: ['getting-started'],
    text: [
      'Enter values next to the relevant survey items. If a survey item does not apply to your business, it can be left blank.',
    ],
    imgPath: CompletingYourReportNoDsp,
  },
  {
    title: 'Your next survey',
    reportType: ['dsp'],
    categories: ['getting-started'],
    text: [
      'The next time you complete a survey, we will remember what accounts you have selected for each survey item, so reporting will be quicker and easier.',
    ],
    imgPath: YourNextReport,
  },
  {
    title: 'Editing your contact details',
    reportType: ['dsp'],
    categories: ['getting-started'],
    text: [
      "You can edit your contact details on the review page by pressing 'Edit details' next to the details. The contact should be the person who completed the survey, so we can contact you if we need to clarify any information.",
      'You cannot edit your email as this is associated with your accounting software.',
    ],
    imgPath: EditingContactDetails,
  },
  {
    title: 'Editing your contact details',
    reportType: ['noDsp'],
    categories: ['getting-started'],
    text: [
      "You can edit your contact details on the review page by pressing 'Edit details' next to the details.",
      'The contact should be the person who completed the survey, so we can contact you if we need to clarify any information.',
    ],
    imgPath: EditingContactDetails,
  },
  {
    title: 'Manual entry',
    reportType: ['dsp'],
    categories: ['completing-your-survey'],
    text: [
      "You can add a manual entry to adjust a value that isn't up to date in your accounting software. An adjustment can be positive or negative.",
      'Any adjustments added will be for the purpose of the survey only, and will not be saved to your accounting software.',
    ],
    imgPath: ManualAccounts,
  },
  {
    title: 'Adding a manual entry',
    reportType: ['dsp'],
    categories: ['completing-your-survey'],
    text: [
      "Add a manual adjustment by pressing 'Manual entry', adding a description and a value and pressing the tick button.",
      "Manual entries can be edited after you've added them by pressing the three dots and selecting 'Edit'.",
    ],
    imgPath: AddingManualAccounts,
  },
  {
    title: 'Removing an allocated account',
    reportType: ['dsp'],
    categories: ['completing-your-survey'],
    text: [
      'You can remove an allocated account by clicking the rubbish bin icon. You can then reallocate this account.',
      "You can remove a manual entry by clicking the dots, and then 'Delete'. This will remove any record of the entry.",
    ],
    imgPath: RemovingAnAllocatedAccount,
  },
  {
    title: 'Splitting an account',
    reportType: ['dsp'],
    categories: ['completing-your-survey'],
    text: [
      'You may need to split an account across multiple items in the survey. To do this, add a manual entry with the correct value for each item, and leave the account from your accounting software unallocated.',
    ],
    imgPath: SplittingAnAccount,
  },
  {
    title: 'Navigating the survey',
    reportType: ['dsp', 'noDsp'],
    categories: ['navigation'],
    text: [
      "At the bottom of each section, you can press 'Save and continue' to save and go to the next section, or 'Back' to return to the previous section.",
      'You can also select any section in the navigation bar to jump to that section.',
    ],
    imgPath: NavigatingTheReport,
  },
  {
    title: 'Returning to the dashboard',
    reportType: ['dsp'],
    categories: ['navigation'],
    text: ["Return to the dashboard by pressing the 'Dashboard' button on the top of the screen."],
    imgPath: ReturningToDashboard,
  },
  {
    title: 'Returning to the dashboard',
    reportType: ['noDsp'],
    categories: ['navigation'],
    text: ["Return to the dashboard by pressing the 'Dashboard' button on the top of the screen."],
    imgPath: ReturningToDashboardNoDsp,
  },
  {
    title: 'Save and autosave',
    reportType: ['dsp', 'noDsp'],
    categories: ['save-and-log-out'],
    text: [
      'Your progress will frequently save automatically, and whenever you move between sections of the survey.',
      "Press the 'Save and exit' button above the left side navigation bar to manually save your progress and exit the survey.",
    ],
    imgPath: SaveAndAutosave,
  },
  {
    title: 'Refreshing your data',
    reportType: ['dsp'],
    categories: ['save-and-log-out'],
    text: [
      "Press the 'Sync' button at any time to view changes made in your accounting software in the survey.",
      "You can also refresh data in the survey by logging out and logging back in, or returning to the dashboard and pressing 'Launch survey'.",
    ],
    imgPath: RefreshingYourData,
  },
  {
    title: 'Logging out',
    reportType: ['dsp', 'noDsp'],
    categories: ['save-and-log-out'],
    text: [
      "To log out, press the icon in the top right hand corner and then press the 'Log out' button. Your survey will be saved if you log out.",
    ],
    imgPath: LoggingOut,
  },
  {
    title: 'Automatic log out',
    reportType: ['dsp', 'noDsp'],
    categories: ['save-and-log-out'],
    text: [
      'If you have been inactive in the survey for twenty minutes your data will be saved and you will be logged out. You will be warned five minutes before you are logged out.',
    ],
    imgPath: AutomaticLogout,
  },
  {
    title: 'Download a copy of your survey',
    reportType: ['dsp'],
    categories: ['review-and-submit'],
    text: [
      "If you need a copy of the survey for your records or to be approved by a client, go to the 'Review' screen and press the 'Download PDF' button. You can also download submitted surveys from the submission confirmation and dashboard screens.",
    ],
    imgPath: DownloadYourReport,
  },
  {
    title: 'Download a copy of your survey',
    reportType: ['noDsp'],
    categories: ['review-and-submit'],
    text: [
      "If you need a copy of the survey for your records or to be approved by a client, go to the 'Review' screen and press the 'Download PDF' button. You can also download submitted surveys from the submission confirmation and dashboard screens.",
    ],
    imgPath: DownloadCopyOfReportNoDsp,
  },
  {
    title: 'Reviewing and submitting your survey',
    reportType: ['dsp'],
    categories: ['review-and-submit'],
    text: [
      'The review screen will show you the accounts you have allocated in each section of the survey. You can return to edit sections of the survey by pressing the pencil icon.',
      "Press the 'Submit survey' button to submit your survey.",
    ],
    imgPath: ReviewingAndSubmitting,
  },
  {
    title: 'Reviewing and submitting your survey',
    reportType: ['noDsp'],
    categories: ['review-and-submit'],
    text: [
      'The review screen will show you the values you have entered in each section of the survey. You can return to edit sections of the survey by pressing the pencil icon.',
      "Press the 'Submit survey' button to submit your survey.",
    ],
    imgPath: ReviewingAndSubmittingNoDsp,
  },
];
