import { useMutation, useReactiveVar } from '@apollo/client';
import { ASSIGN_DELEGATE } from 'apollo/mutations/delegation';
import { authorisedBusinesses } from 'apollo/states/AuthorisedBusinesses';
import { Delegation } from 'models/GraphQL/Delegation';
import { Delegate } from 'models/GraphQL/Delegate';

export const useSetDelegation = (delegateeData: Delegate[]) => {
  const currentBusinesses = useReactiveVar(authorisedBusinesses);
  const { activeBusiness } = currentBusinesses;
  const providerId = activeBusiness?.providerId ?? '';
  const organisationId = activeBusiness?.id ?? '';

  const variables = {
    providerId,
    organisationId,
    delegatees: delegateeData,
  };

  const [callTrigger, { loading }] = useMutation<Delegation>(ASSIGN_DELEGATE, { variables });
  return [callTrigger, loading] as const;
};
