import { useCallback, useEffect } from 'react';
import { RumCustomEvent } from 'enums/RumCustomEvent';
import { getRumAttributes } from 'utils/getRumAttributes';
import { useSaveReport } from 'utils/useSaveReport';
import { recordRumCustomEvent } from '../awsRum';
import { SaveReportDebugUI } from './SaveReportDebugUI';
import {
  isCurrentReportFullySaved,
  isCurrentReportSaveable,
  useCurrentReportSaveable,
  useCurrentReportSaveStatus,
  useTrackCurrentReportSaveStatus,
} from './currentReportStatus';
import { isFullySavedStatus } from './reportStatusUtil';
import { isSavingInBackground, useSavingInBackground } from './useBackgroundSaveReducer';
import { BackgroundSaveManager } from './BackgroundSaveManager';

import './SaveReportManager.css';

/**
 * Single place to coordinate all report saving.
 *
 * This may be broken up to separate files, but still represents one place that all the report
 * saving code should live in the system. This is to reduce bugs and make them easy to debug.
 *
 * Key parts of this system:
 *
 * - SaveReportManager component: nest in ASPApolloClient where it will render for all pages
 *     (not just report pages, since we need to ensure in-progress saves complete after a
 *      report is closed)
 * - BackgroundSaveManager component: nested component that handles background save data and coordination
 * - hooks that can be used in any other component to subscribe to changes in save-related statuses
 *     - current report unchanged, changed and never saved, saving, saved and not changed since,
 *       changed after save, or "unknown" when no report is loaded
 *     - specific report ID same statuses as current report, but "unknown" when the report ID has
 *       not been loaded at all (in the current session).
 *       (e.g. to show reports on the dashboard that are still saving)
 *     - current report saved all data items or not (e.g. to check if good to submit or close)
 *     - any report data items pending save (e.g. to delay close until everything saved)
 * - sync callbacks to give a snapshot of any of the above hook states
 * - async callbacks to await specific hook states (e.g. to await all data items saved before logout)
 */
export const SaveReportManager = () => {
  // DO NOT REMOVE: this ensures the currentReportStatus var is kept up to date
  useTrackCurrentReportSaveStatus();

  const savingInBackground = useSavingInBackground();
  const currentReportStatus = useCurrentReportSaveStatus();
  const reportFullySaved = isFullySavedStatus(currentReportStatus);
  const currentReportSaveable = useCurrentReportSaveable();
  const [saveReport] = useSaveReport('SaveReportManager');

  const hasReport = currentReportStatus !== 'unknown';

  const handleCloseBrowser = useCallback(
    (event: BeforeUnloadEvent) => {
      recordRumCustomEvent(RumCustomEvent.windowClose, getRumAttributes());

      if (isCurrentReportSaveable() && !isCurrentReportFullySaved()) {
        // TODO: consider replacing with background save to allow possibility of parallel saving
        saveReport('handleCloseBrowser', 'user closed browser/tab', false);
        // preventing default on this triggers a dialog to buy us some time to save,
        // see: https://developer.mozilla.org/en-US/docs/Web/API/Window/beforeunload_event
        event.preventDefault();
      }

      if (isSavingInBackground()) {
        // just prevent default, background save should complete automatically
        event.preventDefault();
      }
    },
    [saveReport]
  );

  // compute outside useEffect to prevent unnecessary listener churn
  const hasUnsavedData = savingInBackground || (hasReport && currentReportSaveable && !reportFullySaved);

  useEffect(() => {
    if (hasUnsavedData) {
      window.addEventListener('beforeunload', handleCloseBrowser);

      // Cleanup detaches handler whenever there is no pending data to save, to prevent issues,
      // see https://developer.mozilla.org/en-US/docs/Web/API/Window/beforeunload_event )
      return () => {
        window.removeEventListener('beforeunload', handleCloseBrowser);
      };
    }
  }, [hasUnsavedData, handleCloseBrowser]);

  // Don't render a visible element unless debug is on
  // IMPORTANT: always render BackgroundSaveManager or we won't get working background save
  const debug = window.showSaveReportDebugUI;
  return (
    <div className="save-debug-ui" style={debug ? {} : { display: 'none' }}>
      {debug && <SaveReportDebugUI />}
      <BackgroundSaveManager debug={debug} />
    </div>
  );
};

declare global {
  interface Window {
    showSaveReportDebugUI: boolean;
  }
}
