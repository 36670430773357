import { Flex, Text, Button } from '@aws-amplify/ui-react';
import { ReactComponent as DelegatedIcon } from 'assets/icon-delegated.svg';

interface DelegateCapsuleProps {
  onClick?: () => void;
}

export const DelegateCapsule = ({ onClick }: DelegateCapsuleProps) => (
  <Button className="report-progress-capsule-wrapper" border="none" backgroundColor="#F3F7FB" onClick={onClick}>
    <Flex alignItems="center" justifyContent="center" direction="row" gap={8} padding={8}>
      <DelegatedIcon />
      <Text testId="report-progress-capsule-text" color="#326297">
        Delegated
      </Text>
    </Flex>
  </Button>
);
