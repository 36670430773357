import { useState } from 'react';
import { ErrorMessage } from 'models';
import { dspApiErrorTypes } from 'enums/ApiErrorMsg';
import ToastNotification from 'components/ToastNotification/ToastNotification';

const dspApiErrorToastHeader = 'Accounting software communication is down';

const buildDspApiErrorToastText = (errorCode?: string): string =>
  `Your accounting software is not currently sending us information. You will be unable to connect a business, ` +
  `launch a survey or sync until this is working. Please try again later.${
    errorCode ? ` (Error Code: ${errorCode})` : ''
  }`;

/**
 * Checks error messages for DSP API type errors and renders message content in a ToastNotification.
 * @returns a tuple with:
 *     rendered toast component to insert in JSX,
 *     callback to show toast if given errors have an API error,
 *     callback to hide the toast
 */
const useDspApiErrorToast = () => {
  const [open, setOpen] = useState(false);
  const [errorCode, setErrorCode] = useState('');

  const toast = (
    <ToastNotification
      success={false}
      title={dspApiErrorToastHeader}
      text={buildDspApiErrorToastText(errorCode)}
      open={open}
      onClose={() => setOpen(false)}
    />
  );

  /**
   * Shows DSP API error toast if any of errorMessages is a DSP API error
   * @param errorMessages to check for error type
   * @returns true if Toast was opened, otherwise false
   */
  const checkErrorTypes = (errorMessages: ErrorMessage[]) => {
    if (hasDspApiErrorTypes(errorMessages)) {
      setErrorCode(getDspApiErrorCode(errorMessages));
      setOpen(true);
      return true;
    }
    return false;
  };

  const clearToast = () => {
    setOpen(false);
    setErrorCode('');
  };

  return [toast, checkErrorTypes, clearToast] as const;
};

export default useDspApiErrorToast;

function hasDspApiErrorTypes(errorMessages: ErrorMessage[]) {
  return (Array.isArray(errorMessages) ? errorMessages : [errorMessages]).some((x) =>
    dspApiErrorTypes.includes(x.errorType)
  );
}

function getDspApiErrorCode(errorMessages: ErrorMessage[]): string {
  const errorCodes = (Array.isArray(errorMessages) ? errorMessages : [errorMessages]).filter((x) =>
    dspApiErrorTypes.includes(x.errorType)
  );

  // Formats multiple error codes, does not format when there is 1 error code,
  // returns empty string when there are 0 error codes
  return errorCodes.map((e) => e.errorCode).join(', ');
}
