/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { useMutation } from '@apollo/client';
import { LOCK_USER_REPORT } from 'apollo/mutations/lockUserReport';
import { chosenReport } from 'apollo/states/ChosenReport';
import { isCurrentReportLocked } from 'apollo/states/isCurrentReportLocked';

interface ReportDataProps {
  dspProvider?: string;
  organisationId?: string;
  userReportId?: string;
}

const noOp = () => Promise.resolve();

export const useSetReportLock = (action: 'lock' | 'unlock', reportData?: ReportDataProps) => {
  const lock = action === 'lock';
  const reportDetails = chosenReport();
  const variables = {
    dspProvider: reportData?.dspProvider ?? reportDetails?.dspProvider,
    organisationId: reportData?.organisationId ?? reportDetails?.organisationId,
    userReportId: reportData?.userReportId ?? reportDetails?.userReportId,
    lock,
  };

  const [callTrigger] = useMutation(LOCK_USER_REPORT, {
    variables,
    onCompleted: (response) => {
      if (response.lockUserReport.success) isCurrentReportLocked(lock);
    },
  });

  const isMissingRequiredInputs = !variables.dspProvider || !variables.organisationId || !variables.userReportId;
  if (isMissingRequiredInputs) return noOp;

  return callTrigger;
};
